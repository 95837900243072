import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Divider,
  Checkbox,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { store } from "../../../redux/store";
import { title10Tab } from "../../../style";
import { RadioGreyBox, TextGreyBox } from "../../../components/TextBox";
import { StatusBottomBar } from "../StatusBottomBar";
import { LayoutTab } from "../LayoutTab";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { useMutation } from "@tanstack/react-query";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { enqueueSnackbar } from "notistack";
import {
  OtherAdd,
  OtherArrow,
  OtherCheck,
  OtherRemove,
} from "../../../asset/iconSvg";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { MedAndNutritionalHistoryDialog } from "./MedAndNutritionalHistoryDialog";

// const RadioGroup = ({
//   value,
//   setValue,
//   label,
// }: {
//   value: boolean;
//   setValue: (value: boolean) => void;
//   label: string;
// }) => (
//   <>
//     <FormControlLabel
//       value="true"
//       control={
//         <Radio
//           color="default"
//           style={{ color: "#098484" }}
//           onChange={() => setValue(true)}
//         />
//       }
//       label="Yes"
//       checked={value}
//     />
//     <FormControlLabel
//       value="false"
//       control={
//         <Radio
//           color="default"
//           style={{ color: "#098484" }}
//           onChange={() => setValue(false)}
//         />
//       }
//       label="No"
//       checked={!value}
//     />
//   </>
// );
// type Medication = {
//   medicationUse: boolean;
//   DMmed: boolean;
//   glp: boolean;
//   insulin: boolean;
//   other: boolean;
//   DLPmed: boolean;
//   HTmed: boolean;
//   nutritionAndSupplement: boolean;
//   calciumSupplement: boolean;
//   vitDSupplement: boolean;
//   ironSupplement: boolean;
//   vitB12Supplement: boolean;
// };
export type MedicationResponse = {
  entryId: number;
  editorId: number;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  medicationUse: boolean;
  dmMed: boolean;
  glp1Ra: boolean;
  insulin: boolean;
  dmOther: boolean;
  dmOtherNote: string[];
  dlpMed: boolean;
  htMed: boolean;
  supplementUse: boolean;
  calcium: boolean;
  vitaminD: boolean;
  iron: boolean;
  vitaminB12: boolean;
};
type Props = {
  handleCancelClick: () => void;
};

export const MedAndNutritional = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState(2);
  const [otherDetails, setOtherDetails] = useState([""]);
  const [validationError, setValidationError] = useState(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);
  const latestEntryId = useAppSelector(
    (state) =>
      state.patientUser.latestEntryIds
        ?.medicationAndNutritionalSupplementsStatus
  );
  const currentEntryId = useAppSelector(
    (state) =>
      state.patientUser.entryIds?.medicationAndNutritionalSupplementsStatus
  );
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [medicationValues, setMedicationValues] = useState({
    medicationUse: false,
    DMmed: false,
    glp: false,
    insulin: false,
    other: false,
    DLPmed: false,
    HTmed: false,
    nutritionAndSupplement: false,
    calciumSupplement: false,
    vitDSupplement: false,
    ironSupplement: false,
    vitB12Supplement: false,
  });
  const setData = (medicationData: MedicationResponse) => {
    setMedicationValues({
      medicationUse: medicationData.medicationUse,
      DMmed: medicationData.dmMed,
      glp: medicationData.glp1Ra,
      insulin: medicationData.insulin,
      other: medicationData.dmOther,
      DLPmed: medicationData.dlpMed,
      HTmed: medicationData.htMed,
      nutritionAndSupplement: medicationData.supplementUse,
      calciumSupplement: medicationData.calcium,
      vitDSupplement: medicationData.vitaminD,
      ironSupplement: medicationData.iron,
      vitB12Supplement: medicationData.vitaminB12,
    });
    setOtherDetails(medicationData.dmOtherNote ?? [""]);
  };
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  useEffect(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const medicationData: MedicationResponse =
          await visitDataService.getMedicationFromEntryId(currentEntryId);
        setData(medicationData);
        setStatus(medicationData.visitDataStatus);
      } else if (latestEntryId) {
        const medicationData: MedicationResponse =
          await visitDataService.getMedicationFromEntryId(latestEntryId);
        setData(medicationData);
        setStatus(medicationData.visitDataStatus);
      }
    };
    fetchData();
  },[openNoPermissionDialog]);
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveMedicationData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        medicationUse: medicationValues.medicationUse,
        dmMed: medicationValues.medicationUse ? medicationValues.DMmed : false,
        glp1Ra: medicationValues.medicationUse ? medicationValues.glp : false,
        insulin: medicationValues.medicationUse
          ? medicationValues.insulin
          : false,
        dmOther: medicationValues.medicationUse
          ? medicationValues.other
          : false,
        dmOtherNote:
          medicationValues.medicationUse && medicationValues.other
            ? otherDetails
            : null,
        dlpMed: medicationValues.medicationUse
          ? medicationValues.DLPmed
          : false,
        htMed: medicationValues.medicationUse ? medicationValues.HTmed : false,
        supplementUse: medicationValues.nutritionAndSupplement,
        calcium: medicationValues.nutritionAndSupplement
          ? medicationValues.calciumSupplement
          : false,
        vitaminD: medicationValues.nutritionAndSupplement
          ? medicationValues.vitDSupplement
          : false,
        iron: medicationValues.nutritionAndSupplement
          ? medicationValues.ironSupplement
          : false,
        vitaminB12: medicationValues.nutritionAndSupplement
          ? medicationValues.vitB12Supplement
          : false,
      });
      return response as MedicationResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          medicationAndNutritionalSupplementsStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSubmitData = async () => {
    // await submitDataAsync();
    if(patientDataPermissionEditAble === false){
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (medicationValues.DMmed) {
      if (
        medicationValues.glp ||
        medicationValues.insulin ||
        medicationValues.other
      ) {
        if (medicationValues.other) {
          if (validateOtherRequiredFields()) {
            setValidationError(false);
            await submitDataAsync();
            dispatch(updateTabsIsDirty(false));
          } else {
            setValidationError(true);
          }
        } else {
          await submitDataAsync();
          setValidationError(false);
          dispatch(updateTabsIsDirty(false));
        }
      } else {
        setOpenIncompleteDataDialog(true);
      }
    }else if(medicationValues.DMmed === false && medicationValues.nutritionAndSupplement === false){
      await submitDataAsync();
      dispatch(updateTabsIsDirty(false));
    }else if(medicationValues.DMmed === false && medicationValues.nutritionAndSupplement){
      await submitDataAsync();
      dispatch(updateTabsIsDirty(false));
    };
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setMedicationValues({
    //   ...medicationValues,
    //   [event.target.name]: event.target.value === "yes" ? true : false,
    // });
    const { name, value, type, checked } = event.target;

    setMedicationValues((prevValues) => ({
      ...prevValues,
      [name]: type === "radio" ? (value === "yes" ? true : false) : checked,
    }));
    dispatch(updateTabsIsDirty(true));
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };
  const handleOtherAdd = () => {
    setOtherDetails((prevDetails) => [...prevDetails, ""]);
    dispatch(updateTabsIsDirty(true));
  };
  const handleOtherRemove = (index: number) => {
    if (otherDetails.length !== 1) {
      const newDetails = otherDetails.filter((_, i) => i !== index);
      setOtherDetails(newDetails);
    }
    dispatch(updateTabsIsDirty(true));
  };
  const handleOtherChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDetails = [...otherDetails];
    newDetails[index] = event.target.value;
    setOtherDetails(newDetails);
    dispatch(updateTabsIsDirty(true));
  };
  const validateOtherRequiredFields = () => {
    for (let detail of otherDetails) {
      if (!detail.trim()) {
        return false;
      }
    }
    return true;
  };
  const handleCloseDialog = () => {
    setOpenIncompleteDataDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenHistoryDialog(false);
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box style={{ padding: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => console.log("history")}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ p: 2 }}>
          <Typography style={title10Tab}>Medication</Typography>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack spacing={1}>
              <Typography color={"#646D78"}>
                Medication Use <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <FormControlLabel
                  name="medicationUse"
                  value="yes"
                  control={
                    <Radio
                      color="default"
                      style={{ color: "#098484" }}
                      onChange={(e) => handleChange(e)}
                    />
                  }
                  label="Yes"
                  checked={medicationValues.medicationUse === true}
                />
                <FormControlLabel
                  name="medicationUse"
                  value="no"
                  control={
                    <Radio
                      color="default"
                      style={{ color: "#098484" }}
                      onChange={(e) => handleChange(e)}
                    />
                  }
                  label="No"
                  checked={medicationValues.medicationUse === false}
                />
              </Box>
            </Stack>
            {medicationValues.medicationUse && (
              <Stack spacing={1}>
                <Typography color={"#646D78"}>
                  DM med <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box>
                  <FormControlLabel
                    name="DMmed"
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        style={{ color: "#098484" }}
                        onChange={(e) => handleChange(e)}
                      />
                    }
                    label="Yes"
                    checked={medicationValues.DMmed === true}
                  />
                  <FormControlLabel
                    name="DMmed"
                    value="no"
                    control={
                      <Radio
                        color="default"
                        style={{ color: "#098484" }}
                        onChange={(e) => handleChange(e)}
                      />
                    }
                    label="No"
                    checked={medicationValues.DMmed === false}
                  />
                </Box>
              </Stack>
            )}
            {medicationValues.DMmed === true &&
              medicationValues.medicationUse === true && (
                <div>
                  <Typography color={"#646D78"}>
                    DM med Data<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Grid container rowGap={1} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Stack spacing={1}>
                        <Stack direction={"row"} spacing={1}>
                          <TextGreyBox>GLP-1 RA</TextGreyBox>
                          <RadioGreyBox>
                            <Checkbox
                              name="glp"
                              checked={medicationValues.glp}
                              onChange={handleChange}
                              style={{ color: "#098484" }}
                            />
                          </RadioGreyBox>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <TextGreyBox>Insulin</TextGreyBox>
                          <RadioGreyBox>
                            <Checkbox
                              name="insulin"
                              checked={medicationValues.insulin}
                              onChange={handleChange}
                              style={{ color: "#098484" }}
                            />
                          </RadioGreyBox>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <TextGreyBox>Other</TextGreyBox>
                          <RadioGreyBox>
                            <Checkbox
                              name="other"
                              checked={medicationValues.other}
                              onChange={handleChange}
                              style={{ color: "#098484" }}
                            />
                          </RadioGreyBox>
                        </Stack>
                        {medicationValues.other && (
                          <Stack spacing={1}>
                            {otherDetails.map((detail, index) => {
                              return (
                                <Stack
                                  key={index}
                                  spacing={1}
                                  direction={"row"}
                                  // alignItems={"center"}
                                >
                                  <Box sx={{ pt: 1 }}>
                                    <OtherArrow />
                                  </Box>

                                  <TextField
                                    required
                                    sx={{ width: "61%" }}
                                    value={detail}
                                    onChange={(e) =>
                                      handleOtherChange(index, e)
                                    }
                                    error={validationError && !detail.trim()}
                                    helperText={
                                      validationError && !detail.trim()
                                        ? "This field is required"
                                        : ""
                                    }
                                  />
                                  <Box
                                    sx={{ pt: 0.5 }}
                                    onClick={() => handleOtherRemove(index)}
                                  >
                                    <OtherRemove />
                                  </Box>
                                  <Box
                                    sx={{ pt: 0.5 }}
                                    onClick={handleOtherAdd}
                                  >
                                    <OtherAdd />
                                  </Box>
                                </Stack>
                              );
                            })}
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </div>
              )}
            {medicationValues.DMmed && medicationValues.medicationUse && (
              <Stack spacing={1}>
                <Typography color={"#646D78"}>
                  DLP med <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box>
                  <FormControlLabel
                    name="DLPmed"
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        style={{ color: "#098484" }}
                        onChange={(e) => handleChange(e)}
                      />
                    }
                    label="Yes"
                    checked={medicationValues.DLPmed === true}
                  />
                  <FormControlLabel
                    name="DLPmed"
                    value="no"
                    control={
                      <Radio
                        color="default"
                        style={{ color: "#098484" }}
                        onChange={(e) => handleChange(e)}
                      />
                    }
                    label="No"
                    checked={medicationValues.DLPmed === false}
                  />
                </Box>
              </Stack>
            )}
            {medicationValues.DMmed && medicationValues.medicationUse && (
              <>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    HT med <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="HTmed"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={medicationValues.HTmed === true}
                    />
                    <FormControlLabel
                      name="HTmed"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={medicationValues.HTmed === false}
                    />
                  </Box>
                </Stack>
              </>
            )}
          </Stack>

          <Divider sx={{ width: "100%", my: 3 }} />
          <Typography style={title10Tab}> Nutritional Supplements</Typography>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Stack spacing={1}>
              <Typography color={"#646D78"}>
                Nutritional Supplement <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <FormControlLabel
                  name="nutritionAndSupplement"
                  value="yes"
                  control={
                    <Radio
                      color="default"
                      style={{ color: "#098484" }}
                      onChange={(e) => handleChange(e)}
                    />
                  }
                  label="Yes"
                  checked={medicationValues.nutritionAndSupplement === true}
                />
                <FormControlLabel
                  name="nutritionAndSupplement"
                  value="no"
                  control={
                    <Radio
                      color="default"
                      style={{ color: "#098484" }}
                      onChange={(e) => handleChange(e)}
                    />
                  }
                  label="No"
                  checked={medicationValues.nutritionAndSupplement === false}
                />
              </Box>
            </Stack>
            {medicationValues.nutritionAndSupplement && (
              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    calcium Supplement<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="calciumSupplement"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={medicationValues.calciumSupplement === true}
                    />
                    <FormControlLabel
                      name="calciumSupplement"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={medicationValues.calciumSupplement === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Vitamin D Supplement<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="vitDSupplement"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={medicationValues.vitDSupplement === true}
                    />
                    <FormControlLabel
                      name="vitDSupplement"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={medicationValues.vitDSupplement === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Iron Supplement<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="ironSupplement"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={medicationValues.ironSupplement === true}
                    />
                    <FormControlLabel
                      name="ironSupplement"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={medicationValues.ironSupplement === false}
                    />
                  </Box>
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    Vitamin B12 (1000 mcg/ml)
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box>
                    <FormControlLabel
                      name="vitB12Supplement"
                      value="yes"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="Yes"
                      checked={medicationValues.vitB12Supplement === true}
                    />
                    <FormControlLabel
                      name="vitB12Supplement"
                      value="no"
                      control={
                        <Radio
                          color="default"
                          style={{ color: "#098484" }}
                          onChange={(e) => handleChange(e)}
                        />
                      }
                      label="No"
                      checked={medicationValues.vitB12Supplement === false}
                    />
                  </Box>
                </Stack>
              </Stack>
            )}
          </Stack>
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
      <MedAndNutritionalHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openIncompleteDataDialog}
        title="ข้อมูลไม่ครบถ้วน"
        content="กรุณาระบุข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </LayoutTab>
  );
};
