import { Box, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { Layout } from "../Layout";
import "./Dashboard.css";
import { UserPermission } from "../type";

const maleCount = 544;
const femaleCount = 339;
const register = 544;
const preOp = 339;
const postOp = 345;
const endOfStudy = 150;

const dailyData = [
  ["2024-08-01", 5],
  ["2024-08-02", 8],
  ["2024-08-03", 6],
];

const monthlyData = [
  ["January", 120],
  ["February", 150],
  ["March", 170],
];

const yearlyData = [
  ["2022", 2000],
  ["2023", 2200],
  ["2024", 2500],
];

const ageData = [
  11, 15, 16, 22, 22, 23, 31, 35, 38, 36, 40, 41, 45, 45, 48, 51, 54, 59, 59,
  59, 61, 65, 65, 70,
];
const ageGroups = [
  { label: "11-20 ปี", range: [11, 20], count: 0 },
  { label: "21-30 ปี", range: [21, 30], count: 0 },
  { label: "31-40 ปี", range: [31, 40], count: 0 },
  { label: "41-50 ปี", range: [41, 50], count: 0 },
  { label: "51-60 ปี", range: [51, 60], count: 0 },
  { label: "61-70 ปี", range: [61, 70], count: 0 },
];
ageData.forEach((age) => {
  ageGroups.forEach((group) => {
    if (age >= group.range[0] && age <= group.range[1]) {
      group.count++;
    }
  });
});
const ageGroupedData = ageGroups.map((group) => ({
  name: group.label,
  y: group.count,
}));
const surgeryGroup = [
  { label: "RYGB", count: 5 },
  { label: "SG", count: 6 },
  { label: "Sleeve-DJB", count: 9 },
  { label: "Sleeve-PJB", count: 13 },
  { label: "SADI-S", count: 20 },
  { label: "Gastric Banding", count: 21 },
  { label: "Intragastric Boolon", count: 23 },
  { label: "Other", count: 23 },
];
const surgeryData = surgeryGroup.map((group) => ({
  name: group.label,
  y: group.count,
}));

exporting(Highcharts);
exportData(Highcharts);

Highcharts.setOptions({
  colors: [
    "#084FD7",
    "#64BDC6",
    "#EECA34",
    "#FE7135",
    "#FD2A2A",
    "#EE60E0",
    "#7B47E9",
    "#5D89DF",
    "#DF5353",
    "#FEB56A",
    "#6975FF",
  ],
});
dayjs.locale("th");

const menuItems = [
  { value: "ภาพรวม", label: "ภาพรวม" },
  {
    value: "Roux-en-Y gastric bypass (RYGB)",
    label: "Roux-en-Y gastric bypass (RYGB)",
  },
  { value: "Sleeve Gastrectomy (SG)", label: "Sleeve Gastrectomy (SG)" },
  { value: "Sleeve-DJB (Roux-en-Y)", label: "Sleeve-DJB (Roux-en-Y)" },
  { value: "Sleeve-DJB (Loop)", label: "Sleeve-DJB (Loop)" },
  { value: "Sleeve-PJB", label: "Sleeve-PJB" },
  { value: "SADI-S", label: "SADI-S" },
  { value: "Gastric Banding", label: "Gastric Banding" },
  { value: "Intragastric Balloon", label: "Intragastric Balloon" },
  {
    value: "Endoscopic Sleeve Gastroplasty",
    label: "Endoscopic Sleeve Gastroplasty",
  },
];

const Dashboard = () => {
  const navigate = useNavigate();
  const stackRef = useRef<HTMLDivElement>(null);
  const [ewlType, setEwlType] = useState("ภาพรวม");
  const [dashboardView, setDashboardView] = useState("โรงพยาบาลทั้งหมด");
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [userPermissionList, setUserPermissionList] = useState<
    UserPermission[]
  >([]);
  const [chartData, setChartData] = useState(dailyData);
  const [title, setTitle] = useState("Daily");
  const userPermission = useSelector((state: any) => state.userPermission);
  const [boxWidth, setBoxWidth] = useState(0);
  const [userAnchorEl, setUserAnchorEl] = useState<any | null>(null);
  useEffect(() => {
    const handleResize = () => {
      if (stackRef.current) {
        const stackWidth = stackRef.current.clientWidth;
        setBoxWidth(stackWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token || token === null) {
          setIsTokenExpired(true);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setIsTokenExpired(true);
        } else {
          console.log("Error fetching List", error);
        }
      }
    };
    fetchData();
  });
  const handleDataChange = (data: (string | number)[][], title: string) => {
    setChartData(data);
    setTitle(title);
  };
  const allPatientOptions = {
    chart: {
      type: "pie",
      height: 250,
      renderTo: "container",
      style: {
        fontFamily: "Sarabun",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          distance: -30,
          format: "{point.percentage:.0f}%",
          style: {
            fontSize: "0.8em",
            color: "white",
            textOutline: "none",
          },
        },
        showInLegend: true,
      },
    },
    legend: {
      labelFormatter(this: Highcharts.Point): string {
        return `${this.name}: ${this.y}`;
      },
    },
    series: [
      {
        name: "Patient",
        colorByPoint: true,
        data: [
          { name: "Male", y: maleCount },
          { name: "Female", y: femaleCount },
        ],
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "AllPatient",
    },
  };
  const allTypeOptions = {
    chart: {
      type: "pie",
      height: 250,
      renderTo: "container",
      style: {
        fontFamily: "Sarabun",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        dataLabels: {
          enabled: true,
          distance: -20,
          format: "{point.percentage:.0f}%",
          style: {
            fontSize: "0.8em",
            textOutline: "none",
          },
          filter: {
            operator: ">",
            property: "percentage",
            value: 10,
          },
        },
        showInLegend: true,
      },
    },
    // legend: {
    //   labelFormatter(this: Highcharts.Point): string {
    //     return `${this.name}: ${this.y}`;
    //   },
    // },
    legend: {
      useHTML: true,
      labelFormatter(this: Highcharts.Point): string {
        return `<div>${this.name} ${this.y}</div>`;
      },
    },
    series: [
      {
        name: "Patient",
        colorByPoint: true,
        data: [
          { name: "Register", y: register },
          { name: "Pre-op", y: preOp },
          { name: "Post-op", y: postOp },
          { name: "End of study", y: endOfStudy },
        ],
      },
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "AllPatient",
    },
  };
  const newRegistrationOptions = {
    chart: {
      type: "column",
      height: 200,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat:
        '<span style="font-size: 14px"><b>{point.y} คน</b></span><br/>',
      pointFormatter: function (this: Highcharts.Point): string {
        if (title === "Daily") {
          const date = dayjs(this.name);
          return `${date.format("dddd")}, ${date.format("D MMMM BBBB")}`;
        } else {
          return `${this.name}`;
        }
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth: 15,
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "New Users",
        data: chartData,
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "NewRegistrationUser",
    },
  };
  const ageOptions = {
    chart: {
      type: "column",
      height: 270,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      categories: ageGroups.map((group) => group.label),
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "จำนวน (คน)",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth: 50,
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Age Groups",
        data: ageGroupedData,
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "age",
    },
  };
  const surgeryOptions = {
    chart: {
      type: "column",
      height: 270,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "category",
      title: {
        text: "",
      },
      categories: surgeryGroup.map((group) => group.label),
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "จำนวน (คน)",
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        groupPadding: 0.1,
        pointWidth: 35,
        color: "#DF5C8E",
      },
      series: {
        borderRadius: 5,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "การผ่าตัด",
        data: surgeryData,
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "age",
    },
  };
  const bmiOptions = {
    chart: {
      type: "pie",
      height: 270,
      width: null,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: "60%",
        dataLabels: {
          distance: 5,
          style: {
            fontSize: "12px",
          },
        },
      },
      series: {
        cursor: "pointer",
        allowPointSelect: false,
        dataLabels: [
          {
            enabled: true,
            distance: 5,
          },
          {
            enabled: true,
            distance: -20,
            format: "{point.percentage:.0f}%",
            style: {
              fontSize: "0.8em",
              textOutline: "none",
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
        showInLegend: false,
      },
    },
    series: [
      {
        name: "BMI",
        colorByPoint: true,
        // innerSize: "40%",
        data: [
          {
            name: "37.5-50",
            // sliced: true,
            y: 23.9,
          },
          {
            name: "> 50",
            y: 12.6,
          },
          {
            name: "< 25",
            y: 37.0,
          },
          {
            name: "25-32.5",
            y: 26.4,
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "bmi",
    },
  };
  const commoditiesOptions = {
    chart: {
      type: "pie",
      height: 270,
      width: null,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        size: "60%",
        dataLabels: {
          distance: 5,
          style: {
            fontSize: "12px",
          },
        },
      },
      series: {
        cursor: "pointer",
        allowPointSelect: false,
        dataLabels: [
          {
            enabled: true,
            distance: 5,
          },
          {
            enabled: true,
            distance: -20,
            format: "{point.percentage:.0f}%",
            style: {
              fontSize: "0.8em",
              textOutline: "none",
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
          },
        ],
        showInLegend: false,
      },
    },
    series: [
      {
        name: "BMI",
        colorByPoint: true,
        // innerSize: "40%",
        data: [
          {
            name: "โรคข้อเข่าเสื่อม",
            y: 23.9,
          },
          {
            name: "PCOS",
            y: 12.6,
          },
          {
            name: "Gallstones",
            y: 37.0,
          },
          {
            name: "CVA",
            y: 26.4,
          },
          {
            name: "HT",
            y: 5,
          },
          {
            name: "DM",
            y: 8.3,
          },
          {
            name: "DLP",
            y: 3.5,
          },
          {
            name: "NAFLD/NASH",
            y: 8,
          },
          {
            name: "CAD",
            y: 8.2,
          },
          {
            name: "CVA",
            y: 7.6,
          },
          {
            name: "อื่นๆ",
            y: 1,
          },
        ],
      },
    ],
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "bmi",
    },
  };
  const EwlOptions = {
    chart: {
      height: 270,
      width: null,
      style: {
        fontFamily: "Sarabun",
      },
    },
    title: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: [
        "2WK",
        "1M",
        "3M",
        "6M",
        "9M",
        "1Y",
        "1.6Y",
        "2Y",
        "3Y",
        "4Y",
        "5Y",
        "5Y+",
      ],
      title: {
        text: "",
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },
    series: [
      {
        name: "EWL%",
        data: [5, 10, 25, 40, 50, 60, 65, 70, 75, 80, 85, 90],
      },
      {
        name: "tWL%",
        data: [3, 7, 15, 25, 35, 45, 50, 55, 60, 65, 70, 75],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "ewl_vs_twl",
    },
  };

  return (
    <Layout activeTab="dashboard">
      <div
        style={{
          minHeight: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            p: 2,
            pb: 0,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Select
            value={dashboardView}
            size="small"
            onChange={(e) => {
              setUserAnchorEl(e.currentTarget);
              setDashboardView(e.target.value);
            }}
            sx={{ fontSize: "14px", width: 200 }}
            MenuProps={{
              sx: { zoom: 100 * (100 / 85) + "%", top: "0px" },
            }}
          >
            <MenuItem value={"โรงพยาบาลทั้งหมด"} sx={{ fontSize: 12 }}>
              โรงพยาบาลทั้งหมด
            </MenuItem>
            <MenuItem value={"โรงพยาบาลจุฬาลงกรณ์"} sx={{ fontSize: 12 }}>
              โรงพยาบาลจุฬาลงกรณ์
            </MenuItem>
          </Select>
        </Box>
        <Box
          sx={{
            height: "calc(100% - 70px - 16px)",
            bgcolor: "white",
            m: 2,
            overflow: "auto",
            scrollbarWidth: "thin",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              minWidth: 600,
            }}
          >
            <Box sx={{ borderBottom: "2px solid #D8D8D8" }}>
              <Box
                component={"span"}
                sx={{
                  display: "inline-block",
                  minWidth: "185px",
                  borderBottom: "4px solid #DF5C8E",
                  p: 1.5,
                  fontSize: 18,
                  fontWeight: 600,
                  ml: 1.5,
                  boxSizing: "border-box",
                }}
              >
                ภาพรวม
              </Box>
            </Box>
            <Stack spacing={2}>
              <Box
                id="dashboard-row-1"
                ref={stackRef}
                sx={{
                  width: 1,
                  display: "flex",
                  gap: 2,
                  height: 330,
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 250,
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนคนไข้ทั้งหมด
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        {`${maleCount + femaleCount} คน`}
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={allPatientOptions}
                    />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนคนแบ่งตามประเภทผู้ป่วย
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        {`${register + preOp + postOp + endOfStudy} คน`}
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={allTypeOptions}
                    />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนคนลงทะเบียนใหม่
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        20 คน
                      </Typography>
                    </Box>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={newRegistrationOptions}
                    />
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gap={1}
                      sx={{ bgcolor: "#E8E9EE", borderRadius: 2, p: 1, mx: 2 }}
                    >
                      <Box
                        sx={{
                          bgcolor: title === "Daily" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(dailyData, "Daily")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Daily" ? 700 : 500}
                        >
                          Daily
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: title === "Monthly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(monthlyData, "Monthly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Monthly" ? 700 : 500}
                        >
                          Monthly
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: title === "Yearly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(yearlyData, "Yearly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Yearly" ? 700 : 500}
                        >
                          Yearly
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 2 / 5,
                    minWidth: 500,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        อายุ (ปี)
                      </Typography>
                    </Box>

                    <HighchartsReact
                      highcharts={Highcharts}
                      options={ageOptions}
                    />
                  </Paper>
                </Box>
              </Box>
              <Box
                id="dashboard-row-2"
                sx={{
                  width: 1,
                  display: "flex",
                  gap: 2,
                  height: 330,
                  p: 2,
                  pt: 0,
                }}
              >
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        BMI (Pre-op)
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={bmiOptions}
                    />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        Commodities & Complication
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={commoditiesOptions}
                    />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        จำนวนผู้กรอกแบบสุขภาพประจำวัน
                      </Typography>
                      <Typography fontSize={24} fontWeight={700}>
                        12 คน
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={newRegistrationOptions}
                    />
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gap={1}
                      sx={{ bgcolor: "#E8E9EE", borderRadius: 2, p: 1, mx: 2 }}
                    >
                      <Box
                        sx={{
                          bgcolor: title === "Daily" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(dailyData, "Daily")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Daily" ? 700 : 500}
                        >
                          Daily
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: title === "Monthly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(monthlyData, "Monthly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Monthly" ? 700 : 500}
                        >
                          Monthly
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: title === "Yearly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(yearlyData, "Yearly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Yearly" ? 700 : 500}
                        >
                          Yearly
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 1,
                    width: 2 / 5,
                    minWidth: 500,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ borderRadius: 2, height: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        ข้อมูลจำนวนการผ่าตัดแบ่งตามชนิดการผ่าตัด
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={surgeryOptions}
                    />
                  </Paper>
                </Box>
              </Box>
              {/* <Box
                sx={{
                  width: 1,
                  display: "flex",
                  gap: 2,
                  height: 1,
                  boxSizing: "border-box",
                  p: 2,
                  pt: 0,
                }}
              >
                <Box
                  sx={{
                    height: 1,
                    width: 1,
                    boxSizing: "border-box",
                  }}
                >
                  {boxWidth}
                  <Paper sx={{ borderRadius: 2, height: 1, width: 1 }}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        ค่าเฉลี่ย %EWL, %TWL
                      </Typography>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={EwlOptions}
                    />
                  </Paper>
                </Box>
              </Box> */}
              <Box
                sx={{
                  width: boxWidth,
                  p: 2,
                  boxSizing: "border-box",
                  pt: 0,
                }}
              >
                <Paper sx={{ borderRadius: 2, height: 1, width: 1 }}>
                  <Box sx={{ p: 2, pb: 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Typography fontSize={14} color={"#4d4d4d"}>
                        ค่าเฉลี่ย %EWL, %TWL
                      </Typography>
                      <Select
                        id="ewlType"
                        value={ewlType}
                        size="small"
                        onChange={(e) => setEwlType(e.target.value)}
                        sx={{
                          fontSize: 14,
                          width: 250,
                        }}
                        MenuProps={{
                          sx: {
                            zoom: 100 * (100 / 85) + "%",
                            top: "0px",
                            height: "300px",
                          },
                        }}
                      >
                        {menuItems.map((item) => (
                          <MenuItem
                            key={item.value}
                            value={item.value}
                            sx={{ fontSize: 12 }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={EwlOptions}
                  />
                </Paper>
              </Box>
            </Stack>
            {/* <Stack spacing={4} height={300}>
              <Box sx={{ width: 1, display: "flex", gap: 2 }}>
                <Box
                  sx={{
                    height: 300,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Typography fontSize={14} color={"#4d4d4d"}>
                      จำนวนคนไข้ทั้งหมด
                    </Typography>
                    <Typography fontSize={24} fontWeight={700}>
                      885 คน
                    </Typography>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={allPatientOptions}
                    />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 300,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      height: 1,
                    }}
                  >
                    <Typography fontSize={14} color={"#4d4d4d"}>
                      จำนวนคนไข้ทั้งหมด
                    </Typography>
                    <Typography fontSize={24} fontWeight={700}>
                      885 คน
                    </Typography>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={allPatientOptions}
                    />
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 300,
                    width: 1 / 5,
                    minWidth: 250,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ p: 2, borderRadius: 2, height: 1 }}>
                    <Typography fontSize={14} color={"#4d4d4d"}>
                      จำนวนคนลงทะเบียนใหม่
                    </Typography>
                    <Typography fontSize={24} fontWeight={700}>
                      20 คน
                    </Typography>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={newRegistrationOptions}
                    />
                    <Box
                      display={"grid"}
                      gridTemplateColumns={"1fr 1fr 1fr"}
                      gap={1}
                      sx={{ bgcolor: "#E8E9EE", borderRadius: 2, p: 1 }}
                    >
                      <Box
                        sx={{
                          bgcolor: title === "Daily" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(dailyData, "Daily")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Daily" ? 700 : 500}
                        >
                          Daily
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: title === "Monthly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(monthlyData, "Monthly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Monthly" ? 700 : 500}
                        >
                          Monthly
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          bgcolor: title === "Yearly" ? "white" : "",
                          px: 1,
                          py: 0.5,
                          borderRadius: 2,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDataChange(yearlyData, "Yearly")}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={title === "Yearly" ? 700 : 500}
                        >
                          Yearly
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
                <Box
                  sx={{
                    height: 300,
                    width: 2 / 5,
                    minWidth: 500,
                    boxSizing: "border-box",
                  }}
                >
                  <Paper sx={{ p: 2, borderRadius: 2, height: 1 }}>
                    <Typography fontSize={14} color={"#4d4d4d"}>
                      อายุ (ปี)
                    </Typography>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={ageOptions}
                    />
                  </Paper>
                </Box>
              </Box>
              <Box>yyy</Box>
            </Stack> */}
          </Box>
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};

export default Dashboard;
