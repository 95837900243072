import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import { ulid } from "ulid";
import { Rearrange } from "../../asset/iconSvg";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";
import {
  AddIcon,
  BookmarkBorderIcon,
  DeleteIcon,
  EditIcon,
  ReorderIcon,
} from "../../icon";
import { Layout } from "../../Layout";
import { MasterDataFiltering } from "../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../style";
import { theme2 } from "../../theme";
import { useNavigate } from "react-router";
import { masterDataService } from "../../service/masterDataService";
import { dataTagSymbol, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import { ErrorDialog } from "../../components/dialog/ErrorDialog";
import { store } from "../../redux/store";

type HospitalItem = {
  id: number;
  nameThai: string;
  contactInfo: string;
  location: string;
  isActivated: boolean;
  sortingOrder?: number;
};
type CreateUpdateHospitalMasterPayload = {
  id?: number;
  nameThai: string;
  contactInfo: string;
  location: string;
  isActivated: boolean;
};
type UpdateHospitalOrderMasterPayload = {
  id: number;
  sortingOrder: number;
};
const tableCellValues = [
  { name: "ลำดับ", width: "10%", align: "center" },
  { name: "ชื่อโรงพยาบาล", width: "30%", align: "left" },
  { name: "การติดต่อ", width: "10%", align: "left" },
  { name: "ที่อยู่", width: "30%", align: "left" },
  { name: "สถานะ", width: "10%", align: "left" },
  { name: "เครื่องมือ", width: "10%", align: "center" },
];

const findMaxDisplayOrder = (list: HospitalItem[]): number => {
  return Math.max(...list.map((item) => item.sortingOrder || 0), 0);
};

export const HospitalMaster = () => {
  const navigate = useNavigate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [hospitalList, setHospitalList] = useState<HospitalItem[]>([]);
  const [filteredList, setFilteredList] = useState<HospitalItem[]>([]);
  const [reorderableHospitals, setReorderableHospitals] = useState<
    HospitalItem[]
  >([]);
  const [currentHospitalItem, setCurrentHospitalItem] =
    useState<HospitalItem>();
  const [searchText, setSearchText] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRearrangeDialog, setOpenRearrangeDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemIndex, setDeleteItemIndex] = useState(0);
  const [activeStatus, setActiveStatus] = useState(true);
  const [isFetchData, setIsFetchData] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [messageError, setMessageError] = useState(
    "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
  );

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const masterDataPermissionAddAble =
    userId === "1" ? true : userPermission?.masterDataPermission.addAble;
  const masterDataPermissionEditAble =
    userId === "1" ? true : userPermission?.masterDataPermission.editAble;
  const masterDataPermissionDeleteAble =
    userId === "1" ? true : userPermission?.masterDataPermission.deleteAble;
  // const [boxHeight, setBoxHeight] = useState("calc(100vh - 162px - 56px)");

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (filterRef.current) {
  //       const stackHeight = filterRef.current.clientHeight;
  //       const calculatedHeight =
  //         stackHeight > 56
  //           ? `calc(100vh - ${stackHeight}px - 56px)`
  //           : "calc(100vh - 162px - 56px)";
  //       setBoxHeight(calculatedHeight);
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const queryFunction = async () => {
    const response = await masterDataService.getHospitalMasterData();
    return response;
  };
  const {
    data: hospitalData,
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ["hospitalMasterData"],
    queryFn: queryFunction,
    enabled: false,
  });
  const {
    mutateAsync: createHospitalMasterAsync,
    isPending: IsPendingCreateHospitalMaster,
  } = useMutation({
    mutationFn: async ({
      nameThai,
      contactInfo,
      location,
      isActivated,
    }: CreateUpdateHospitalMasterPayload) => {
      const response = await masterDataService.createHospitalMaster({
        nameThai: nameThai,
        contactInfo: contactInfo,
        location: location,
        isActivated: isActivated,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const {
    mutateAsync: updateHospitalOrderMasterAsync,
    isPending: IsPendingUpdateHospitalOrderMaster,
  } = useMutation({
    mutationFn: async (hospitalOrders: UpdateHospitalOrderMasterPayload[]) => {
      const response = await masterDataService.updateHospitalOrderMaster({
        hospitalOrders: hospitalOrders,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const {
    mutateAsync: updateHospitalMasterAsync,
    isPending: IsPendingUpdateHospitalMaster,
  } = useMutation({
    mutationFn: async ({
      id,
      nameThai,
      contactInfo,
      location,
      isActivated,
    }: CreateUpdateHospitalMasterPayload) => {
      const response = await masterDataService.updateHospitalMaster({
        id: id,
        nameThai: nameThai,
        contactInfo: contactInfo,
        location: location,
        isActivated: isActivated,
      });
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          setIsShowError(true);
        }
      }
    },
  });
  const { mutateAsync: deleteHospitalMasterAsync } = useMutation({
    mutationFn: async (id: string) => {
      const response = await masterDataService.deleteHospitalMaster(id);
      return response;
    },
    onError(error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setIsTokenExpired(true);
        } else {
          if (
            error.response?.data.message ===
            "Cannot perform operation, data is in use"
          ) {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล เนื่องจากมีการใช้ข้อมูลทำให้ไม่สามารถลบได้"
            );
          } else {
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }
          setIsShowError(true);
        }
      }
    },
  });
  useEffect(() => {
    const sortHospitalData = (data: HospitalItem[]) => {
      return data.sort((a, b) => {
        const orderA =
          a.sortingOrder !== null && a.sortingOrder !== undefined
            ? a.sortingOrder
            : Number.MAX_SAFE_INTEGER;
        const orderB =
          b.sortingOrder !== null && b.sortingOrder !== undefined
            ? b.sortingOrder
            : Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });
    };
    refetch();
    if (hospitalData) {
      const sortedData = sortHospitalData(hospitalData);
      setHospitalList(sortedData);
      setFilteredList(sortedData);
    } else {
      setHospitalList([]);
      setFilteredList([]);
    }
  }, [isFetchData, hospitalData]);
  useEffect(() => {
    if (error && error instanceof AxiosError) {
      if (error.response?.status === 401) {
        setIsTokenExpired(true);
      } else {
        setIsShowError(true);
      }
    }
  }, [error]);
  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const reorderedItems = Array.from(reorderableHospitals);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    setReorderableHospitals(reorderedItems);
  };
  const handleOpenRearrangeDialog = () => {
    setReorderableHospitals(hospitalList.filter((item) => item.isActivated));
    setOpenRearrangeDialog(true);
  };
  const handleSubmitReorder = async () => {
    const reorderedList = reorderableHospitals.map((item, index) => ({
      ...item,
      sortingOrder: index + 1,
    }));
    // const finalList = [
    //   ...reorderedList,
    //   ...hospitalList.filter((item) => !item.isActivated),
    // ];
    // setHospitalList(finalList);
    // setFilteredList(finalList);
    const hospitalOrders = reorderedList.map((item) => ({
      id: item.id,
      sortingOrder: item.sortingOrder,
    }));
    await updateHospitalOrderMasterAsync(hospitalOrders);
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handlePageChange = (event: unknown, value: number) => {
    setPage(value);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchStatusChange = (newValue: string) => {
    setSearchStatus(newValue);
  };
  const handleSearchClick = () => {
    const result = hospitalList.filter((item) => {
      const matchesText = item.nameThai
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const currentStatus = item.isActivated ? "ใช้งานได้" : "ปิดการใช้งาน";
      const matchesStatus = searchStatus
        ? currentStatus === searchStatus
        : true;
      return matchesStatus && matchesText;
    });
    setPage(1);
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setSearchStatus("");
    setFilteredList(hospitalList);
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenRearrangeDialog(false);
  };
  const handleAddNewItem = async (data: FieldValues) => {
    await createHospitalMasterAsync({
      nameThai: data.nameThai,
      contactInfo: data.contactInfo,
      location: data.location,
      isActivated: activeStatus,
    });
    setIsFetchData((prev) => !prev);
    // const maxDisplayOrder = findMaxDisplayOrder(hospitalList);
    // const newItem = {
    //   id: ulid(),
    //   nameThai: data.nameThai,
    //   contactInfo: data.contactInfo,
    //   location: data.location,
    //   isActivated: activeStatus,
    //   sortingOrder: activeStatus ? maxDisplayOrder + 1 : undefined,
    // };
    // setFilteredList([...hospitalList, newItem]);
    // hospitalList.push(newItem);
    handleCloseDialog();
  };
  const handleDeleteClick = (index: number) => {
    setDeleteItemIndex(index);
    setOpenDeleteDialog(true);
  };
  const handleEditClick = (data: HospitalItem) => {
    setOpenEditDialog(true);
    setCurrentHospitalItem(data);
    setActiveStatus(data.isActivated);
  };
  const handleEditItem = async (data: HospitalItem) => {
    // const maxDisplayOrder = findMaxDisplayOrder(hospitalList);
    // setHospitalList((prevList) =>
    //   prevList.map((item) =>
    //     item.nameThai === currentHospitalItem?.nameThai
    //       ? {
    //           ...data,
    //           isActivated: activeStatus,
    //           sortingOrder: item.sortingOrder
    //             ? item.sortingOrder
    //             : activeStatus
    //             ? maxDisplayOrder + 1
    //             : undefined,
    //         }
    //       : item
    //   )
    // );
    // setFilteredList((prevList) =>
    //   prevList.map((item) =>
    //     item.nameThai === currentHospitalItem?.nameThai
    //       ? {
    //           ...data,
    //           isActivated: activeStatus,
    //           sortingOrder: item.sortingOrder
    //             ? item.sortingOrder
    //             : activeStatus
    //             ? maxDisplayOrder + 1
    //             : undefined,
    //         }
    //       : item
    //   )
    // );
    if (currentHospitalItem) {
      await updateHospitalMasterAsync({
        id: currentHospitalItem.id,
        nameThai: data.nameThai,
        contactInfo: data.contactInfo,
        location: data.location,
        isActivated: activeStatus,
      });
      setIsFetchData((prev) => !prev);
      handleCloseDialog();
    }
  };
  const handleConfirmDeleteDialog = async () => {
    // setHospitalList((prevList) =>
    //   prevList.filter((_, i) => i !== deleteItemTemp)
    // );
    // setFilteredList((prevList) =>
    //   prevList.filter((_, i) => i !== deleteItemTemp)
    // );
    await deleteHospitalMasterAsync(deleteItemIndex.toString());
    setIsFetchData((prev) => !prev);
    handleCloseDialog();
  };
  const handleAddClick = () => {
    setActiveStatus(true);
    setOpenAddDialog(true);
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1 }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`โรงพยาบาล`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              title="โรงพยาบาล"
              searchText={searchText}
              searchStatus={searchStatus}
              searchTextLabel="ค้นหาชื่อโรงพยาบาล"
              handleSearchTextChange={handleTextInputChange}
              handleSearchStatusChange={handleSearchStatusChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              // height: boxHeight,
              mt: 2,
              overflow: "auto",
              scrollbarWidth: "thin",
              p: "20px",
              boxSizing: "border-box",
            }}
          >
            <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
              {masterDataPermissionEditAble && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#FBC235",
                    height: "40px",
                    fontSize: "14px",
                    marginBottom: "20px",
                  }}
                  startIcon={<Rearrange />}
                  onClick={handleOpenRearrangeDialog}
                >
                  ลำดับการแสดงผล
                </Button>
              )}
              {masterDataPermissionAddAble && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#FF366B",
                    height: "40px",
                    fontSize: "14px",
                    marginBottom: "20px",
                    minWidth: "90px",
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleAddClick}
                >
                  เพิ่ม
                </Button>
              )}
            </Stack>

            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                            textAlign: item.align,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ textAlign: "center" }}>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.nameThai}</TableCell>
                            <TableCell>{item.contactInfo}</TableCell>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor: item.isActivated
                                      ? "green"
                                      : "red",
                                  }}
                                />
                                {item.isActivated
                                  ? "ใช้งานได้"
                                  : "ปิดการใช้งาน"}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Stack
                                spacing={1}
                                direction={"row"}
                                justifyContent={"center"}
                              >
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: 14,
                                    cursor: masterDataPermissionEditAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents: masterDataPermissionEditAble
                                      ? "auto"
                                      : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionEditAble) {
                                      handleEditClick(item);
                                    }
                                  }}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    border: "1px solid #7A8688",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "#7A8688",
                                    fontSize: 14,
                                    cursor: masterDataPermissionDeleteAble
                                      ? "pointer"
                                      : "not-allowed",
                                    pointerEvents:
                                      masterDataPermissionDeleteAble
                                        ? "auto"
                                        : "none",
                                  }}
                                  onClick={() => {
                                    if (masterDataPermissionDeleteAble) {
                                      handleDeleteClick(item.id);
                                    }
                                  }}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  {isLoading ? (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <CircularProgress size={20} />
                      {` กำลังโหลดข้อมูล...`}
                    </Box>
                  ) : (
                    "--ไม่พบข้อมูล--"
                  )}
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อโรงพยาบาล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="nameThai"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>การติดต่อ</Typography>
                  <TextFieldElement
                    name="contactInfo"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ที่อยู่</Typography>
                  <TextFieldElement
                    name="location"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={activeStatus}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={IsPendingCreateHospitalMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              onSuccess={handleEditItem}
              defaultValues={{
                nameThai: currentHospitalItem?.nameThai,
                contactInfo: currentHospitalItem?.contactInfo,
                location: currentHospitalItem?.location,
                sortingOrder: currentHospitalItem?.sortingOrder,
              }}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อโรงพยาบาล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="nameThai"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>การติดต่อ</Typography>
                  <TextFieldElement
                    name="contactInfo"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ที่อยู่</Typography>
                  <TextFieldElement
                    name="location"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ลำดับการแสดงผล</Typography>
                  <TextFieldElement
                    name="sortingOrder"
                    variant="outlined"
                    size="small"
                    disabled
                    sx={{ bgcolor: "#E3E7F0" }}
                    InputProps={{
                      value: currentHospitalItem?.sortingOrder,
                      sx: {
                        width: 1,
                        bgcolor: "#E3E7F0",
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "#3C4142",
                        },
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentHospitalItem?.isActivated ? true : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <LoadingButton
                    loading={IsPendingUpdateHospitalMaster}
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: "#098484",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#098484",
                      },
                    }}
                  >
                    บันทึก
                  </LoadingButton>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
        {/* Failure Dialog */}
        <ErrorDialog
          openDialog={isShowError}
          handleCloseDialog={() => {
            setIsShowError(false);
            setMessageError(
              "เกิดข้อผิดพลาดในการอัปเดตข้อมูล กรุณาลองใหม่อีกครั้ง"
            );
          }}
          title="เกิดข้อผิดพลาด"
          content={messageError}
        />
        {/* Session Expired Dialog */}
        <ErrorDialog
          title="เซสชันหมดอายุ"
          content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
          openDialog={isTokenExpired}
          handleCloseDialog={() => {
            navigate("/");
            setIsTokenExpired(false);
          }}
        />
        {/* Rearrange  Dialog */}
        <Dialog
          open={openRearrangeDialog}
          onClose={handleCloseDialog}
          fullWidth
        >
          <DialogTitle>เรียงลำดับ</DialogTitle>
          <DialogContent dividers>
            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="hospitals">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {reorderableHospitals.map((hospital, index) =>
                      // If index is 0, render a non-draggable item
                      index === 0 ? (
                        <Box
                          key={hospital.id}
                          p={2}
                          mb={2}
                          bgcolor="#E3E7F0"
                          borderRadius={2}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <BookmarkBorderIcon />
                            {hospital.nameThai}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {`ลำดับที่ ${index + 1}`}
                            <ReorderIcon />
                          </Box>
                        </Box>
                      ) : (
                        <Draggable
                          key={hospital.id}
                          draggableId={hospital.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              p={2}
                              mb={2}
                              bgcolor="#F7F6FA"
                              borderRadius={2}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <BookmarkBorderIcon />
                                {hospital.nameThai}
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                {`ลำดับที่ ${index + 1}`}
                                <ReorderIcon />
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="hospitals">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {reorderableHospitals.map((hospital, index) => (
                      <Draggable
                        key={hospital.id}
                        draggableId={hospital.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            p={2}
                            mb={2}
                            bgcolor="#f7f7f7"
                            borderRadius={2}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <BookmarkBorderIcon />
                              {hospital.nameThai}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {`ลำดับที่ ${index + 1}`}
                              <ReorderIcon />
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
            <Box>
              <Divider sx={{ my: 2 }} />
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                <Button
                  onClick={handleCloseDialog}
                  style={{ backgroundColor: "#7A8688", color: "white" }}
                >
                  ยกเลิก
                </Button>
                <LoadingButton
                  loading={IsPendingUpdateHospitalOrderMaster}
                  onClick={handleSubmitReorder}
                  variant="contained"
                  sx={{
                    backgroundColor: "#098484",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#098484",
                    },
                  }}
                >
                  บันทึก
                </LoadingButton>
              </Stack>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </Layout>
  );
};
