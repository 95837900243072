import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  Button,
  TextField,
  DialogActions,
  Box,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { calculateBmi } from "../../../utils/calculateBmi";
import { calculateIdealWeight } from "../../../utils/calculateIdealWeight";
import { calculateEWL } from "../../../utils/calculateEWL";
import { calculateTWL } from "../../../utils/calculateTWL";
import { calculateEBMIL } from "../../../utils/calculateEBMIL";
import { store } from "../../../redux/store";

interface BodyComposition {
  sbp: string;
  dbp: string;
  weight: number;
  smm: string;
  bfm: string;
  pbf: string;
  previousWeight?: number | null;
  height?: number | null;
}

interface BodyAndCompositionDialogProps extends BodyComposition {
  open: boolean;
  onClose: (data: BodyComposition) => void;
  onSave: (data: BodyComposition) => void;
}
export const BodyAndCompositionDialog: React.FC<
  BodyAndCompositionDialogProps
> = ({
  open,
  onClose,
  onSave,
  sbp,
  dbp,
  weight,
  smm,
  bfm,
  pbf,
  previousWeight,
  height,
}) => {
  const initialValues = useMemo(
    () => ({ sbp, dbp, weight, smm, bfm, pbf, previousWeight, height }),
    [sbp, dbp, weight, smm, bfm, pbf, previousWeight, height]
  );

  const [bodyComposition, setBodyComposition] =
    useState<BodyComposition>(initialValues);
  const [bodyCompositionTemp, setBodyCompositionTemp] =
    useState<BodyComposition>(initialValues);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  
  useEffect(() => {
    setBodyComposition(initialValues);
    setBodyCompositionTemp(initialValues);
  }, [initialValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyComposition({
      ...bodyComposition,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickSave = () => {
    if(patientDataPermissionEditAble === true){
      onSave(bodyComposition);
    } else {
      setBodyComposition(bodyCompositionTemp);
      onClose(bodyCompositionTemp);
    }
    
  };
  const handleCloseDialog = () => {
    setBodyComposition(bodyCompositionTemp);
    onClose(bodyCompositionTemp);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Body Composition</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              SBP <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="sbp"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={bodyComposition.sbp}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mmHg</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              DBP <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="dbp"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={bodyComposition.dbp}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mmHg</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Weight <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="Weight"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "480px", backgroundColor: "#E5EBEB" }}
                disabled
                value={weight}
              />
              <span style={{ marginLeft: "10px" }}>kg</span>
            </div>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <Box
              display={"grid"}
              gridTemplateColumns={"2fr 1fr"}
              sx={{ pb: 1 }}
            >
              <Typography sx={{ color: "#646D78" }}>BMI</Typography>
              <Typography sx={{ color: "#098484" }}>
                {weight && height
                  ? calculateBmi(weight, height).toString()
                  : "(Auto calculation)"}
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <Box
              display={"grid"}
              gridTemplateColumns={"2fr 1fr"}
              sx={{ pb: 1 }}
            >
              <Typography sx={{ color: "#646D78" }}>Ideal weight</Typography>
              <Typography sx={{ color: "#098484" }}>
                {height
                  ? calculateIdealWeight(height).toString()
                  : "(Auto calculation)"}
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <Box
              display={"grid"}
              gridTemplateColumns={"2fr 1fr"}
              sx={{ pb: 1 }}
            >
              <Typography sx={{ color: "#646D78" }}>
                Percent of excess weight loss (%EWL)
              </Typography>
              <Typography sx={{ color: "#098484" }}>
                {weight && previousWeight && height
                  ? calculateEWL(
                      weight,
                      previousWeight,
                      calculateIdealWeight(height)
                    ).toString()
                  : "(Auto calculation)"}
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <Box
              display={"grid"}
              gridTemplateColumns={"2fr 1fr"}
              sx={{ pb: 1 }}
            >
              <Typography sx={{ color: "#646D78" }}>
                Percent of total weight loss (%TWL)
              </Typography>
              <Typography sx={{ color: "#098484" }}>
                {weight && previousWeight
                  ? calculateTWL(weight, previousWeight).toString()
                  : "(Auto calculation)"}
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <Box
              display={"grid"}
              gridTemplateColumns={"2fr 1fr"}
              sx={{ pb: 1 }}
            >
              <Typography sx={{ color: "#646D78" }}>
                Percent excess BMI loss (%EBMIL)
              </Typography>
              <Typography sx={{ color: "#098484" }}>
                {weight && previousWeight && height
                  ? calculateEBMIL(weight, previousWeight, height).toString()
                  : "(Auto calculation)"}
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              SMM <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="smm"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={bodyComposition.smm}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>kg</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              BFM <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="bfm"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={bodyComposition.bfm}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>kg</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              PBF <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="pbf"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={bodyComposition.pbf}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>%</span>
            </div>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
