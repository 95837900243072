import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useMemo, useState } from "react";
import { LoadingComponent } from "../../components/LoadingComponent";
import { dataCollectionService } from "../../service/dataCollectionService";
import { visitDataService } from "../../service/visitDataService";
import { title18700 } from "../../style";
import {
  AllCollectData,
  BariaticSurgeryData,
  PatientUser,
  PatientUserProfile,
  VisitData,
} from "../../type";
import { generateDataSeries } from "../../utils/generateDataSeries";
import { generateXLabels } from "../../utils/generateXLabels";
import {
  getEarliestDate,
  getLatestDate,
  getMaxWeight,
  getMinWeight,
  getWeightEarliestDate,
} from "../../utils/getWDataDetails";
import { BmiCard } from "./BmiCard";
import { ExerciseCard } from "./ExerciseCard";
import { HistoryCard } from "./HistoryCard";
import { MealCard } from "./MealCard";
import "./PatientDashboard.css";
import { PatientHistoryDetail } from "./PatientHistoryDetail";
import { PatientProfileDetail } from "./PatientProfileDetail";
import { ProfileCard } from "./ProfileCard";

type Props = {
  patientUser: PatientUser;
  patientUserProfile: PatientUserProfile;
  isHistory: boolean;
  isProfile: boolean;
  selectedHistoryDate: string;
  handleHistoryClick: (selectedDate: string) => void;
  handelViewProfile: () => void;
  handleGobackClick: () => void;
};
type WeightWithDate = {
  weight: number;
  date: string;
};
type WeightDataPoint = {
  day: number;
  weight: number | null;
};
export type PostOpDetail = {
  surgeryDate: string;
  postAwlWeight: number | null;
  visitDate: string;
  demoWeight?: number;
  operationType: string;
};

export const PatientDashboard = ({
  patientUser,
  patientUserProfile,
  isHistory,
  isProfile,
  selectedHistoryDate,
  handleHistoryClick,
  handelViewProfile,
  handleGobackClick,
}: Props) => {
  const [isPostOps, setIsPostOps] = useState(false);
  const [postOpDetail, setPostOpDetail] = useState<PostOpDetail[]>([]);
  const [selectedDisplayChart, setSelectedDisplayChart] = useState("Weekly");
  const [allCollectionData, setAllCollectData] = useState<AllCollectData[]>([]);
  const [allWeightFromVisit, setAllWeightFromVisit] = useState<
    WeightWithDate[]
  >([]);
  const [allWeightFromPatient, setAllWeightFromPatient] = useState<
    WeightWithDate[]
  >([]);
  const [visitDataList, setVisitDataList] = useState<VisitData[]>([]);

  const {
    mutateAsync: getAllCollectionAsync,
    isPending: isPendingGetAllCollection,
  } = useMutation({
    mutationFn: async () => {
      const response = await dataCollectionService.getAllCollection(
        patientUser.id
      );
      return response as AllCollectData[];
    },
  });
  const { mutateAsync: getAllVisitAsync, isPending: isPendingGetAllVisit } =
    useMutation({
      mutationFn: async () => {
        const response = await visitDataService.getAllVisit(patientUser.id);
        return response as VisitData[];
      },
    });
  const {
    mutateAsync: getBariatricSurgeryHistoryAsync,
    isPending: isPendingGetBariatricSurgeryHistory,
  } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.getBariatricSurgeryHistory(
        patientUser.id
      );
      return response;
    },
  });
  const {
    mutateAsync: getDemoGraphicFromEntryIdAsync,
    isPending: isPendingGetDemoGraphicFromEntryId,
  } = useMutation({
    mutationFn: async (entryId: number) => {
      const response = await visitDataService.getDemoGraphicFromEntryId(
        entryId
      );
      return response;
    },
  });
  useEffect(() => {
    const fetchAllCollection = async () => {
      try {
        const allListData = await getAllCollectionAsync();
        if (allListData) {
          setAllCollectData(allListData);
        }
      } catch (error) {
        setAllCollectData([]);
        console.error(error);
      }
    };
    fetchAllCollection();
    const fetchVisits = async () => {
      try {
        const response = await getAllVisitAsync();
        if (response.length === 0) {
          setVisitDataList([]);
        }
        const filteredVisits = response.filter(
          (item: VisitData) => item.demographicDataStatus !== null
        );
        const sortedVisits = filteredVisits.sort(
          (a: VisitData, b: VisitData) => {
            const dateA = dayjs(a.visitDate);
            const dateB = dayjs(b.visitDate);
            return dateA.diff(dateB);
          }
        );
        setVisitDataList(sortedVisits);
      } catch (error) {
        setVisitDataList([]);
        console.error(error);
      }
    };
    fetchVisits();
    const fetchBariaticHistoryList = async () => {
      try {
        const responseBariaticHistoryList =
          await getBariatricSurgeryHistoryAsync();
        const sortedBariatricHistory = responseBariaticHistoryList.sort(
          (a: BariaticSurgeryData, b: BariaticSurgeryData) => {
            const dateA = dayjs(a.surgeryDate);
            const dateB = dayjs(b.surgeryDate);
            return dateA.diff(dateB);
          }
        );
        const surgeryDates = sortedBariatricHistory.map(
          (item: BariaticSurgeryData) => ({
            surgeryDate: item.surgeryDate,
            postAwlWeight: item.acuteWeightLoss ? item.postAwlWeight : null,
            visitDate: item.visitDate,
            operationType: item.operationType,
          })
        );
        setPostOpDetail(surgeryDates);
        // setPostOpDetail([
        //   {
        //     surgeryDate: "2024-06-06",
        //     postAwlWeight: 80,
        //     visitDate: "2024-06-12",
        //     operationType: "Roux-en-Y gastric bypass (RYGB)",
        //   },
        //   {
        //     surgeryDate: "2024-06-15",
        //     postAwlWeight: 90,
        //     visitDate: "2024-06-06",
        //     operationType: "Roux-en-Y gastric bypass (RYGB)",
        //   },
        // ]);
      } catch (error) {
        setPostOpDetail([]);
        console.error(error);
      }
    };
    fetchBariaticHistoryList();
  }, [patientUser.id]);
  useEffect(() => {
    if (postOpDetail.length > 0) {
      setIsPostOps(true);
    } else {
      setIsPostOps(false);
    }
  }, [postOpDetail]);
  useEffect(() => {
    if (allCollectionData.length > 0) {
      const allWeight = allCollectionData.map(({ date, weight }) => ({
        date,
        weight: weight,
      }));
      setAllWeightFromPatient(allWeight);
    } else {
      setAllWeightFromPatient([]);
    }
  }, [allCollectionData]);
  useEffect(() => {
    const fetchDemographicData = async () => {
      const fetchedData: WeightWithDate[] = [];
      for (const item of visitDataList) {
        if (item.demographicDataStatus !== null) {
          if (item.demographicDataStatus.lasEntryId) {
            try {
              const demographicData = await getDemoGraphicFromEntryIdAsync(
                item.demographicDataStatus.lasEntryId
              );
              if (demographicData) {
                fetchedData.push({
                  weight: demographicData.weightPreOperation,
                  date: demographicData.visitDate,
                });
              }
            } catch (error) {
              console.error(
                `Failed to fetch data for entry ID ${item.demographicDataStatus.lasEntryId}:`,
                error
              );
            }
          }
        }
      }
      postOpDetail.map((op) => {
        if (op.postAwlWeight === null) {
          const demoWeight = fetchedData.find(
            (item) => item.date === op.visitDate
          )?.weight;
          setPostOpDetail((prev) =>
            prev.map((item) =>
              item.visitDate === op.visitDate
                ? { ...item, demoWeight: demoWeight }
                : item
            )
          );
        }
      });
      setAllWeightFromVisit(fetchedData);
    };
    fetchDemographicData();
  }, [visitDataList]);
  const sortedAllCollectData = useMemo(() => {
    const sortedData = allCollectionData?.sort((a, b) => {
      const dateA = dayjs(a.date);
      const dateB = dayjs(b.date);
      return dateB.diff(dateA);
    });
    return sortedData;
  }, [allCollectionData]);

  const earliestDayjsDate = dayjs(
    getEarliestDate(allWeightFromPatient, allWeightFromVisit)
  );
  const weightEarliestDate = getWeightEarliestDate(
    allWeightFromPatient,
    allWeightFromVisit
  );
  const latestDayjsDate = dayjs(
    getLatestDate(allWeightFromPatient, allWeightFromVisit)
  );
  const initialStartDate =
    selectedDisplayChart === "Weekly"
      ? latestDayjsDate.subtract(4, "day")
      : selectedDisplayChart === "Monthly"
      ? latestDayjsDate.subtract(20, "day")
      : latestDayjsDate.subtract(355, "day");
  const startDate = initialStartDate.isBefore(earliestDayjsDate)
    ? earliestDayjsDate
    : initialStartDate;
  const xLabels = generateXLabels(selectedDisplayChart, startDate);
  const wData = generateDataSeries(
    selectedDisplayChart,
    startDate,
    allWeightFromPatient
  );

  const wData2 = generateDataSeries(
    selectedDisplayChart,
    startDate,
    allWeightFromVisit,
    postOpDetail
  );

  const generateValues = (
    surgeryWeight: number,
    surgeryDateIndex: number,
    monthlyPercentage: number[]
  ): WeightDataPoint[] => {
    return Array.from({ length: 13 }, (_, i) => {
      const day = surgeryDateIndex + i * 30;
      if (i === 0) {
        return { day, weight: surgeryWeight };
      } else if (i < monthlyPercentage.length) {
        const previousWeight = surgeryWeight * (1 - monthlyPercentage[i] / 100);
        return { day, weight: previousWeight };
      } else {
        return { day, weight: null };
      }
    });
  };

  const generateExponentialValues = (
    surgeryWeight: number,
    surgeryDateIndex: number,
    length: number,
    monthlyPercentage: number[]
  ) => {
    return Array.from({ length: length }, (_, i) => {
      const values = generateValues(
        surgeryWeight,
        surgeryDateIndex,
        monthlyPercentage
      );
      if (i < surgeryDateIndex) {
        return null;
      } else if (i === surgeryDateIndex) {
        return surgeryWeight;
      } else {
        return values.find((item) => item.day === i)?.weight ?? null;
      }
    });
  };
  const monthlyPercentageSG = [
    0, 5.7, 9.6, 13, 16, 18.5, 21, 22.7, 24.1, 25.1, 25.7, 26, 26.2,
  ];
  const RYGBList = [
    "Roux-en-Y gastric bypass (RYGB)",
    "Sleeve-DJB (Roux-en-Y)",
    "Sleeve-PJB",
    "Sleeve-DJB (Loop)",
    "SADI-S",
  ];
  const monthlyPercentageRYGB = [
    6, 10, 14, 18, 21, 23.7, 26, 27.8, 29.2, 30.2, 30.8, 31,
  ];
  const expoSeries = postOpDetail
    .map((op) => {
      const surgeryWeight =
        op.postAwlWeight !== null ? op.postAwlWeight : op.demoWeight;
      const surgeryDateIndex = xLabels.findIndex((date) =>
        dayjs(date).isSame(dayjs(op.surgeryDate), "day")
      );
      if (surgeryWeight && surgeryWeight !== 0 && surgeryDateIndex !== -1) {
        const exponentialValues =
          op.operationType === "Sleeve Gastrectomy (SG)" ||
          RYGBList.includes(op.operationType)
            ? generateExponentialValues(
                surgeryWeight,
                surgeryDateIndex,
                xLabels.length,
                op.operationType === "Sleeve Gastrectomy (SG)"
                  ? monthlyPercentageSG
                  : monthlyPercentageRYGB
              )
            : [];
        return {
          type: "line",
          name: op.operationType,
          dashStyle: "shortdash",
          data: isPostOps ? exponentialValues : [],
          marker: {
            enabled: true,
          },
          color: "#7A8688",
          showInLegend: true,
          connectNulls: true,
        };
      }
      return null;
    })
    .filter((series) => series !== null);
  const options = {
    chart: {
      style: {
        fontFamily: "Sarabun",
      },
      marginTop: 50,
      scrollablePlotArea: {
        minWidth:
          selectedDisplayChart === "Weekly"
            ? 300
            : selectedDisplayChart === "Monthly"
            ? 600
            : 8000,
        scrollPositionX: 0,
      },
      accessibility: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      valueSuffix: " Kg.",
      split: true,
      distance: 30,
      padding: 5,
      shadow: false,
      borderWidth: 1,
      backgroundColor: "rgba(255,255,255,0.8)",
      // shared: true,
      // useHTML: true,
      // headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
      // pointFormat:
      //   '<tr><td style="color: {series.color}">{series.name} ' +
      //   "</td>" +
      //   '<td style="text-align: right"><b>{point.y} Kg.</b></td></tr>',
      // footerFormat: "</table>",
      valueDecimals: 2,
    },
    xAxis: {
      categories: xLabels.map((date) => dayjs(date).format("DD/MM/BB")),
      labels: {
        rotation: -45,
      },
      plotLines: isPostOps
        ? postOpDetail.map((item) => ({
            color: "black",
            dashStyle: "dash",
            width: 1,
            value: xLabels.findIndex((date) =>
              dayjs(date).isSame(dayjs(item?.surgeryDate), "day")
            ),
            label: {
              text: `วันผ่าตัด`,
              align: "left",
              rotation: 0,
              x: -20,
              y: -10,
              style: {
                color: "black",
                zIndex: 1,
              },
            },
          }))
        : [],
    },
    yAxis: {
      title: {
        text: "",
      },
      max: getMaxWeight(allWeightFromPatient, allWeightFromVisit) + 20,
      min: getMinWeight(allWeightFromPatient, allWeightFromVisit) - 20,
      plotLines: !isPostOps
        ? [
            {
              color: "#D80027",
              dashStyle: "shortdash",
              value: weightEarliestDate * 0.95,
              width: 1,
              zIndex: 1,
              label: {
                text: "-5%",
                x: -15,
                y: -5,
                align: "right",
                style: {
                  color: "#D80027",
                },
              },
            },
            {
              color: "#FBC235",
              dashStyle: "shortdash",
              value: weightEarliestDate * 0.9,
              width: 1,
              zIndex: 1,
              label: {
                text: "-10%",
                x: -10,
                y: -5,
                align: "right",
                style: {
                  color: "#FBC235",
                },
              },
            },
            {
              color: "#098484",
              dashStyle: "shortdash",
              value: weightEarliestDate * 0.85,
              width: 1,
              zIndex: 1,
              label: {
                text: "-15%",
                x: -10,
                y: -5,
                align: "right",
                style: {
                  color: "#098484",
                },
              },
              labels: {
                clip: true,
              },
            },
          ]
        : [],
    },
    series: [
      {
        data: wData,
        name: "น้ำหนักคนไข้ (Line)",
        connectNulls: true,
        zoneAxis: "x",
        // zones: [
        //   {
        //     value: xLabels.findIndex((date) =>
        //       // date.date() === dayjs().add(-1, "day").date() &&
        //       // date.month() === dayjs().month()
        //       dayjs(date).isSame(dayjs(opDates[0]?.surgeryDate), "day")
        //     ),
        //     // color: "blue",
        //   },
        //   isPostOps && {
        //     color: "black",
        //   },
        // ],
      },
      {
        data: wData2,
        name: "น้ำหนักคนไข้ (Visit)",
        connectNulls: true,
        zoneAxis: "x",
        // zones: [
        //   {
        //     value: xLabels.findIndex((date) =>
        //       dayjs(date).isSame(dayjs(opDates[0]?.surgeryDate), "day")
        //     ),
        //     // color: "blue",
        //   },
        //   isPostOps && {
        //     color: "black",
        //   },
        // ],
      },
      // {
      //   name: " test",
      //   type: "line",
      //   dashStyle: "shortdash",
      //   data: isPostOps ? exponentialValues : [],
      //   marker: {
      //     enabled: false,
      //   },
      //   color: "#7A8688",
      //   showInLegend: false,
      //   enableMouseTracking: false,
      ...expoSeries,
    ],
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadCSV"],
        },
      },
      filename: "NutritionDataExport",
    },
  };
  return (
    <Box
      sx={{
        p: 3,
        height: "calc(100vh - 40px)",
        overflow: "auto",
        scrollbarWidth: "thin",
      }}
    >
      {!isProfile &&
        !isHistory &&
        (!isPendingGetAllCollection &&
        !isPendingGetAllVisit &&
        !isPendingGetBariatricSurgeryHistory &&
        !isPendingGetDemoGraphicFromEntryId ? (
          <div>
            <Typography style={title18700} sx={{ mb: 2 }}>
              Daily Form
            </Typography>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
            >
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Paper elevation={4} sx={{ borderRadius: 2, height: "100%" }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    spacing={1}
                    padding={2}
                  >
                    <Typography style={title18700}>
                      กราฟเปรียบเทียบน้ำหนักของคนไข้กับค่าเฉลี่ย
                    </Typography>
                    <Select
                      id="graphType"
                      value={selectedDisplayChart}
                      size="small"
                      onChange={(e) => setSelectedDisplayChart(e.target.value)}
                      sx={{
                        fontSize: 12,
                        width: "100px",
                        borderRadius: 5,
                        textAlign: "center",
                      }}
                    >
                      <MenuItem value={"Weekly"} sx={{ fontSize: 12 }}>
                        Weekly
                      </MenuItem>
                      <MenuItem value={"Monthly"} sx={{ fontSize: 12 }}>
                        Monthly
                      </MenuItem>
                      <MenuItem value={"Yearly"} sx={{ fontSize: 12 }}>
                        Yearly
                      </MenuItem>
                    </Select>
                  </Stack>
                  {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
                  {!wData.every((item) => item === null) ||
                  !wData2.every((item) => item === null) ? (
                    <div style={{ zoom: 100 * (100 / 85) + "%" }}>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                      />
                    </div>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: 12,
                        color: "#9AA1AE",
                        py: 2,
                      }}
                    >
                      ไม่พบข้อมูลน้ำหนัก กรุณาเลือกช่วงเวลาอื่น
                    </Box>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <BmiCard
                      patient={patientUser}
                      weight={
                        sortedAllCollectData.length > 0
                          ? sortedAllCollectData[0]?.weight
                          : patientUser.weight
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <ProfileCard
                      patientUser={patientUser}
                      patientUserProfile={patientUserProfile}
                      handelViewProfile={handelViewProfile}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <MealCard patientUser={patientUser} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <ExerciseCard
                      patientUser={patientUser}
                      weight={
                        sortedAllCollectData.length > 0
                          ? sortedAllCollectData[0]?.weight
                          : patientUser.weight
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2, maxWidth: "calc(100% - 30px)" }}>
              <HistoryCard
                patientUser={patientUser}
                handleHistoryClick={handleHistoryClick}
              />
            </Box>
          </div>
        ) : (
          <LoadingComponent />
        ))}

      {isHistory && (
        <PatientHistoryDetail
          selectedDate={selectedHistoryDate}
          patientUser={patientUser}
          patientUserProfile={patientUserProfile}
          handelViewProfile={handelViewProfile}
          handleGobackClick={handleGobackClick}
        />
      )}

      {isProfile && (
        <PatientProfileDetail handleGobackClick={handleGobackClick} />
      )}
    </Box>
  );
};
