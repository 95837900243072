import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Pagination,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormContainer,
  TextFieldElement,
} from "react-hook-form-mui";
import "react-quill/dist/quill.snow.css";
import { ulid } from "ulid";
import { Rearrange } from "../../asset/iconSvg";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";
import {
  AddIcon,
  BookmarkBorderIcon,
  DeleteIcon,
  EditIcon,
  ReorderIcon,
} from "../../icon";
import { Layout } from "../../Layout";
import { MasterDataFiltering } from "../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../style";
import { theme2 } from "../../theme";
import Quill from "quill";
import ReactQuill, { Quill as Quill2 } from "react-quill";
import QuillResizeImage from "quill-resize-image";
import { useNavigate } from "react-router";
Quill2.register("modules/resize", QuillResizeImage);

type FaqItem = {
  id: string;
  topic: string;
  question: string;
  answer: string;
  status: string;
  displayOrder?: number;
};

const tableCellValues = [
  { name: "ลำดับ", width: "5%" },
  { name: "หัวข้อ", width: "10%" },
  { name: "คำถาม", width: "30%" },
  { name: "คำตอบ", width: "35%" },
  { name: "สถานะ", width: "10%" },
  { name: "เครื่องมือ", width: "10%" },
];

const initialList: FaqItem[] = [
  {
    id: ulid(),
    topic: "การติดต่อ",
    question: "ติดต่อคลินิกรักษ์พุง",
    answer: "นัดหมายเพื่อเข้ามารับการตรวจที่หมายเลข 02 256 4000 ต่อ 71205",
    status: "ใช้งานได้",
    displayOrder: 1,
  },
  {
    id: ulid(),
    topic: "ทดสอบ",
    question: "ทดสอบ",
    answer: "ทดสอบ",
    status: "ใช้งานได้",
    displayOrder: 2,
  },
];

const findMaxDisplayOrder = (list: FaqItem[]): number => {
  return Math.max(...list.map((item) => item.displayOrder || 0), 0);
};

const icons: any = ReactQuill.Quill.import("ui/icons");
icons["undo"] = `<svg viewbox="0 0 18 18">
      <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
      <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
    </svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

function undoChange(this: { quill: Quill }) {
  this.quill.history.undo();
}

function redoChange(this: { quill: Quill }) {
  this.quill.history.redo();
}

const modules = {
  toolbar: {
    container: [
      ["undo", "redo"],
      [{ size: [] }],
      [{ align: [] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  resize: {
    locale: {},
  },
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true,
  },
};

export const FaqMaster = () => {
  const navigate = useNavigate();
  const quillRef = useRef<ReactQuill | null>(null);
  const filterRef = useRef<HTMLDivElement>(null);
  const [faqList, setFaqList] = useState<FaqItem[]>(initialList);
  const [filteredList, setFilteredList] = useState<FaqItem[]>(initialList);
  const [reorderableFaqs, setReorderableFaqs] = useState<FaqItem[]>([]);
  const [currentFaqItem, setCurrentFaqItem] = useState<FaqItem>();
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRearrangeDialog, setOpenRearrangeDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemTemp, setDeleteItemTemp] = useState(0);
  const [activeStatus, setActiveStatus] = useState(true);
  const [boxHeight, setBoxHeight] = useState("calc(100vh - 162px - 56px)");

  const handleDragAndDrop = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const reorderedItems = Array.from(reorderableFaqs);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    setReorderableFaqs(reorderedItems);
  };
  const handleOpenRearrangeDialog = () => {
    setReorderableFaqs(faqList.filter((item) => item.status === "ใช้งานได้"));
    setOpenRearrangeDialog(true);
  };
  const handleSubmitReorder = () => {
    const reorderedList = reorderableFaqs.map((item, index) => ({
      ...item,
      displayOrder: index + 1,
    }));

    const finalList = [
      ...reorderedList,
      ...faqList.filter((item) => item.status !== "ใช้งานได้"),
    ];

    setFaqList(finalList);
    setFilteredList(finalList);
    handleCloseDialog();
  };
  useEffect(() => {
    const handleResize = () => {
      if (filterRef.current) {
        const stackHeight = filterRef.current.clientHeight;
        const calculatedHeight =
          stackHeight > 56
            ? `calc(100vh - ${stackHeight}px - 56px)`
            : "calc(100vh - 162px - 56px)";
        setBoxHeight(calculatedHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePageChange = (event: unknown, value: number) => {
    setPage(value);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchStatusChange = (newValue: string) => {
    setSearchStatus(newValue);
  };
  const handleSearchClick = () => {
    const result = faqList.filter((item) => {
      const matchesText = item.question
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const matchesStatus = searchStatus ? item.status === searchStatus : true;
      return matchesText && matchesStatus;
    });
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setSearchStatus("");
    setFilteredList(faqList);
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
    setOpenRearrangeDialog(false);
  };
  const handleAddNewItem = (data: FieldValues) => {
    if (value.trim() === "<p><br></p>") {
      setError("This field is required");
      return;
    }
    setError(null);
    const maxDisplayOrder = findMaxDisplayOrder(faqList);
    const newItem = {
      id: ulid(),
      topic: data.topic,
      question: data.question,
      answer: value,
      status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
      displayOrder: activeStatus ? maxDisplayOrder + 1 : undefined,
    };
    setFilteredList([...faqList, newItem]);
    faqList.push(newItem);
    handleCloseDialog();
  };
  const handleDeleteClick = (index: number) => {
    setDeleteItemTemp(index);
    setOpenDeleteDialog(true);
  };
  const handleEditClick = (data: FaqItem) => {
    setCurrentFaqItem(data);
    setError(null);
    setValue(data.answer);
    setOpenEditDialog(true);
    setActiveStatus(data.status === "ใช้งานได้" ? true : false);
  };
  const handleEditItem = (data: FaqItem) => {
    const maxDisplayOrder = findMaxDisplayOrder(faqList);
    if (value.trim() === "<p><br></p>") {
      setError("This field is required");
      return;
    }
    setError(null);
    setFaqList((prevList) =>
      prevList.map((item) =>
        item.topic === currentFaqItem?.topic
          ? {
              ...data,
              answer: value,
              status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
              displayOrder: item.displayOrder
                ? item.displayOrder
                : activeStatus
                ? maxDisplayOrder + 1
                : undefined,
            }
          : item
      )
    );
    setFilteredList((prevList) =>
      prevList.map((item) =>
        item.topic === currentFaqItem?.topic
          ? {
              ...data,
              answer: value,
              status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
              displayOrder: item.displayOrder
                ? item.displayOrder
                : activeStatus
                ? maxDisplayOrder + 1
                : undefined,
            }
          : item
      )
    );
    handleCloseDialog();
  };
  const handleConfirmDeleteDialog = () => {
    setFaqList((prevList) => prevList.filter((_, i) => i !== deleteItemTemp));
    setFilteredList((prevList) =>
      prevList.filter((_, i) => i !== deleteItemTemp)
    );
    setOpenDeleteDialog(false);
  };
  const handleAddClick = () => {
    setError(null);
    setValue("");
    setActiveStatus(true);
    setOpenAddDialog(true);
  };
  useEffect(() => {
    setTimeout(() => {
      if (currentFaqItem && quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.clipboard.dangerouslyPasteHTML(currentFaqItem.answer);
        setValue(currentFaqItem.answer);
      }
    }, 100);
  }, [currentFaqItem, quillRef]);
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1 }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`คำถามที่พบบ่อย`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              title="คำถามที่พบบ่อย"
              searchText={searchText}
              searchStatus={searchStatus}
              searchTextLabel="ค้นหาคำถาม"
              handleSearchTextChange={handleTextInputChange}
              handleSearchStatusChange={handleSearchStatusChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>
          <Box
            sx={{
              bgcolor: "white",
              height: boxHeight,
              mt: 2,
              overflowY: "auto",
              scrollbarWidth: "thin",
              p: "20px",
            }}
          >
            <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FBC235",
                  height: "40px",
                  fontSize: "14px",
                  marginBottom: "20px",
                }}
                startIcon={<Rearrange />}
                onClick={handleOpenRearrangeDialog}
              >
                ลำดับการแสดงผล
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#FF366B",
                  height: "40px",
                  fontSize: "14px",
                  marginBottom: "20px",
                  minWidth: "90px",
                }}
                startIcon={<AddIcon />}
                onClick={handleAddClick}
              >
                เพิ่ม
              </Button>
            </Stack>

            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.topic}</TableCell>
                            <TableCell>{item.question}</TableCell>
                            <TableCell>
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: item.answer as string,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                      item.status === "ใช้งานได้"
                                        ? "green"
                                        : "red",
                                  }}
                                />
                                {item.status}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Stack spacing={1} direction={"row"}>
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                  onClick={() => handleEditClick(item)}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    border: "1px solid #7A8688",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    color: "#7A8688",
                                    fontSize: 14,
                                  }}
                                  onClick={() => handleDeleteClick(index)}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  --ไม่พบข้อมูล--
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หัวข้อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="topic"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    คำถาม <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="question"
                    required
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    คำตอบ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    modules={modules}
                    value={value}
                    onChange={setValue}
                  />
                  {error && (
                    <FormHelperText
                      sx={{
                        color: "#d32f2f",
                        mt: "4px !important",
                        ml: "14px !important",
                      }}
                    >
                      {error}
                    </FormHelperText>
                  )}
                  {/* <TextFieldElement
                    name="answer"
                    required
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  /> */}
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={activeStatus}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              onSuccess={handleEditItem}
              defaultValues={{
                topic: currentFaqItem?.topic,
                question: currentFaqItem?.question,
                answer: currentFaqItem?.answer,
                displayOrder: currentFaqItem?.displayOrder,
              }}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    หัวข้อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="topic"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    คำถาม <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="question"
                    required
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    คำตอบ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <ReactQuill
                    key={currentFaqItem?.answer}
                    ref={quillRef}
                    theme="snow"
                    modules={modules}
                    value={value}
                    onChange={setValue}
                  />
                  {error && (
                    <FormHelperText
                      sx={{
                        color: "#d32f2f",
                        mt: "4px !important",
                        ml: "14px !important",
                      }}
                    >
                      {error}
                    </FormHelperText>
                  )}
                  {/* <TextFieldElement
                    name="answer"
                    required
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  /> */}
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>ลำดับการแสดงผล</Typography>
                  <TextFieldElement
                    name="displayOrder"
                    variant="outlined"
                    size="small"
                    disabled
                    sx={{ bgcolor: "#E3E7F0" }}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentFaqItem?.status === "ใช้งานได้" ? true : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
        {/* Rearrange  Dialog */}
        <Dialog
          open={openRearrangeDialog}
          onClose={handleCloseDialog}
          fullWidth
        >
          <DialogTitle>เรียงลำดับ</DialogTitle>
          <DialogContent dividers>
            <DragDropContext onDragEnd={handleDragAndDrop}>
              <Droppable droppableId="faqs">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {reorderableFaqs.map((faq, index) => (
                      <Draggable
                        key={faq.id}
                        draggableId={faq.id}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            p={2}
                            mb={2}
                            bgcolor="#f7f7f7"
                            borderRadius={2}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <BookmarkBorderIcon />
                              {faq.question}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {`ลำดับที่ ${index + 1}`}
                              <ReorderIcon />
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Box>
              <Divider sx={{ my: 2 }} />
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                <Button
                  onClick={handleCloseDialog}
                  style={{ backgroundColor: "#7A8688", color: "white" }}
                >
                  ยกเลิก
                </Button>
                <Button
                  style={{ backgroundColor: "#098484", color: "white" }}
                  onClick={handleSubmitReorder}
                >
                  บันทึก
                </Button>
              </Stack>
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </Layout>
  );
};
