import axios from "axios";

const BASE_URL = "https://cub-bff-lf3ljqzysq-as.a.run.app";

export const getPatientList = async () => {
  try {
    const config = {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    };
    const response = await axios.get(
      `${BASE_URL}/v1/users/list/mobile`,
      config
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
