import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  RadioGreyBox,
  TextGreyBox,
  TextWhiteBox,
  YesNoBox,
} from "../../../components/TextBox";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { title10Tab } from "../../../style";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import { LayoutTab } from "../LayoutTab";
import { StatusBottomBar } from "../StatusBottomBar";
import { OtherAdd, OtherArrow, OtherRemove } from "../../../asset/iconSvg";
import { ComorbiditiesAndComplicationsHistoryDialog } from "./ComorbiditiesAndComplicationsHistoryDialog";
import { WarningDialog } from "../../../components/dialog/WarningDialog";

interface RadioGroupsState {
  [key: string]: string;
}

export type ComorbiditiesResponse = {
  entryId: number;
  editorId: string;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  ht: boolean;
  dm: boolean;
  dlp: boolean;
  nafld: boolean;
  cva: boolean;
  cvd: boolean;
  gallstones: boolean;
  pcos: boolean;
  kneeOsteoarthritis: boolean;
  spondylosis: boolean;
  gerd: boolean;
  psych: boolean;
  caco: boolean;
  osa: boolean;
  other: boolean;
  otherText: string[];
  cpap: boolean;
};
type Props = {
  handleCancelClick: () => void;
};
export const ComorbiditiesAndComplications = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;

  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);
  const theme = useTheme();
  const [status, setStatus] = useState(2);
  const [otherDetails, setOtherDetails] = useState([""]);
  const [validationError, setValidationError] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const latestEntryId = useAppSelector(
    (state) =>
      state.patientUser.latestEntryIds?.comorbiditiesAndConditionsStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.comorbiditiesAndConditionsStatus
  );
  const [radioGroups, setRadioGroups] = useState<RadioGroupsState>({
    ht: "no",
    dm: "no",
    dlp: "no",
    nafld: "no",
    cvd: "no",
    cva: "no",
    gallstones: "no",
    pcos: "no",
    kneeOsteoarthritis: "no",
    spondylosis: "no",
    gerd: "no",
    psych: "no",
    caco: "no",
    osa: "no",
    cpap: "no",
    other: "no",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const comorbiditiesData: ComorbiditiesResponse =
          await visitDataService.getComorbiditiesFromEntryId(currentEntryId);
        setRadioGroups({
          ht: comorbiditiesData.ht ? "yes" : "no",
          dm: comorbiditiesData.dm ? "yes" : "no",
          dlp: comorbiditiesData.dlp ? "yes" : "no",
          nafld: comorbiditiesData.nafld ? "yes" : "no",
          cvd: comorbiditiesData.cvd ? "yes" : "no",
          cva: comorbiditiesData.cva ? "yes" : "no",
          gallstones: comorbiditiesData.gallstones ? "yes" : "no",
          pcos: comorbiditiesData.pcos ? "yes" : "no",
          kneeOsteoarthritis: comorbiditiesData.kneeOsteoarthritis
            ? "yes"
            : "no",
          spondylosis: comorbiditiesData.spondylosis ? "yes" : "no",
          gerd: comorbiditiesData.gerd ? "yes" : "no",
          psych: comorbiditiesData.psych ? "yes" : "no",
          caco: comorbiditiesData.caco ? "yes" : "no",
          osa: comorbiditiesData.osa ? "yes" : "no",
          cpap: comorbiditiesData.cpap ? "yes" : "no",
          other: comorbiditiesData.other ? "yes" : "no",
        });
        setStatus(comorbiditiesData.visitDataStatus);
        setOtherDetails(comorbiditiesData.otherText ?? [""]);
      } else if (latestEntryId) {
        const comorbiditiesData: ComorbiditiesResponse =
          await visitDataService.getComorbiditiesFromEntryId(latestEntryId);
        setRadioGroups({
          ht: comorbiditiesData.ht ? "yes" : "no",
          dm: comorbiditiesData.dm ? "yes" : "no",
          dlp: comorbiditiesData.dlp ? "yes" : "no",
          nafld: comorbiditiesData.nafld ? "yes" : "no",
          cvd: comorbiditiesData.cvd ? "yes" : "no",
          cva: comorbiditiesData.cva ? "yes" : "no",
          gallstones: comorbiditiesData.gallstones ? "yes" : "no",
          pcos: comorbiditiesData.pcos ? "yes" : "no",
          kneeOsteoarthritis: comorbiditiesData.kneeOsteoarthritis
            ? "yes"
            : "no",
          spondylosis: comorbiditiesData.spondylosis ? "yes" : "no",
          gerd: comorbiditiesData.gerd ? "yes" : "no",
          psych: comorbiditiesData.psych ? "yes" : "no",
          caco: comorbiditiesData.caco ? "yes" : "no",
          osa: comorbiditiesData.osa ? "yes" : "no",
          cpap: comorbiditiesData.cpap ? "yes" : "no",
          other: comorbiditiesData.other ? "yes" : "no",
        });
        setStatus(comorbiditiesData.visitDataStatus);
        setOtherDetails(comorbiditiesData.otherText ?? [""]);
      }
    };
    fetchData();
  }, [openNoPermissionDialog]);
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveComorbiditiesData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: status,
        ht: radioGroups.ht === "yes" ? true : false,
        dm: radioGroups.dm === "yes" ? true : false,
        dlp: radioGroups.dlp === "yes" ? true : false,
        nafld: radioGroups.nafld === "yes" ? true : false,
        cva: radioGroups.cva === "yes" ? true : false,
        cvd: radioGroups.cvd === "yes" ? true : false,
        gallstones: radioGroups.gallstones === "yes" ? true : false,
        pcos: radioGroups.pcos === "yes" ? true : false,
        spondylosis: radioGroups.spondylosis === "yes" ? true : false,
        kneeOsteoarthritis:
          radioGroups.kneeOsteoarthritis === "yes" ? true : false,
        gerd: radioGroups.gerd === "yes" ? true : false,
        psych: radioGroups.psych === "yes" ? true : false,
        caco: radioGroups.caco === "yes" ? true : false,
        osa: radioGroups.osa === "yes" ? true : false,
        other: radioGroups.other === "yes" ? true : false,
        otherText: otherDetails,
        cpap: radioGroups.cpap === "yes" ? true : false,
      });

      return response as ComorbiditiesResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          comorbiditiesAndConditionsStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRadioGroups((prev) => ({
      ...prev,
      [name]: value,
    }));
    dispatch(updateTabsIsDirty(true));
  };
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(Number(event.target.value));
    dispatch(updateTabsIsDirty(true));
  };

  const handleSubmitData = async () => {
    if (!patientDataPermissionEditAble) {
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (radioGroups.other !== "no") {
      if (validateOtherRequiredFields()) {
        setValidationError(false);
        await submitDataAsync();
        dispatch(updateTabsIsDirty(false));
      } else {
        setValidationError(true);
      }
    } else {
      await submitDataAsync();
      setValidationError(false);
      dispatch(updateTabsIsDirty(false));
    }
  };
  const handleCloseDialog = () => {
    setOpenSubmitDialog(false);
    setOpenHistoryDialog(false);
  };
  const handleOtherAdd = () => {
    setOtherDetails((prevDetails) => [...prevDetails, ""]);
    dispatch(updateTabsIsDirty(true));
  };
  const handleOtherRemove = (index: number) => {
    if (otherDetails.length !== 1) {
      const newDetails = otherDetails.filter((_, i) => i !== index);
      setOtherDetails(newDetails);
      dispatch(updateTabsIsDirty(true));
    }
  };
  const handleCloseErrorDialog = () => {
    setOpenNoPermissionDialog(false);
  };
  const handleOtherChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newDetails = [...otherDetails];
    newDetails[index] = event.target.value;
    setOtherDetails(newDetails);
    dispatch(updateTabsIsDirty(true));
  };
  const validateOtherRequiredFields = () => {
    for (let detail of otherDetails) {
      if (!detail.trim()) {
        return false;
      }
    }
    return true;
  };
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => console.log("history")}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Box
            width={1}
            my={2}
            alignItems="center"
            gap={4}
            sx={{ border: "2px solid #D8D8D8" }}
          >
            <Box
              sx={{
                px: 2,
                py: 1,
                borderBottom: "2px solid #D8D8D8",
              }}
            >
              <Typography style={title10Tab}>
                ประวัติโรคร่วมและโรคแทรกซ้อน
              </Typography>
            </Box>
            <div style={{ padding: "20px", width: "100%" }}>
              <Grid container direction="row" columnSpacing={4} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Stack spacing={2}>
                    <Stack spacing={1} direction={"row"}>
                      <TextWhiteBox>{``}</TextWhiteBox>
                      <YesNoBox />
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคความดันโลหิตสูง (HT)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="ht"
                          name="ht"
                          value={radioGroups.ht}
                          onChange={handleChange}
                          sx={{
                            width: 1,
                            display: "felx",
                            justifyContent: "center",
                          }}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคเบาหวาน (DM)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="dm"
                          name="dm"
                          value={radioGroups.dm}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคไขมันในเลือดสูง (DLP)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="dlp"
                          name="dlp"
                          value={radioGroups.dlp}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>ภาวะไขมันสะสมในตับ (NAFLD/NASH)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="nafld"
                          name="nafld"
                          value={radioGroups.nafld}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคหลอดเลือดหัวใจ (CAD)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="cvd"
                          name="cvd"
                          value={radioGroups.cvd}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคหลอดเลือดสมอง (CVA)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="cva"
                          name="cva"
                          value={radioGroups.cva}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคนิ่วในถุงน้ำดี (Gallstones)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="gallstones"
                          name="gallstones"
                          value={radioGroups.gallstones}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>
                        โรคถุงน้ำผิดปกติที่รังไข่ (PCOS)
                      </TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="pcos"
                          name="pcos"
                          value={radioGroups.pcos}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคข้อเข่าเสื่อม</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="kneeOsteoarthritis"
                          name="kneeOsteoarthritis"
                          value={radioGroups.kneeOsteoarthritis}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคกระดูกสันหลัง</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="spondylosis"
                          name="spondylosis"
                          value={radioGroups.spondylosis}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={5}>
                  <Stack spacing={2}>
                    {lgScreen && (
                      <Stack spacing={1} direction={"row"}>
                        <TextWhiteBox>{``}</TextWhiteBox>
                        <YesNoBox />
                      </Stack>
                    )}
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคกรดไหลย้อน (GERD)</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="gerd"
                          name="gerd"
                          value={radioGroups.gerd}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคทางจิตเวช</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="psych"
                          name="psych"
                          value={radioGroups.psych}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>ประวัติโรคมะเร็ง</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="caco"
                          name="caco"
                          value={radioGroups.caco}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>
                        ภาวะทางเดินหายใจอุดกั้นขณะนอนหลับ (OSA)
                      </TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="osa"
                          name="osa"
                          value={radioGroups.osa}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>CPAP use</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="cpap"
                          name="cpap"
                          value={radioGroups.cpap}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    <Stack spacing={1} direction={"row"}>
                      <TextGreyBox>โรคอื่นๆ</TextGreyBox>
                      <RadioGreyBox>
                        <RadioGroup
                          row
                          aria-label="other"
                          name="other"
                          value={radioGroups.other}
                          onChange={handleChange}
                        >
                          <Radio value="yes" style={{ color: "#098484" }} />
                          <Radio value="no" style={{ color: "#098484" }} />
                        </RadioGroup>
                      </RadioGreyBox>
                    </Stack>
                    {radioGroups.other === "yes" && (
                      <Stack spacing={1}>
                        {otherDetails.map((detail, index) => {
                          return (
                            <Stack
                              key={index}
                              spacing={1}
                              direction={"row"}
                              // alignItems={"center"}
                            >
                              <Box sx={{ pt: 1 }}>
                                <OtherArrow />
                              </Box>

                              <TextField
                                required
                                sx={{ width: "62%" }}
                                value={detail}
                                onChange={(e) => handleOtherChange(index, e)}
                                error={validationError && !detail.trim()}
                                helperText={
                                  validationError && !detail.trim()
                                    ? "This field is required"
                                    : ""
                                }
                              />
                              <Box
                                sx={{ pt: 0.5 }}
                                onClick={() => handleOtherRemove(index)}
                              >
                                <OtherRemove />
                              </Box>
                              <Box sx={{ pt: 0.5 }} onClick={handleOtherAdd}>
                                <OtherAdd />
                              </Box>
                            </Stack>
                          );
                        })}
                      </Stack>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </div>
          </Box>
          <StatusBottomBar
            value={status}
            handleStatusChange={handleStatusChange}
          />
        </Box>
      </Box>
      <ComorbiditiesAndComplicationsHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
    </LayoutTab>
  );
};
