import { Box, TextField, Button } from "@mui/material";
import { useState } from "react";

const LoginForm = ({ onSubmit }: { onSubmit: (data: { email: string, password: string }) => void }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    
    const handleSubmit = (event: any) => {
      event.preventDefault();
      onSubmit({ email, password });
    };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoFocus
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

    <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        name="password"
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
    />

      <Button
        fullWidth
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          backgroundColor: "#FF6699",
          color: "white",
          "&:hover": {
            backgroundColor: "#FF6699",
          },
        }}
        type="submit"
      >
        เข้าสู่ระบบ
      </Button>
    </Box>
  );
};

export default LoginForm;