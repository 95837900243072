import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  FormContainer,
  MultiSelectElement,
  SelectElement,
  TextFieldElement,
  useForm,
} from "react-hook-form-mui";
import { FilterItem } from "../Pages/Report/Report";
import { FilterIcon } from "../icon";
import { labelForm, spacingForm } from "../style";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

type Props = {
  filter?: FilterItem;
  reportType: string;
  isHideFilter: boolean;
  onApplyFilter: (newFilter: FilterItem) => void;
  setReportType: (reportType: string) => void;
  setFilter: (value: FilterItem | undefined) => void;
  setIsHideFilter: (value: boolean) => void;
};

type DisplayFilterProps = {
  label: string;
  value: any;
};

type DisplayRangeFilterProps = {
  label: string;
  minValue: any;
  maxValue: any;
};

const DisplayFilter = ({ label, value }: DisplayFilterProps) => {
  if (!shouldDisplayValue(value)) return null;

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={1}
      sx={{ fontSize: 14 }}
      display={"grid"}
      gridTemplateColumns={"1fr 1.5fr "}
    >
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: "#dbdbdb",
          px: 1,
          py: 0.5,
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        {label}
      </Box>
      <Box>{Array.isArray(value) ? value.join(", ") : value}</Box>
    </Stack>
  );
};

const DisplayRangeFilter = ({
  label,
  minValue,
  maxValue,
}: DisplayRangeFilterProps) => {
  if (!shouldDisplayValue(minValue) && !shouldDisplayValue(maxValue)) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={1}
      sx={{ fontSize: 14 }}
      display={"grid"}
      gridTemplateColumns={"1fr 1.5fr "}
    >
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: "#dbdbdb",
          px: 1,
          py: 0.5,
          textAlign: "center",
          boxSizing: "border-box",
        }}
      >
        {label}
      </Box>
      <Box>
        {shouldDisplayValue(minValue) ? (
          <>
            {maxValue ? "" : "มากกว่า "}
            {minValue ?? "-"}
            {shouldDisplayValue(maxValue) && " - "}
          </>
        ) : null}
        {shouldDisplayValue(maxValue) ? (
          <>
            {minValue ? "" : "น้อยกว่า "}
            {maxValue ?? "-"}
          </>
        ) : null}
      </Box>
    </Stack>
  );
};

const shouldDisplayValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return value !== undefined && value !== "";
};

// const getGridItemSize = (visibleCount: number) => {
//   const maxColumns = 3;
//   const columns = Math.min(visibleCount, maxColumns);
//   return 12 / columns;
// };

export const ReportFiltering = ({
  filter,
  reportType,
  isHideFilter,
  onApplyFilter,
  setReportType,
  setFilter,
  setIsHideFilter,
}: Props) => {
  const [reportTypeTemp, setReportTypeTemp] = useState("");
  const [minDate, setStartDate] = useState<Dayjs | null>(null);
  const [maxDate, setEndDate] = useState<Dayjs | null>(null);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const formMethods = useForm<FilterItem>({
    defaultValues: {
      sex: filter?.sex ?? "ทั้งหมด",
      minAge: filter?.minAge ?? undefined,
      maxAge: filter?.maxAge ?? undefined,
      minWeight: filter?.minWeight ?? undefined,
      maxWeight: filter?.maxWeight ?? undefined,
      minHeight: filter?.minHeight ?? undefined,
      maxHeight: filter?.maxHeight ?? undefined,
      minBmi: filter?.minBmi ?? undefined,
      maxBmi: filter?.maxBmi ?? undefined,
      comorbidities: filter?.comorbidities ?? [],
      surgeryType: filter?.surgeryType ?? "",
      surgeryNo: filter?.surgeryNo ?? undefined,
      surgeryCount: filter?.surgeryCount ?? "",
      minPwl: filter?.minPwl ?? undefined,
      maxPwl: filter?.maxPwl ?? undefined,
      ultrasound: filter?.ultrasound ?? "",
      endoscopy: filter?.endoscopy ?? "",
      fibroScan: filter?.fibroScan ?? "",
      unComorbidities: filter?.unComorbidities ?? [],
      complication: filter?.complication ?? [],
      followUp: filter?.followUp ?? "",
      pathology: filter?.pathology ?? "",
    },
  });
  const minAgeValue = formMethods.watch("minAge");
  const maxAgeValue = formMethods.watch("maxAge");
  const minHeightValue = formMethods.watch("minHeight");
  const maxHeightValue = formMethods.watch("maxHeight");
  const minWeightValue = formMethods.watch("minWeight");
  const maxWeightValue = formMethods.watch("maxWeight");
  const minBmiValue = formMethods.watch("minBmi");
  const maxBmiValue = formMethods.watch("maxBmi");
  const minPwlValue = formMethods.watch("minPwl");
  const maxPwlValue = formMethods.watch("maxPwl");

  const handleFilterClick = () => {
    if (filter) {
      setStartDate(filter.minDate ? dayjs(filter.minDate) : null);
      setEndDate(filter.maxDate ? dayjs(filter.maxDate) : null);
    }
    setOpenFilterDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenFilterDialog(false);
  };
  const handleSubmit = (data: FilterItem) => {
    const formattedMinDate = dayjs(minDate).format("YYYY-MM-DD");
    const formattedMaxDate = dayjs(maxDate).format("YYYY-MM-DD");

    formMethods.setValue("minDate", minDate ? formattedMinDate : "");
    formMethods.setValue("maxDate", maxDate ? formattedMaxDate : "");
    const formattedData = {
      ...data,
      minDate: minDate ? formattedMinDate : "",
      maxDate: maxDate ? formattedMaxDate : "",
    };
    onApplyFilter(formattedData);
    handleCloseDialog();
  };
  const validateMinValue = (value: number, maxValue: number) => {
    if (value > 0 && maxValue > 0) {
      if (value > maxValue) return "ค่าเริ่มต้น ต้องน้อยกว่า ค่าสิ้นสุด";
      return true;
    }
  };

  const validateMaxValue = (value: number, minValue: number) => {
    if (value > 0 && minValue > 0) {
      if (value < minValue) return "ค่าสิ้นสุด ต้องมากกว่า ค่าเริ่มต้น";
      return true;
    }
  };
  const clearForm = () => {
    formMethods.reset();
    setStartDate(null);
    setEndDate(null);
  };
  const visibleCount = fieldOrder.reduce((acc, key) => {
    if (filter && shouldDisplayValue(filter[key])) {
      return acc + 1;
    }
    return acc;
  }, 0);

  // const gridItemSize = getGridItemSize(visibleCount);

  return (
    <>
      <Box sx={{ bgcolor: "white" }}>
        <Box
          sx={{
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            component={"span"}
            sx={{
              display: "inline-block",
              minWidth: "185px",
              borderBottom: "4px solid #098484",
              p: 1.5,
              fontSize: 18,
              fontWeight: 600,
              ml: 1.5,
              boxSizing: "border-box",
            }}
          >
            กำหนดเงื่อนไขการออกรายงาน
          </Box>
          <Box id="filter-button">
            <Button
              variant="contained"
              sx={{ m: "8px" }}
              onClick={handleFilterClick}
            >
              <FilterIcon sx={{ color: "white" }} />
              Filter
            </Button>
            {filter && (
              <Button
                variant="contained"
                sx={{ m: "8px", ml: 0 }}
                style={{ backgroundColor: "#7A8688", color: "white" }}
                onClick={() => {
                  setReportType("");
                  setFilter(undefined);
                  clearForm();
                }}
              >
                ล้าง Filter
              </Button>
            )}
          </Box>
        </Box>
        <Box id="filter-display" sx={{ p: "20px" }}>
          {filter ? (
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography component={"span"} fontSize={16} fontWeight={500}>
                {`เงื่อนไข จำนวนที่เลือก (${visibleCount} รายการ)`}
              </Typography>
              <Button
                variant="text"
                onClick={() => setIsHideFilter(!isHideFilter)}
              >
                {isHideFilter ? "กดเพื่อแสดง" : "กดเพื่อซ่อน"}
              </Button>
            </Stack>
          ) : (
            <Typography>{`เลือก Filter ที่ต้องการค้นหา`}</Typography>
          )}
          {filter && (
            <Box mt={1} display={isHideFilter ? "none" : ""}>
              <Grid container spacing={2}>
                {fieldOrder.map((key) => {
                  const value = filter[key];

                  if (key.startsWith("min")) {
                    const minKey = key;
                    const maxKey = key.replace(
                      "min",
                      "max"
                    ) as keyof FilterItem;
                    const minValue = filter[key];
                    const maxValue = filter[maxKey];
                    if (
                      shouldDisplayValue(minValue) ||
                      shouldDisplayValue(maxValue)
                    ) {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          key={`${key}-${maxKey}`}
                        >
                          <DisplayRangeFilter
                            label={fieldLabels[key]}
                            minValue={minValue}
                            maxValue={maxValue}
                          />
                        </Grid>
                      );
                    }
                    // return (
                    //   <Grid
                    //     item
                    //     xs={12}
                    //     sm={6}
                    //     md={4}
                    //     key={`${minKey}-${maxKey}`}
                    //   >
                    //     <DisplayRangeFilter
                    //       label={fieldLabels[minKey]}
                    //       minValue={filter[minKey]}
                    //       maxValue={filter[maxKey]}
                    //     />
                    //   </Grid>
                    // );
                  }
                  if (!shouldDisplayValue(value)) return null;
                  return (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <DisplayFilter label={fieldLabels[key]} value={value} />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          )}
          {filter && (
            <Stack direction={"row"} gap={2} mt={2}>
              <Select
                value={reportTypeTemp}
                size="small"
                onChange={(e) => setReportTypeTemp(e.target.value)}
                sx={{ fontSize: "14px", width: 350 }}
                MenuProps={{
                  sx: {
                    "& .MuiMenuItem-root": {
                      fontSize: "14px",
                    },

                    left: 50,
                    top: 50,
                    minWidth: "0 !important",
                    width: "350px !important",
                  },
                }}
                displayEmpty
              >
                <MenuItem disabled value={""} sx={{ fontSize: 14 }}>
                  โปรดระบุ
                </MenuItem>
                {reportList.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === "RPT03" || item.id === "RPT05"}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#098484",
                  marginRight: "10px",
                  height: "40px",
                  width: "150px",
                }}
                onClick={() => setReportType(reportTypeTemp)}
              >
                ค้นหา
              </Button>
            </Stack>
          )}
        </Box>
      </Box>
      {/* Filter Dialog */}
      <Dialog open={openFilterDialog} onClose={handleCloseDialog} fullWidth>
        <DialogTitle>เลือก Filter ที่ต้องการค้นหา</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom style={{ color: "#646D78" }}>
            กรุณาระบุเงื่อนไขที่ต้องการค้นหา
          </Typography>
          <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
            <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ชื่อ-นามสกุล</Typography>
                <TextFieldElement
                  name="name"
                  variant="outlined"
                  size="small"
                  type="text"
                  label="-- ชื่อ-นามสกุล --"
                  sx={{ width: "inherit" }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>วันที่ visit</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <DatePicker
                    value={minDate}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                  -
                  <DatePicker
                    value={maxDate}
                    maxDate={dayjs()}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>เพศ</Typography>
                <SelectElement
                  name="sex"
                  size="small"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "ทั้งหมด",
                      label: "ทั้งหมด",
                    },
                    {
                      id: "ชาย (Male)",
                      label: "ชาย (Male)",
                    },
                    {
                      id: "หญิง (Female)",
                      label: "หญิง (Female)",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>อายุ</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minAge"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุอายุเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxAgeValue)}
                    rules={{
                      validate: (value) => validateMinValue(value, maxAgeValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                      if (parseInt(target.value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxAge"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุอายุสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minAgeValue)}
                    rules={{
                      validate: (value) => validateMaxValue(value, minAgeValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      target.value = target.value.replace(/[^0-9]/g, "");
                      if (parseInt(target.value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ส่วนสูง</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minHeight"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุส่วนสูงเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxHeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxHeightValue),
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxHeight"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุส่วนสูงสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minHeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minHeightValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>น้ำหนัก</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minWeight"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุน้ำหนักเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxWeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMinValue(value, maxWeightValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxWeight"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุน้ำหนักสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minWeightValue)}
                    rules={{
                      validate: (value) =>
                        validateMaxValue(value, minWeightValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>BMI</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minBmi"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุBMIเริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxBmiValue)}
                    rules={{
                      validate: (value) => validateMinValue(value, maxBmiValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxBmi"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุBMIสิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minBmiValue)}
                    rules={{
                      validate: (value) => validateMaxValue(value, minBmiValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>โรคร่วมและโรคแทรกซ้อน</Typography>
                <MultiSelectElement
                  name="comorbidities"
                  size="small"
                  label="-- เลือกโรคร่วมและโรคแทรกซ้อน --"
                  options={["โรคเบาหวาน", "โรคไขมันในเลือดสูง"]}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        zoom: `${100 * (100 / 85)}%`,
                        top: "-15px",
                        "& .MuiMenuItem-root": {
                          fontSize: "14px",
                        },
                        minWidth: "0 !important",
                        width: "450px !important",
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ชนิดการผ่าตัด</Typography>
                <SelectElement
                  name="surgeryType"
                  size="small"
                  label="-- เลือกชนิดการผ่าตัด --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Roux-en-Y gastric bypass (RYGB)",
                      label: "Roux-en-Y gastric bypass (RYGB)",
                    },
                    {
                      id: "Sleeve-DJB (Roux-en-Y)",
                      label: "Sleeve-DJB (Roux-en-Y)",
                    },
                    {
                      id: "Sleeve-PJB",
                      label: "Sleeve-PJB",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ครั้งที่ผ่าตัด</Typography>
                <SelectElement
                  name="surgeryNo"
                  size="small"
                  label="-- เลือกMedication Use --"
                  options={[
                    {
                      id: 0,
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((val) => ({
                      id: val.toString(),
                      label: `${val}`,
                    })),
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>จำนวนครั้งที่ผ่าตัด</Typography>
                <SelectElement
                  name="surgeryCount"
                  size="small"
                  label="-- เลือกจำนวนครั้งที่ผ่าตัด --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "1 ครั้ง",
                      label: "1 ครั้ง",
                    },
                    {
                      id: "2 ครั้ง",
                      label: "2 ครั้ง",
                    },
                    {
                      id: "มากกว่า 2 ครั้งขึ้นไป",
                      label: "มากกว่า 2 ครั้งขึ้นไป",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Preop Weight Loss (%)</Typography>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignItems={"center"}
                  width={1}
                >
                  <TextFieldElement
                    name="minPwl"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุ % เริ่มต้น --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(maxPwlValue)}
                    rules={{
                      validate: (value) => validateMinValue(value, maxPwlValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                  -
                  <TextFieldElement
                    name="maxPwl"
                    variant="outlined"
                    size="small"
                    type="number"
                    label="-- ระบุ % สิ้นสุด --"
                    sx={{ width: "inherit" }}
                    // required={Boolean(minPwlValue)}
                    rules={{
                      validate: (value) => validateMaxValue(value, minPwlValue),
                    }}
                    inputProps={{ min: 0 }}
                    onInput={(e) => {
                      const target = e.target as HTMLInputElement;
                      const value = target.value;
                      if (value.includes(".")) {
                        const [integerPart, decimalPart] = value.split(".");
                        if (decimalPart.length > 2) {
                          target.value = `${integerPart}.${decimalPart.slice(
                            0,
                            2
                          )}`;
                        }
                      }
                      if (parseFloat(value) < 0) {
                        target.value = "";
                      }
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Ultrasound</Typography>
                <SelectElement
                  name="ultrasound"
                  size="small"
                  label="-- เลือกUltrasound --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Fatty Liver",
                      label: "Fatty Liver",
                    },
                    {
                      id: "Gallstone",
                      label: "Gallstone",
                    },
                    {
                      id: "Cirrhosis",
                      label: "Cirrhosis",
                    },
                    {
                      id: "Ascites",
                      label: "Ascites",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Endoscopy</Typography>
                <SelectElement
                  name="endoscopy"
                  size="small"
                  label="-- เลือกEndoscopy --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Hiatal Hernia",
                      label: "Hiatal Hernia",
                    },
                    {
                      id: "Reflux Esophagitis",
                      label: "Reflux Esophagitis",
                    },
                    {
                      id: "Gastritis",
                      label: "Gastritis",
                    },
                    {
                      id: "Gastric/Duodenal Ulcer",
                      label: "Gastric/Duodenal Ulcer",
                    },
                    {
                      id: "H.Pyroli Infection",
                      label: "H.Pyroli Infection",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>FibroScan</Typography>
                <SelectElement
                  name="fibroScan"
                  size="small"
                  label="-- เลือกFibroScan --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Yes",
                      label: "Yes",
                    },
                    {
                      id: "No",
                      label: "No",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>
                  หายจากโรคร่วมและแทรกซ้อน
                </Typography>
                <MultiSelectElement
                  name="unComorbidities"
                  size="small"
                  label="-- เลือกหายจากโรคร่วมและแทรกซ้อน --"
                  options={["โรคเบาหวาน", "โรคไขมันในเลือดสูง"]}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        zoom: `${100 * (100 / 85)}%`,
                        top: "-15px",
                        "& .MuiMenuItem-root": {
                          fontSize: "14px",
                        },
                        minWidth: "0 !important",
                        width: "450px !important",
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Early Complication</Typography>
                <MultiSelectElement
                  name="complication"
                  size="small"
                  label="-- เลือกEarly Complication --"
                  options={[
                    "Leakage",
                    "Bleeding",
                    "Surgical Site Infection",
                    "Pulmonary Complication",
                    "Obstruction",
                    "VTE",
                    "Cardiovascular events (MI, Stroke)",
                  ]}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        zoom: `${100 * (100 / 85)}%`,
                        top: "-15px",
                        "& .MuiMenuItem-root": {
                          fontSize: "14px",
                        },
                        minWidth: "0 !important",
                        width: "450px !important",
                      },
                    },
                  }}
                />
                {/* <SelectElement
                  name="complication"
                  size="small"
                  label="-- เลือกEarly Complication --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Leakage",
                      label: "Leakage",
                    },
                    {
                      id: "Bleeding",
                      label: "Bleeding",
                    },
                    {
                      id: "Surgical Site Infection",
                      label: "Surgical Site Infection",
                    },
                    {
                      id: "Pulmonary Complication",
                      label: "Pulmonary Complication",
                    },
                    {
                      id: "Obstruction",
                      label: "Obstruction",
                    },
                    {
                      id: "VTE",
                      label: "VTE",
                    },
                    {
                      id: "Cardiovascular events (MI, Stroke)",
                      label: "Cardiovascular events (MI, Stroke)",
                    },
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                /> */}
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>ระยะเวลา Follow Up</Typography>
                <SelectElement
                  name="followUp"
                  size="small"
                  label="-- เลือกระยะเวลา Follow Up --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    ...[
                      "2WK",
                      "1M",
                      "3M",
                      "6M",
                      "9M",
                      "1Y",
                      "1.6Y",
                      "2Y",
                      "3Y",
                      "4Y",
                      "5Y",
                      "5Y+",
                    ].map((val) => ({
                      id: val,
                      label: `${val}`,
                    })),
                  ]}
                  sx={{
                    minWidth: "150px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                          maxHeight: "200px",
                        },
                      },
                    },
                  }}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography color={"#646D78"}>Pathology</Typography>
                <SelectElement
                  name="pathology"
                  size="small"
                  label="-- เลือกPathology --"
                  options={[
                    {
                      id: "",
                      label: "- โปรดระบุข้อมูล --",
                      disabled: true,
                    },
                    {
                      id: "Stomach",
                      label: "Stomach",
                    },
                    {
                      id: "Liver Preop",
                      label: "Liver Preop",
                    },
                    {
                      id: "Liver Postop",
                      label: "Liver Postop",
                    },
                    {
                      id: "Gastroscopy",
                      label: "Gastroscopy",
                    },
                  ]}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          zoom: `${100 * (100 / 85)}%`,
                          top: "-15px",
                          "& .MuiMenuItem-root": {
                            fontSize: "14px",
                          },
                          minWidth: "0 !important",
                          width: "450px !important",
                        },
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
            <Box>
              <Divider sx={{ my: 2 }} />
              <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
                <Button
                  onClick={handleCloseDialog}
                  style={{ backgroundColor: "#7A8688", color: "white" }}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  style={{ backgroundColor: "#098484", color: "white" }}
                >
                  นำไปใช้
                </Button>
              </Stack>
            </Box>
          </FormContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

const fieldOrder: (keyof FilterItem)[] = [
  "name",
  "minDate",
  "sex",
  "minAge",
  "minHeight",
  "minWeight",
  "minBmi",
  "comorbidities",
  "surgeryType",
  "surgeryNo",
  "surgeryCount",
  "minPwl",
  "ultrasound",
  "endoscopy",
  "fibroScan",
  "unComorbidities",
  "complication",
  "followUp",
  "pathology",
];

const fieldLabels: Record<keyof FilterItem, string> = {
  name: "ชื่อ-นามสกุล",
  minDate: "วันที่ visit",
  maxDate: "วันที่ visit",
  sex: "เพศ",
  minAge: "อายุ",
  maxAge: "อายุ",
  minWeight: "น้ำหนัก",
  maxWeight: "น้ำหนัก",
  minHeight: "ความสูง",
  maxHeight: "ความสูง",
  minBmi: "BMI",
  maxBmi: "BMI",
  comorbidities: "โรคร่วมและโรคแทรกซ้อน",
  surgeryType: "ชนิดการผ่าตัด",
  surgeryNo: "ครั้งที่ผ่าตัด",
  surgeryCount: "จำนวนครั้งที่ผ่าตัด",
  minPwl: "Preop Weight Loss (%)",
  maxPwl: "Preop Weight Loss (%)",
  ultrasound: "Ultrasound",
  endoscopy: "Endoscopy",
  fibroScan: "FibroScan",
  unComorbidities: "หายจากโรคร่วมและแทรกซ้อน",
  complication: "Early Complication",
  followUp: "ระยะเวลา Follow Up",
  pathology: "Pathology",
};
const reportList = [
  {
    id: "RPT01",
    label: "RPT01: รายงาน First visit",
  },
  {
    id: "RPT02",
    label: "RPT02: รายงานภาพรวมข้อมูลผู้ป่วย",
  },
  {
    id: "RPT03",
    label: "RPT03: รายงาน Patient Raw Data",
  },
  {
    id: "RPT04",
    label: "RPT04: รายงานการผ่าตัด",
  },
  {
    id: "RPT05",
    label: "RPT05: รายงาน Lab data",
  },
  {
    id: "RPT06",
    label: "RPT06: รายงานข้อมูล Comorbidities & Complications",
  },
  {
    id: "RPT07",
    label: "RPT07: รายงานข้อมูล Pathology",
  },
  {
    id: "RPT08",
    label: "RPT08: รายงานข้อมูล Gynecologic",
  },
  {
    id: "RPT09",
    label: "RPT09: รายงานข้อมูล Investigation",
  },
  {
    id: "RPT10",
    label: "RPT10: รายงานข้อมูล Med And Nutritional ",
  },
  {
    id: "RPT11",
    label: "RPT11: รายงานข้อมูล Late complication",
  },
  {
    id: "RPT12",
    label: "RPT12: รายงานข้อมูล End of study",
  },
];
