import { MenuItem, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

type Props = {
  value: string;
  label: string;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
};

export const CustomMenuItem = ({
  value,
  label,
  isSelected,
  isDisabled,
  onClick,
}: Props) => {
  return (
    <MenuItem
      value={value}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      onClick={onClick}
      disabled={isDisabled}
    >
      {label}
      {isSelected ? (
        <CheckCircleRoundedIcon sx={{ color: "success.main" }} />
      ) : (
        <Typography sx={{ fontSize: "0.875rem", color: "#098484" }}>
          เลือก
        </Typography>
      )}
    </MenuItem>
  );
};
