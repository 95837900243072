import { Button } from "@mui/material";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

type Props = {
  data: any[];
  columns: any[];
  fileName: string;
  reportTitle: string;
  reportDescription: string;
};

const ExcelExport = ({
  data,
  fileName,
  columns,
  reportTitle,
  reportDescription,
}: Props) => {
  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");
    const endColumn = String.fromCharCode(65 + columns.length - 1); //  A = 65 in ASCII
    console.log(reportTitle);
    const headerRows = [
      {
        text: reportTitle,
        alignment: { vertical: "middle", horizontal: "center" },
        merge: { start: "A1", end: `${endColumn}1` },
        style: {
          bold: true,
          color: { argb: "098484" },
        },
      },
      {
        text: reportDescription,
        alignment: { vertical: "middle", horizontal: "center" },
        merge: { start: "A2", end: `${endColumn}2` },
        style: {
          italic: true,
        },
      },
      {
        text: `วันที่ออกรายงาน: ${dayjs().format("DD/MM/BBBB, HH:mm น.")}`,
        alignment: { vertical: "middle", horizontal: "right" },
        merge: { start: "A3", end: `${endColumn}3` },
        style: {
          italic: true,
        },
      },
      {
        text: `Total Records: ${data.length}`,
        alignment: { vertical: "middle", horizontal: "right" },
        merge: { start: "A4", end: `${endColumn}4` },
        style: {
          italic: true,
        },
      },
    ];

    // Add header rows with merging
    headerRows.forEach((row) => {
      const rowIndex = worksheet.addRow([row.text]);
      const cell = worksheet.getCell(row.merge.start);
      cell.alignment = row.alignment as Partial<ExcelJS.Alignment>;
      cell.font = row.style;
      worksheet.mergeCells(row.merge.start, row.merge.end);
    });

    // Add column headers
    const headerNames = columns.map((col) => col.name);
    worksheet.addRow(headerNames);

    headerNames.forEach((name, colIndex) => {
      const cell = worksheet.getCell(5, colIndex + 1); // Row 5 for header
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "098484" },
      };
      cell.font = { color: { argb: "FFFFFF" } };
    });

    // Add the actual data
    data.forEach((item) => {
      const rowData = columns.map((col) => {
        const value = item[col.key];

        return value === true ? "Yes" : value === false ? "No" : value;
      });
      worksheet.addRow(rowData);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${fileName}.xlsx`);
  };

  return (
    <Button variant="contained" color="info" onClick={exportToExcel}>
      Export
    </Button>
  );
};

export default ExcelExport;
