import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Pagination,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { AddIcon, DeleteIcon, EditIcon } from "../../icon";
import { Layout } from "../../Layout";
import { MasterDataFiltering } from "../../shared/MasterDataFiltering";
import { labelForm, spacingForm, title16500 } from "../../style";
import { theme2 } from "../../theme";
import { DeleteDialog } from "../../components/dialog/DeleteDialog";
import { useNavigate } from "react-router";

type ComorbiditiesItem = {
  name: string;
  shortName: string;
  description: string;
  status: string;
};

const tableCellValues = [
  { name: "ลำดับ", width: "10%" },
  { name: "ชื่อฟิลด์ข้อมูล", width: "25%" },
  { name: "ชื่อย่อ", width: "10%" },
  { name: "Description", width: "35%" },
  { name: "สถานะ", width: "10%" },
  { name: "เครื่องมือ", width: "10%" },
];

const initialList: ComorbiditiesItem[] = [
  {
    name: "โรคความดันโลหิตสูง (HT)",
    shortName: "HT",
    description: "",
    status: "ใช้งานได้",
  },
];
export const ComorbiditiesMaster = () => {
  const navigate = useNavigate();
  const filterRef = useRef<HTMLDivElement>(null);
  const [comorbiditiesList, setComorbiditiesList] =
    useState<ComorbiditiesItem[]>(initialList);
  const [filteredList, setFilteredList] =
    useState<ComorbiditiesItem[]>(initialList);
  const [currentComorbiditiesItem, setComorbiditiesItem] =
    useState<ComorbiditiesItem>();
  const [searchText, setSearchText] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteItemTemp, setDeleteItemTemp] = useState(0);
  const [activeStatus, setActiveStatus] = useState(true);
  const [boxHeight, setBoxHeight] = useState("calc(100vh - 162px - 56px)");

  useEffect(() => {
    const handleResize = () => {
      if (filterRef.current) {
        const stackHeight = filterRef.current.clientHeight;
        const calculatedHeight =
          stackHeight > 56
            ? `calc(100vh - ${stackHeight}px - 56px)`
            : "calc(100vh - 162px - 56px)";
        setBoxHeight(calculatedHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePageChange = (event: unknown, value: number) => {
    setPage(value);
  };
  const handleTextInputChange = (newValue: string) => {
    setSearchText(newValue);
  };
  const handleSearchStatusChange = (newValue: string) => {
    setSearchStatus(newValue);
  };
  const handleSearchClick = () => {
    const result = comorbiditiesList.filter((item) => {
      const matchesText = item.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const matchesStatus = searchStatus ? item.status === searchStatus : true;
      return matchesStatus && matchesText;
    });
    setFilteredList(result);
  };
  const handleClearSearchClick = () => {
    setSearchText("");
    setSearchStatus("");
    setFilteredList(comorbiditiesList);
  };
  const handleActiveStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatus(event.target.checked);
  };
  const handleCloseDialog = () => {
    setOpenAddDialog(false);
    setOpenEditDialog(false);
    setOpenDeleteDialog(false);
  };
  const handleAddNewItem = (data: FieldValues) => {
    const newItem = {
      name: data.name,
      shortName: data.shortName,
      description: data.description,
      status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน",
    };
    setFilteredList([...comorbiditiesList, newItem]);
    comorbiditiesList.push(newItem);
    handleCloseDialog();
  };
  const handleDeleteClick = (index: number) => {
    setDeleteItemTemp(index);
    setOpenDeleteDialog(true);
  };
  const handleEditClick = (data: ComorbiditiesItem) => {
    setOpenEditDialog(true);
    setComorbiditiesItem(data);
    setActiveStatus(
      currentComorbiditiesItem?.status === "ใช้งานได้" ? true : false
    );
  };
  const handleEditItem = (data: ComorbiditiesItem) => {
    setComorbiditiesList((prevList) =>
      prevList.map((item) =>
        item.name === currentComorbiditiesItem?.name
          ? { ...data, status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
          : item
      )
    );
    setFilteredList((prevList) =>
      prevList.map((item) =>
        item.name === currentComorbiditiesItem?.name
          ? { ...data, status: activeStatus ? "ใช้งานได้" : "ปิดการใช้งาน" }
          : item
      )
    );
    handleCloseDialog();
  };
  const handleConfirmDeleteDialog = () => {
    setComorbiditiesList((prevList) =>
      prevList.filter((_, i) => i !== deleteItemTemp)
    );
    setFilteredList((prevList) =>
      prevList.filter((_, i) => i !== deleteItemTemp)
    );
    setOpenDeleteDialog(false);
  };
  const handleAddClick = () => {
    setActiveStatus(true);
    setOpenAddDialog(true);
  };
  return (
    <Layout activeTab="setting">
      <ThemeProvider theme={theme2}>
        <Box sx={{ p: "20px", width: 1 }}>
          <Box sx={{ pb: 2 }}>
            <Typography component={"span"} style={title16500}>
              <Stack direction={"row"} gap={1}>
                <Box
                  onClick={() => navigate("/masterData")}
                  sx={{ cursor: "pointer" }}
                >
                  ตั้งค่าข้อมูลหลัก
                </Box>
                /
                <Typography
                  component={"span"}
                  color={"#7A8688"}
                  style={title16500}
                >
                  {`โรคร่วมและโรคแทรกซ้อน`}
                </Typography>
              </Stack>
            </Typography>
          </Box>
          <Box ref={filterRef}>
            <MasterDataFiltering
              title="โรคร่วมและโรคแทรกซ้อน"
              searchText={searchText}
              searchStatus={searchStatus}
              searchTextLabel="ค้นหาชื่อฟิลด์ข้อมูล"
              handleSearchTextChange={handleTextInputChange}
              handleSearchStatusChange={handleSearchStatusChange}
              handleSearchClick={handleSearchClick}
              handleClearSearchClick={handleClearSearchClick}
            />
          </Box>

          <Box
            sx={{
              bgcolor: "white",
              height: boxHeight,
              mt: 2,
              overflowY: "auto",
              scrollbarWidth: "thin",
              p: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF366B",
                float: "right",
                height: "40px",
                fontSize: "14px",
                marginBottom: "20px",
                minWidth: "90px",
              }}
              startIcon={
                <AddIcon style={{ color: "white", padding: "0.5px" }} />
              }
              onClick={handleAddClick}
            >
              เพิ่ม
            </Button>
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableCellValues.map((item, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 16,
                            fontWeight: 700,
                            width: item.width,
                          }}
                        >
                          {item.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {filteredList.length > 0 && (
                    <TableBody>
                      {filteredList
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {(page - 1) * rowsPerPage + index + 1}
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.shortName}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "5px",
                                    height: "10px",
                                    width: "10px",
                                    borderRadius: "50%",
                                    backgroundColor:
                                      item.status === "ใช้งานได้"
                                        ? "green"
                                        : "red",
                                  }}
                                />
                                {item.status}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Stack spacing={1} direction={"row"}>
                                <Box
                                  sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: "secondary.main",
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                  onClick={() => handleEditClick(item)}
                                >
                                  <EditIcon
                                    fontSize="inherit"
                                    color="secondary"
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    border: "1px solid #7A8688",
                                    borderRadius: 2,
                                    width: "30px",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    color: "#7A8688",
                                    fontSize: 14,
                                  }}
                                  onClick={() => handleDeleteClick(index)}
                                >
                                  <DeleteIcon
                                    fontSize="inherit"
                                    color="inherit"
                                  />
                                </Box>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {filteredList.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: 20,
                    color: "#9AA1AE",
                    py: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    height: "100px",
                  }}
                >
                  --ไม่พบข้อมูล--
                </Box>
              )}
            </Box>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Pagination
                count={Math.ceil(filteredList.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                sx={{ mt: 2 }}
              />
            </div>
          </Box>
        </Box>
        {/* Add  Dialog */}
        <Dialog open={openAddDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>เพิ่ม</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer onSuccess={(data) => handleAddNewItem(data)}>
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อฟิลด์ข้อมูล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>
                    ชื่อย่อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="shortName"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={spacingForm}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    checked={activeStatus}
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={handleCloseDialog} fullWidth>
          <DialogTitle>แก้ไข</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom style={{ color: "#646D78" }}>
              กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormContainer
              onSuccess={handleEditItem}
              defaultValues={{
                name: currentComorbiditiesItem?.name,
                shortName: currentComorbiditiesItem?.shortName,
                description: currentComorbiditiesItem?.description,
              }}
            >
              <Stack spacing={2} sx={{ mt: 2, ...labelForm }}>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    ชื่อฟิลด์ข้อมูล <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="name"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>
                    ชื่อย่อ <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    name="shortName"
                    required
                    variant="outlined"
                    size="small"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography color={"#646D78"}>Description</Typography>
                  <TextFieldElement
                    name="description"
                    variant="outlined"
                    size="small"
                    multiline
                    rows={3}
                  />
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <Switch
                    defaultChecked={
                      currentComorbiditiesItem?.status === "ใช้งานได้"
                        ? true
                        : false
                    }
                    color="success"
                    onChange={handleActiveStatusChange}
                  />
                  <Typography>
                    {activeStatus ? "(ใช้งานได้)" : "(ปิดการใช้งาน)"}
                  </Typography>
                </Stack>
              </Stack>
              <Box>
                <Divider sx={{ my: 2 }} />
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  spacing={2}
                >
                  <Button
                    onClick={handleCloseDialog}
                    style={{ backgroundColor: "#7A8688", color: "white" }}
                  >
                    ยกเลิก
                  </Button>
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#098484", color: "white" }}
                  >
                    บันทึก
                  </Button>
                </Stack>
              </Box>
            </FormContainer>
          </DialogContent>
        </Dialog>
        {/* Delete Dialog */}
        <DeleteDialog
          openDialog={openDeleteDialog}
          title="ต้องการลบรายการใช่หรือไม่"
          content="คุณต้องการลบรายการ"
          content2="ต้องการดำเนินการต่อใช่หรือไม่?"
          handleCloseDialog={handleCloseDialog}
          handleCancelClick={handleCloseDialog}
          handleConfirmClick={handleConfirmDeleteDialog}
        />
      </ThemeProvider>
    </Layout>
  );
};
