import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { WarningDialog } from "../../components/dialog/WarningDialog";
import { SuccessDialog } from "../../components/dialog/SuccessDialog";
import { userManagementService } from "../../service/userManagementService";
import { ConsentData } from "../../type";
import { useEffectOnce } from "react-use";
import { Info } from "../../asset/iconSvg";
import { tootlipPlacement } from "../../style";

const PersonalInfo = ({
  step,
  prevStep,
  nextStep,
  registerForm,
  handleRegisterChange,
}: {
  step: any;
  prevStep: any;
  nextStep: any;
  registerForm: any;
  handleRegisterChange: any;
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [openUnsaveDataDialog, setOpenUnsaveDataDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [dialog, setDialog] = useState({ title: "", content: "" });
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const [consentData, setConsentData] = useState<ConsentData>();

  useEffectOnce(() => {
    try {
      userManagementService.getLatestConsent().then((response) => {
        setConsentData(response);
      });
    } catch (error) {
      console.error(error);
    }
  });

  const nameTitleList = [
    {
      value: "นาย",
      label: "นาย",
    },
    {
      value: "นาง",
      label: "นาง",
    },
    {
      value: "นางสาว",
      label: "นางสาว",
    },
  ];

  const handleCloseDialog = () => {
    setOpenUnsaveDataDialog(false);
  };
  const handleConfirmDialog = () => {
    setOpenUnsaveDataDialog(false);
  };

  const handleCloseErrorDialog = () => {
    setOpenSuccessDialog(false);
    navigate("/");
  };

  const getIdcardValue = (event: any, index: number) => {
    if (index === 1 || index === 5 || index === 10 || index === 12) {
      document.getElementById(`idCard${index + 2}`)?.focus();
    } else {
      document.getElementById(`idCard${index + 1}`)?.focus();
    }
    let inputValue = "";
    for (let i = 0; i < 17; i++) {
      inputValue += (document.getElementById(
        `idCard${i + 1}`
      ) as HTMLInputElement)
        ? (document.getElementById(`idCard${i + 1}`) as HTMLInputElement).value
        : "";
    }
    handleRegisterChange({ target: { name: "nationalId", value: inputValue } });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  const register = async () => {
    registerForm.medicalDocument.documentName = registerForm.fileDoctorName;
    registerForm.medicalDocument.documentType = registerForm.fileDoctorType;
    registerForm.medicalDocument.documentData = registerForm.fileDoctor;
    if (registerForm.fileOther1 && registerForm.fileOther1Name) {
      registerForm.otherDocument[0].documentName = registerForm.fileOther1Name;
      registerForm.otherDocument[0].documentType = registerForm.fileOther1Type;
      registerForm.otherDocument[0].documentData = registerForm.fileOther1;
    }
    if (registerForm.fileOther2 && registerForm.fileOther2Name) {
      registerForm.otherDocument[1].documentName = registerForm.fileOther2Name;
      registerForm.otherDocument[1].documentType = registerForm.fileOther2Type;
      registerForm.otherDocument[1].documentData = registerForm.fileOther2;
    }
    registerForm.tcVersion = null;
    registerForm.pdpaVersion = null;
    const register = async () => {
      try {
        const response = await userManagementService.register(registerForm);
        return response;
      } catch (error: any) {
        console.error("Registration failed:", error);
        throw error;
      }
    };
    register()
      .then((response) => {
        console.log("Registration successful:", response.message);
        setDialog({ title: "สมัครสมาชิกสำเร็จ", content: "" });
        setOpenSuccessDialog(true);
      })
      .catch((error) => {
        if (error.response.data.message === "User is already in the system") {
          setDialog({
            title: "สมัครสมาชิกไม่สำเร็จ",
            content: "อีเมลนี้มีผู้ใช้งานแล้ว",
          });
        } else {
          setDialog({
            title: "สมัครสมาชิกไม่สำเร็จ",
            content: error.response.data.message,
          });
        }
        setOpenUnsaveDataDialog(true);
      });
  };

  const validateNationalId = (nationalId: string) => {
    if (nationalId.length < 13 || !/^\d+$/.test(nationalId)) {
      return false;
    } else {
      return true;
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber.length < 10 || !/^\d+$/.test(phoneNumber)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Box style={{ padding: "20px" }}>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            คำนำหน้า <span style={{ color: "red" }}>*</span>
          </p>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel>-- โปรดระบุข้อมูล --</InputLabel>
            <Select
              label="Regular Dropdown"
              id="prefix"
              name="prefix"
              value={registerForm.prefix}
              onChange={handleRegisterChange}
              size="small"
              error={!registerForm.prefix && isSubmit}
            >
              {nameTitleList.map((nameTitle) => (
                <MenuItem value={nameTitle.label}>{nameTitle.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            ชื่อ <span style={{ color: "red" }}>*</span>
          </p>
          <TextField
            label="-- โปรดระบุข้อมูล --"
            variant="outlined"
            id="firstName"
            name="firstName"
            value={registerForm.firstName}
            onChange={handleRegisterChange}
            size="small"
            fullWidth={true}
            error={!registerForm.firstName && isSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            นามสกุล <span style={{ color: "red" }}>*</span>
          </p>
          <TextField
            label="-- โปรดระบุข้อมูล --"
            variant="outlined"
            id="lastName"
            name="lastName"
            value={registerForm.lastName}
            onChange={handleRegisterChange}
            size="small"
            fullWidth={true}
            error={!registerForm.lastName && isSubmit}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            เลขที่บัตรประชาชน <span style={{ color: "red" }}>*</span>{" "}
            <Tooltip
              title="ระบุตัวเลข 13 หลักบนบัตรประชาชน (ต้องระบุให้ครบ 13 หลัก)"
              placement={tootlipPlacement}
              PopperProps={{
                sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
              }}
            >
              <span>
                <Info width={15} height={15} color="#A0ABBA" />
              </span>
            </Tooltip>
          </p>
          <Grid container columns={{ xs: 17, sm: 17, md: 17 }}>
            {Array.from(Array(17)).map((_, index) => (
              <Grid item xs={1} sm={1} md={1} key={index}>
                {index === 1 || index === 5 || index === 10 || index === 12 ? (
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    -
                  </Typography>
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    style={{ textAlign: "center" }}
                    id={`idCard${index + 1}`}
                    onChange={(event) => {
                      const value = event.target.value;
                      if (/^\d*$/.test(value)) {
                        // Allow only numeric input
                        getIdcardValue(event, index + 1);
                      }
                    }}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        paddingLeft: 8,
                        paddingRight: 8,
                      },
                    }}
                    error={
                      (registerForm.nationalId.length < 13 ||
                        !/^\d+$/.test(registerForm.nationalId)) &&
                      isSubmit
                    }
                  />
                )}
              </Grid>
            ))}
            {(registerForm.nationalId.length < 13 ||
              !/^\d+$/.test(registerForm.nationalId)) && (
              <FormHelperText error style={{ color: "grey" }}>
                กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก และเป็นตัวเลขเท่านั้น
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12} sm={6} md={4} columns={12}>
          <Grid item xs={8} md={8}>
            <p style={{ color: "#646D78" }}>เบอร์โทรศัพท์</p>
            <TextField
              variant="outlined"
              id="phoneNumber"
              name="phoneNumber"
              value={registerForm.phoneNumber}
              onChange={handleRegisterChange}
              size="small"
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <p style={{ color: "#646D78" }}>Ext</p>
            <TextField
              variant="outlined"
              id="phoneNumberExtension"
              name="phoneNumberExtension"
              value={registerForm.phoneNumberExtension}
              onChange={handleRegisterChange}
              size="small"
              fullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            เบอร์มือถือ <span style={{ color: "red" }}>*</span>
          </p>
          <TextField
            variant="outlined"
            label="-- โปรดระบุข้อมูล --"
            id="mobilePhoneNumber"
            name="mobilePhoneNumber"
            value={registerForm.mobilePhoneNumber}
            onChange={handleRegisterChange}
            size="small"
            fullWidth={true}
            error={
              (!registerForm.mobilePhoneNumber ||
                registerForm.mobilePhoneNumber.length < 10) &&
              isSubmit
            }
          />
          {(registerForm.mobilePhoneNumber.length < 10 ||
            !/^\d+$/.test(registerForm.mobilePhoneNumber)) && (
            <FormHelperText error style={{ color: "grey" }}>
              กรุณากรอกเบอร์มือถือให้ครบ 10 หลัก และเป็นตัวเลขเท่านั้น
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Divider style={{ paddingTop: "40px", marginBottom: "10px" }}></Divider>

      <Grid container spacing={2} columns={12}>
        <Grid item xs={12} sm={6} md={4}>
          <p style={{ color: "#646D78" }}>
            Email Address <span style={{ color: "red" }}>*</span>{" "}
            <Tooltip
              title="ระบุ Username ซึ่งคืออีเมล (Email) ที่ใช้เข้าสู่ระบบ"
              placement={tootlipPlacement}
              PopperProps={{
                sx: { zoom: 100 * (100 / 85) + "%", top: "-15px" },
              }}
            >
              <span>
                <Info width={15} height={15} color="#A0ABBA" />
              </span>
            </Tooltip>
          </p>
          <TextField
            label="-- โปรดระบุข้อมูล --"
            variant="outlined"
            id="email"
            name="email"
            value={registerForm.email}
            onChange={handleRegisterChange}
            size="small"
            fullWidth={true}
            error={!registerForm.email && isSubmit}
          />
        </Grid>
        {registerForm.password !== null && (
          <Grid item xs={12} sm={6} md={4}>
            <p style={{ color: "#646D78" }}>
              รหัสผ่าน <span style={{ color: "red" }}>*</span>
            </p>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="password">-- โปรดระบุข้อมูล --</InputLabel>
              <OutlinedInput
                label="-- โปรดระบุข้อมูล --"
                type={showPassword ? "text" : "password"}
                size="small"
                id="password"
                name="password"
                value={registerForm.password}
                onChange={handleRegisterChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  !registerForm.password ||
                  (registerForm.password &&
                    registerForm.confirmPassword &&
                    registerForm.password !== registerForm.confirmPassword)
                }
              />
              {registerForm.password.length < 8 && (
                <FormHelperText error style={{ color: "grey" }}>
                  รหัสผ่านควรมีความยาวมากกว่า 8 ตัวอักษร
                </FormHelperText>
              )}
              {/[^\x00-\x7F]/.test(registerForm.password) && (
                <FormHelperText error style={{ color: "grey" }}>
                  รหัสผ่านควรมีเฉพาะอักษรภาษาอังกฤษ
                </FormHelperText>
              )}
              {!/[a-z]/.test(registerForm.password) && (
                <FormHelperText error style={{ color: "grey" }}>
                  รหัสผ่านควรมีอักษรตัวพิมพ์เล็ก
                </FormHelperText>
              )}
              {!/[A-Z]/.test(registerForm.password) && (
                <FormHelperText error style={{ color: "grey" }}>
                  รหัสผ่านควรมีอักษรตัวพิมพ์ใหญ่
                </FormHelperText>
              )}
              {!/[0-9]/.test(registerForm.password) && (
                <FormHelperText error style={{ color: "grey" }}>
                  รหัสผ่านควรมีตัวเลข
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
        {registerForm.password !== null && (
          <Grid item xs={12} sm={6} md={4}>
            <p style={{ color: "#646D78" }}>
              ยืนยันรหัสผ่าน <span style={{ color: "red" }}>*</span>
            </p>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="confirmPassword">
                -- โปรดระบุข้อมูล --
              </InputLabel>
              <OutlinedInput
                label="-- โปรดระบุข้อมูล --"
                type={showPasswordConfirm ? "text" : "password"}
                size="small"
                id="confirmPassword"
                name="confirmPassword"
                value={registerForm.confirmPassword}
                onChange={handleRegisterChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPasswordConfirm}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                error={
                  !registerForm.confirmPassword ||
                  (registerForm.password &&
                    registerForm.confirmPassword &&
                    registerForm.password !== registerForm.confirmPassword)
                }
              />
              {registerForm.password !== registerForm.confirmPassword ? (
                <FormHelperText error>รหัสผ่านไม่ตรงกัน</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: "50px",
        }}
      >
        {step === 4 ? (
          <Button
            variant="contained"
            style={{
              border: "1px solid #DF5C8E",
              backgroundColor: "transparent",
              color: "#DF5C8E",
              width: "300px",
              height: "35px",
              maxWidth: "350px", // Add your desired max width
              minWidth: "200px", // Add your desired min width
              marginBottom: "10px",
            }}
            onClick={prevStep}
          >
            ก่อนหน้า
          </Button>
        ) : null}
        {step !== 4 ? (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#DF5C8E",
              width: "300px",
              height: "35px",
              maxWidth: "350px", // Add your desired max width
              minWidth: "200px", // Add your desired min width
            }}
            onClick={() => {
              const password = registerForm.password;
              const hasLowerCase = /[a-z]/.test(password);
              const hasUpperCase = /[A-Z]/.test(password);
              const hasDigit = /\d/.test(password);
              const isEqualsPassword =
                password === registerForm.confirmPassword;
              const isEnglishLettersAndSpecialChars =
                /^[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/.test(password);
              const phoneNumber = registerForm.mobilePhoneNumber;
              const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
              const nationalId = registerForm.nationalId;
              const isNationalIdValid = validateNationalId(nationalId);
              if (
                password === null
                  ? false
                  : password.length < 8 ||
                    !isEnglishLettersAndSpecialChars ||
                    !hasLowerCase ||
                    !hasUpperCase ||
                    !hasDigit ||
                    !isPhoneNumberValid ||
                    !isNationalIdValid ||
                    !isEqualsPassword
              ) {
                // Conditions not met, do nothing to prevent proceeding
                setIsSubmit(true);
              } else {
                nextStep();
                setIsSubmit(true);
              }
            }}
          >
            ถัดไป
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{
              backgroundColor: "#DF5C8E",
              width: "300px",
              height: "35px",
              maxWidth: "350px", // Add your desired max width
              minWidth: "200px", // Add your desired min width
            }}
            onClick={register}
          >
            สมัครสมาชิก
          </Button>
        )}
        <Button
          style={{ paddingTop: "20px", color: "#DF5C8E" }}
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate("/")}
        >
          กลับไปยังหน้าเข้าสู่ระบบ
        </Button>
      </div>
      <WarningDialog
        openDialog={openUnsaveDataDialog}
        title={dialog.title}
        content={dialog.content}
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleConfirmDialog}
      />
      <SuccessDialog
        openDialog={openSuccessDialog}
        title={dialog.title}
        content={dialog.content}
        handleCancelClick={handleCloseErrorDialog}
        handleCloseDialog={handleCloseErrorDialog}
        handleConfirmClick={handleCloseErrorDialog}
      />
    </Box>
  );
};

export default PersonalInfo;
