import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { store } from "../../../redux/store";
import { visitDataService } from "../../../service/visitDataService";
import { ComorbiditiesResponse } from "./ComorbiditiesAndComplications";

type Props = {
  open: boolean;
  handleCloseDialog: () => void;
};
interface Comorbidity {
  ht: boolean;
  dm: boolean;
  dlp: boolean;
  nafld: boolean;
  cva: boolean;
  cvd: boolean;
  gallstones: boolean;
  pcos: boolean;
  spondylosis: boolean;
  gerd: boolean;
  psych: boolean;
  caco: boolean;
  osa: boolean;
  other: boolean;
  otherText?: string[];
  cpap: boolean;
  kneeOsteoarthritis: boolean;
  // visitDate: string;
}
const comorbiditiesLabels: { [key in keyof Comorbidity]: string } = {
  ht: "โรคความดันโลหิตสูง (HT)",
  dm: "โรคเบาหวาน (DM)",
  dlp: "โรคไขมันในเลือดสูง (DLP)",
  nafld: "ภาวะไขมันสะสมในตับ (NAFLD/NASH)",
  cvd: "โรคหลอดเลือดหัวใจ (CAD)",
  cva: "โรคหลอดเลือดสมอง (CVA)",
  gallstones: "โรคนิ่วในถุงน้ำดี (Gallstones)",
  pcos: "โรคถุงน้ำผิดปกติที่รังไข่ (PCOS)",
  kneeOsteoarthritis: "โรคข้อเข่าเสื่อม",
  spondylosis: "โรคกระดูกสันหลัง",
  gerd: "โรคกรดไหลย้อน (GERD)",
  psych: "โรคทางจิตเวช",
  caco: "ประวัติโรคมะเร็ง",
  osa: "ภาวะทางเดินหายใจอุดกั้นขณะนอนหลับ (OSA)",
  cpap: "CPAP use",
  other: "โรคอื่นๆ ",
  // visitDate: "วันที่แก้ไข",
};
export const ComorbiditiesAndComplicationsHistoryDialog = ({
  open,
  handleCloseDialog,
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [comorbiditiesHistory, setComorbiditiesHistory] = useState<
    ComorbiditiesResponse[]
  >([]);
  const comorbiditiesKeys = Object.keys(
    comorbiditiesLabels
  ) as (keyof Comorbidity)[];

  useEffect(() => {
    const fetchData = async () => {
      const patientId = store.getState().patientUser.patientUser?.id;
      if (patientId) {
        try {
          const comorbiditiesHistory: ComorbiditiesResponse[] =
            await visitDataService.getComorbiditiesHistory(patientId);
          if (comorbiditiesHistory) {
            setComorbiditiesHistory(comorbiditiesHistory);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, [open]);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth={"lg"}>
      <DialogTitle>Visit History</DialogTitle>
      <DialogContent dividers sx={{ overflow: "hidden" }}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{
            maxHeight: "calc(100vh - 200px)",
            scrollbarWidth: "thin",
          }}
        >
          <Table stickyHeader sx={{ maxHeight: 500, overflowY: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  sx={{
                    width: 250,
                    bgcolor: "white",
                    color: "secondary.main",
                    fontWeight: 700,
                    textAlign: "center",
                    borderRight: "1px solid #E5EBEB",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    zIndex: 3,
                  }}
                >
                  Visit Date
                </TableCell>

                {comorbiditiesHistory
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((entry, index) => (
                    <TableCell
                      key={index}
                      variant="head"
                      sx={{
                        minWidth: 100,
                        bgcolor: "white",
                        fontWeight: 700,
                        textAlign: "center",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      {dayjs(entry.visitDate).format("DD/MM/BBBB")}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {comorbiditiesKeys.map((key) => (
                <TableRow key={key}>
                  <TableCell
                    sx={{
                      fontWeight: 700,
                      textAlign: "center",
                      borderRight: "1px solid #E5EBEB",
                      position: "sticky",
                      left: 0,
                      zIndex: 2,
                      bgcolor: "white",
                    }}
                  >
                    {comorbiditiesLabels[key]}
                  </TableCell>

                  {comorbiditiesHistory
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((entry, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          textAlign: "center",
                          alignContent: "baseline",
                        }}
                      >
                        {entry[key] ? "Yes" : "No"}
                        {key === "other" && entry.other
                          ? entry.otherText?.map((item) => (
                              <Typography>{item}</Typography>
                            ))
                          : ""}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={comorbiditiesHistory.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ปิด
        </Button>
      </DialogActions>
    </Dialog>
  );
};
