import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  Box,
  InputLabel,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import InfoIcon from "../asset/InfoIcon.png";
import "./UserFiltering.css"; // Import a CSS file for styling
import { title16500 } from "../style";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../service/userManagementService";

interface UserPermissionFilteringProps {
  handleSearch: (data: {
    userPermission: string;
    status: string;
  }) => void;
  handleClearFiltering: () => void;
}
const UserPermissionFiltering : React.FC<UserPermissionFilteringProps> = ({
  handleSearch,
  handleClearFiltering,
}) => {
  const [userPermission, setUserPermission] = useState("");
  const [userPermissionList, setUserPermissionList] = useState<any[]>([]);
  const [status, setStatus] = useState("");

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const userPermissionList =
          await userManagementService.getUserPermission();
        setUserPermissionList(userPermissionList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
  });

  const handleClickSearch = () => {
    handleSearch({ userPermission, status });
  };

  const handleClickClearFiltering = () => {
    return () => {
      setUserPermission("");
      setStatus("");
      handleClearFiltering();
    };
  };
  return (
    <div>
      <Box sx={{ py: 2 }}>
        <Typography component={"span"} style={title16500}>
          ผู้ใช้งานและสิทธิ์
        </Typography>
        <Typography component={"span"} color={"#7A8688"} style={title16500}>
          {` / รายการสิทธิ์การใช้งาน`}
        </Typography>
      </Box>
      <Box sx={{ py: 3, px: 3, bgcolor: "white" }}>
        <p
          style={{
            ...title16500,
            borderBottom: "2px solid #D8D8D8",
            marginTop: "0px",
          }}
        >
          <span style={{ borderBottom: "3px solid #DF5C8E", fontSize: "18px" }}>
            รายการผู้ใช้งาน
          </span>
        </p>
        <Box>
          <Grid container columnGap={3} sx={{ my: 2 }} alignItems="stretch">
          <Grid item xs={3} sm={3} md={3} lg={3}>
              <Typography style={{ ...title16500, padding: "5px" }}>
              ค้นหา ชื่อสิทธิ์การใช้งาน{" "}
              </Typography>
              <TextField
                label="-- ระบุคำที่ต้องการค้นหา --"
                variant="outlined"
                value={userPermission}
                onChange={(e) => setUserPermission(e.target.value)}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Typography style={{ ...title16500, padding: "5px" }}>
                สถานะ
              </Typography>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel>-- เลือกสถานะ --</InputLabel>
                <Select
                  label="Regular Dropdown"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <MenuItem value="ใช้งานได้">ใช้งานได้</MenuItem>
                  <MenuItem value="ปิดการใช้งาน">ปิดการใช้งาน</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#DF5C8E",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickSearch}
              >
                ค้นหา
              </Button>
            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={1} pt={4}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#7A8688",
                  marginRight: "10px",
                  height: "40px",
                  width: "100%",
                }}
                onClick={handleClickClearFiltering()}
              >
                ล้างการค้นหา
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default UserPermissionFiltering;
