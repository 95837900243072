import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { PieChart } from "@mui/x-charts";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { ErrorDialog } from "../components/dialog/ErrorDialog";
import { Layout } from "../Layout";
import { userManagementService } from "../service/userManagementService";
import { title16500 } from "../style";
import "./Dashboard.css";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import WelcomeHomePage from "../asset/WelcomeHomePage.png";
import { UserPermission } from "../type";
import {
  setUserDetail,
  setUserId,
  setUserPermission,
  UserDetail,
} from "../redux/features/loginUserSlice";
import { LoadingModal } from "./LoginScreen";

const maleCount = 544;
const femaleCount = 339;
const totalCount = maleCount + femaleCount;

const dailyData = [
  ["2024-08-01", 5],
  ["2024-08-02", 8],
  ["2024-08-03", 6],
];

const monthlyData = [
  ["January", 120],
  ["February", 150],
  ["March", 170],
];

const yearlyData = [
  ["2022", 2000],
  ["2023", 2200],
  ["2024", 2500],
];
exporting(Highcharts);
exportData(Highcharts);
dayjs.locale("th");
type TooltipFormatterContext = Highcharts.TooltipFormatterContextObject;

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId") ?? "";
      const userDetailString = localStorage.getItem("userDetail");
      const userDetails = userDetailString
        ? JSON.parse(userDetailString)
        : null;

      const userPermissionString = localStorage.getItem("userPermission");
      const userPermissions = userPermissionString
        ? JSON.parse(userPermissionString)
        : null;

      dispatch(setUserDetail(userDetails));
      dispatch(setUserId(userId));
      dispatch(setUserPermission(userPermissions));
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  });

  return (
    <Layout activeTab="home">
      <LoadingModal open={loading} />
      <div
        style={{
          minHeight: "100%",
          width: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh", // Ensure the Box takes the full viewport height
          }}
        >
          <img
            src={WelcomeHomePage}
            alt="Welcome"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </div>
      <ErrorDialog
        title="เซสชันหมดอายุ"
        content="กรุณาเข้าสู่ระบบใหม่อีกครั้ง"
        openDialog={isTokenExpired}
        handleCloseDialog={() => {
          navigate("/");
          setIsTokenExpired(false);
        }}
      />
    </Layout>
  );
};

export default Home;
