import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import "./PatientFilterTab.css";
import { body14400, title16500 } from "../../style";
import { ArrowDropDownIcon, DoubleArrowLeftIcon, PersonIcon } from "../../icon";
import { ChangeEvent, useState } from "react";
import { MasterData, PatientUser } from "../../type";
import { useEffectOnce } from "react-use";
import { getHospital } from "../../utils/get-data/getHospital";
import { calculateAge } from "../../utils/calculateAge";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { updatePatientFilter } from "../../redux/features/patientFilter-slice";
import { theme2 } from "../../theme";
import { store } from "../../redux/store";

type Props = {
  patientList: PatientUser[];
  currentPatient?: PatientUser;
  handlePatientClick: (patient: PatientUser) => void;
  handleHideFilterTab: () => void;
};

export const PatientFilterTab = ({
  patientList,
  currentPatient,
  handlePatientClick,
  handleHideFilterTab,
}: Props) => {
  const dispatch = useAppDispatch();
  const selectedHospital = useAppSelector(
    (state) => state.patientFilter.hospital
  );
  const searchHn = useAppSelector((state) => state.patientFilter.hn);
  const searchName = useAppSelector((state) => state.patientFilter.name);
  const selectedStatus = useAppSelector((state) => state.patientFilter.status);
  const isUnverifiredVisit = useAppSelector(
    (state) => state.patientFilter.unverifiredVisits
  );
  const [filteredPatient, setFilteredPatient] =
    useState<PatientUser[]>(patientList);
  const [hopitalList, setHospitalList] = useState<MasterData[]>([]);

  useEffectOnce(() => {
    const fetchData = async () => {
      try {
        const hospitalList = await getHospital();
        setHospitalList(hospitalList);
      } catch (error) {
        console.error("Error fetching List", error);
      }
    };
    fetchData();
    filterPatient();
  });
  const handleSelectedStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const statusName = event.target.name;
    const newSelectedStatus = [...selectedStatus];
    const index = newSelectedStatus.indexOf(statusName);
    if (index !== -1) {
      newSelectedStatus.splice(index, 1);
    } else {
      newSelectedStatus.push(statusName);
    }
    dispatch(
      updatePatientFilter({
        status: newSelectedStatus,
      })
    );
    // setSelectedStatus(newSelectedStatus);
  };
  const handleIsUnverifiredChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updatePatientFilter({
        unverifiredVisits: event.target.checked,
      })
    );
  };
  const filterPatient = () => {
    const hospitalFilter = (item: PatientUser) => {
      if (!selectedHospital) return true;
      if (selectedHospital === "-") return item.affiliatedHospital === null;
      return item.affiliatedHospital
        ?.toLowerCase()
        .includes(selectedHospital.toLowerCase());
    };

    const hnFilter = (item: PatientUser) => {
      if (!searchHn) return true;
      if (searchHn === "-") return item.hn === null;
      return item.hn?.toLowerCase().includes(searchHn.toLowerCase());
    };

    const nameFilter = (item: PatientUser) => {
      if (!searchName) return true;
      const fullName = `${item.firstName} ${item.lastName}`.toLowerCase();
      return (
        item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
        fullName.includes(searchName.toLowerCase())
      );
    };

    const statusFilter = (item: PatientUser) => {
      if (selectedStatus.length === 0) return true;
      return selectedStatus.includes(item.userStatus);
    };
    setFilteredPatient(
      patientList.filter(
        (item) =>
          hospitalFilter(item) &&
          hnFilter(item) &&
          nameFilter(item) &&
          statusFilter(item)
      )
    );
    // setFilteredPatient(
    //   patientList.filter((item) => {
    //     if (
    //       selectedHospital &&
    //       searchHn &&
    //       searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase())) &&
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           `${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase())) &&
    //         selectedStatus.includes(item.userStatus)
    //       );
    //     } else if (
    //       selectedHospital &&
    //       searchHn &&
    //       searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase())) &&
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           `${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase()))
    //       );
    //     } else if (
    //       selectedHospital &&
    //       searchHn &&
    //       !searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase())) &&
    //         selectedStatus.includes(item.userStatus)
    //       );
    //     } else if (
    //       selectedHospital &&
    //       searchHn &&
    //       !searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase()))
    //       );
    //     } else if (
    //       selectedHospital &&
    //       !searchHn &&
    //       searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           (`${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase()) &&
    //             selectedStatus.includes(item.userStatus)))
    //       );
    //     } else if (
    //       selectedHospital &&
    //       !searchHn &&
    //       searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           `${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase()))
    //       );
    //     } else if (
    //       selectedHospital &&
    //       !searchHn &&
    //       !searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (selectedHospital === "-"
    //           ? item.affiliatedHospital === null
    //           : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase())) &&
    //         selectedStatus.includes(item.userStatus)
    //       );
    //     } else if (
    //       selectedHospital &&
    //       !searchHn &&
    //       !searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return selectedHospital === "-"
    //         ? item.affiliatedHospital === null
    //         : item.affiliatedHospital !== null &&
    //             item.affiliatedHospital
    //               .toLowerCase()
    //               .includes(selectedHospital.toLowerCase());
    //     } else if (
    //       !selectedHospital &&
    //       searchHn &&
    //       searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase())) &&
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           `${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase())) &&
    //         selectedStatus.includes(item.userStatus)
    //       );
    //     } else if (
    //       !selectedHospital &&
    //       searchHn &&
    //       searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return (
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase())) &&
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           `${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase()))
    //       );
    //     } else if (
    //       !selectedHospital &&
    //       searchHn &&
    //       !searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (searchHn === "-"
    //           ? item.hn === null
    //           : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase())) &&
    //         selectedStatus.includes(item.userStatus)
    //       );
    //     } else if (
    //       !selectedHospital &&
    //       searchHn &&
    //       !searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return searchHn === "-"
    //         ? item.hn === null
    //         : item.hn !== null &&
    //             item.hn.toLowerCase().includes(searchHn.toLowerCase());
    //     } else if (
    //       !selectedHospital &&
    //       !searchHn &&
    //       searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return (
    //         (item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //           `${item.firstName} ${item.lastName}`
    //             .toLowerCase()
    //             .includes(searchName.toLowerCase())) &&
    //         selectedStatus.includes(item.userStatus)
    //       );
    //     } else if (
    //       !selectedHospital &&
    //       !searchHn &&
    //       searchName &&
    //       selectedStatus.length === 0
    //     ) {
    //       return (
    //         item.firstName.toLowerCase().includes(searchName.toLowerCase()) ||
    //         item.lastName.toLowerCase().includes(searchName.toLowerCase()) ||
    //         `${item.firstName} ${item.lastName}`
    //           .toLowerCase()
    //           .includes(searchName.toLowerCase())
    //       );
    //     } else if (
    //       !selectedHospital &&
    //       !searchHn &&
    //       !searchName &&
    //       selectedStatus.length > 0
    //     ) {
    //       return selectedStatus.includes(item.userStatus);
    //     }
    //     return true;
    //   })
    // );
  };
  const handleHospitalChange = (value: string) => {
    dispatch(
      updatePatientFilter({
        hospital: value,
      })
    );
  };
  const handleHnChange = (value: string) => {
    dispatch(
      updatePatientFilter({
        hn: value,
      })
    );
  };
  const handleNameChange = (value: string) => {
    dispatch(
      updatePatientFilter({
        name: value,
      })
    );
  };
  const handleClearFilter = () => {
    dispatch(
      updatePatientFilter({
        hospital: "",
        hn: "",
        name: "",
        status: [],
        unverifiredVisits: false,
      })
    );
    console.log(selectedStatus);
    setFilteredPatient(patientList);
  };
  return (
    <div>
      <Stack
        spacing={1}
        sx={{
          // height: "calc(100vh - 100px)"
          height: "calc(100vh + 48px)",
        }}
      >
        <Typography style={title16500}>ข้อมูลผู้ป่วย</Typography>
        <ThemeProvider theme={theme2}>
          <div className="filter-container" style={{ position: "relative" }}>
            <Stack spacing={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  p: 0,
                  cursor: "pointer",
                }}
                onClick={handleHideFilterTab}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    border: "1px solid #646D78",
                    borderRadius: 1 / 2,
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoubleArrowLeftIcon
                    fontSize="small"
                    sx={{ color: "#646D78" }}
                  />
                </Box>
              </Box>
              <Stack spacing={1} style={body14400}>
                <Typography component="label" style={title16500}>
                  ชื่อโรงพยาบาล
                </Typography>
                <Select
                  id="hospital"
                  value={selectedHospital}
                  size="small"
                  sx={{ width: 1 }}
                  style={body14400}
                  onChange={(e) => handleHospitalChange(e.target.value)}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: "500px",
                        scrollbarWidth: "thin",
                      },
                    },
                  }}
                >
                  <MenuItem disabled value={""} style={body14400}>
                    โปรดระบุ
                  </MenuItem>
                  {hopitalList.length > 0 &&
                    hopitalList.map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          value={item.nameThai}
                          style={body14400}
                        >
                          {item.nameThai}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Stack>
              <Stack spacing={1}>
                <Typography component="label" style={title16500}>
                  HN No.
                </Typography>
                <TextField
                  id="hn"
                  value={searchHn}
                  variant="outlined"
                  size="small"
                  placeholder="โปรดระบุ"
                  type="text"
                  sx={{ width: 1 }}
                  style={body14400}
                  onChange={(e) => handleHnChange(e.target.value)}
                  InputProps={{
                    sx: {
                      fontSize: 14,
                    },
                  }}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography component="label" style={title16500}>
                  ชื่อ-นามสกุล
                </Typography>
                <Autocomplete
                  freeSolo
                  disableClearable
                  id="name"
                  size="small"
                  value={searchName}
                  options={patientList.map(
                    (item) => `${item.firstName} ${item.lastName}`
                  )}
                  onInputChange={(_event, value) => handleNameChange(value)}
                  sx={{ width: 1 }}
                  ListboxProps={{ sx: { scrollbarWidth: "thin" } }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="โปรดระบุ"
                      InputProps={{
                        ...params.InputProps,
                        // type: "search",
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <ArrowDropDownIcon />
                        //   </InputAdornment>
                        // ),
                        sx: {
                          fontSize: 14,
                        },
                      }}
                    />
                  )}
                  style={body14400}
                />
              </Stack>
              <Stack spacing={0}>
                <Typography component="label" style={title16500}>
                  สถานะ
                </Typography>
                <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="register"
                      />
                    }
                    label="ลงทะเบียน"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("register")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#098484",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="pre-ops"
                      />
                    }
                    label="Pre-op"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("pre-ops")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#0095FF",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="post-ops"
                      />
                    }
                    label="Post-op"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("post-ops")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#FF366B",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleSelectedStatusChange}
                        name="end of study"
                      />
                    }
                    label="End of study"
                    checked={store
                      .getState()
                      .patientFilter.status.includes("end of study")}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#3C4142",
                      },
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleIsUnverifiredChange}
                        name="Unverifired Visits"
                      />
                    }
                    label="Unverifired Visits"
                    checked={isUnverifiredVisit}
                    sx={{
                      mr: 1,
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14,
                        color: "#FBC235",
                      },
                    }}
                  />
                </FormGroup>
              </Stack>
              <Box display={"flex"}>
                <Button
                  variant="contained"
                  sx={{ width: "fit-content" }}
                  onClick={filterPatient}
                >
                  ค้นหา
                </Button>
                <Button
                  variant="text"
                  sx={{ width: "fit-content" }}
                  onClick={handleClearFilter}
                >
                  ล้างค่า
                </Button>
              </Box>
            </Stack>
          </div>
        </ThemeProvider>
        <div
          className="list-container"
          style={{
            overflowY: "auto",
            height: "100%",
            scrollbarWidth: "thin",
          }}
        >
          {filteredPatient.map((patient, idx) => {
            return (
              <div
                key={idx}
                className="patient-card"
                style={{
                  border:
                    patient.id === currentPatient?.id
                      ? "3px solid #098484"
                      : "",
                  borderRadius: 8,
                  marginBottom: 8,
                  cursor: "pointer",
                }}
                onClick={() => handlePatientClick(patient)}
              >
                <Avatar sx={{ width: 48, height: 48 }}>
                  <PersonIcon fontSize="medium" />
                </Avatar>
                <div>
                  {patient.lastName.length > 20 ? (
                    <>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color={"#212121"}
                      >
                        {patient.firstName}
                      </Typography>
                      <Typography
                        fontSize={14}
                        fontWeight={700}
                        color={"#212121"}
                      >
                        {patient.lastName}
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      fontSize={14}
                      fontWeight={700}
                      color={"#212121"}
                    >
                      {`${patient.firstName} ${patient.lastName}`}
                    </Typography>
                  )}
                  <Typography fontSize={12} sx={{ color: "#646D78" }}>{`${
                    patient.sex
                  } | ${calculateAge(patient.birthDate, true)} ปี | BMI ${(
                    (patient.weight * 10000) /
                    (patient.height * patient.height)
                  ).toFixed(2)}`}</Typography>{" "}
                  <div
                    style={{
                      position: "absolute",
                      right: 8,
                      top: 0,
                      backgroundColor:
                        patient.userStatus === "register"
                          ? "#E3F6EB"
                          : patient.userStatus === "pre-ops"
                          ? "#DEE8F5"
                          : patient.userStatus === "post-ops"
                          ? "#F8E1EA"
                          : patient.userStatus === "end of study"
                          ? "#E5E5E5"
                          : "",
                      padding: 4,
                      minWidth: 70,
                      color:
                        patient.userStatus === "register"
                          ? "#098484"
                          : patient.userStatus === "pre-ops"
                          ? "#0095FF"
                          : patient.userStatus === "post-ops"
                          ? "#FF366B"
                          : patient.userStatus === "end of study"
                          ? "#3C4142"
                          : "",
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: 12,
                      borderRadius: 8,
                    }}
                  >
                    {`HN ${patient.hn ?? "-"}`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Stack>
    </div>
  );
};
