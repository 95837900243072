import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  Divider,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { title10Tab } from "../../../style";
import { SubmitBottomBar } from "../SubmitBottomBar";
import { LayoutTab } from "../LayoutTab";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { useEffectOnce } from "react-use";
import { visitDataService } from "../../../service/visitDataService";
import { useMutation } from "@tanstack/react-query";
import { store } from "../../../redux/store";
import { HistoryButtonGroup } from "../HistoryButtonGroup";
import {
  updateEntryIds,
  updateTabsIsDirty,
} from "../../../redux/features/patientSlice";
import { enqueueSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { WarningDialog } from "../../../components/dialog/WarningDialog";
import { EndOfStudyHistoryDialog } from "./EndOfStudyHistoryDialog";
import { set } from "date-fns";

export type EndOfStudyResponse = {
  entryId: number;
  editorId: number;
  createDateTime: string;
  visitDataStatus: number;
  visitDate: string;
  endOfStudy: boolean;
  endOfStudyType: number;
  endOfStudyDate: string;
  daySinceLastVisit: number;
};
type Props = {
  handleCancelClick: () => void;
};

export const EndOfStudy = ({ handleCancelClick }: Props) => {
  const dispatch = useAppDispatch();
  const latestEntryId = useAppSelector(
    (state) => state.patientUser.latestEntryIds?.endOfStudyStatus
  );
  const currentEntryId = useAppSelector(
    (state) => state.patientUser.entryIds?.endOfStudyStatus
  );
  const [endOfStudy, setEndOfStudy] = useState(false);
  const [endOfStudyType, setEndOfStudyType] = useState(0);
  const [endOfStudyDate, setEndOfStudyDate] = useState("");
  const [daySinceLastVisit, setDaySinceLastVisit] = useState(0);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [openNoPermissionDialog, setOpenNoPermissionDialog] = useState(false);

  const RadioGroup = ({
    value,
    setValue,
    label,
  }: {
    value: boolean;
    setValue: (value: boolean) => void;
    label: string;
  }) => (
    <>
      <FormControlLabel
        value="true"
        control={
          <Radio
            color="default"
            style={{ color: "#098484" }}
            onChange={() => {
              setValue(true);
              dispatch(updateTabsIsDirty(true));
            }}
          />
        }
        label="Yes"
        checked={value}
      />
      <FormControlLabel
        value="false"
        control={
          <Radio
            color="default"
            style={{ color: "#098484" }}
            onChange={() => {
              setValue(false);
              dispatch(updateTabsIsDirty(true));
            }}
          />
        }
        label="No"
        checked={!value}
      />
    </>
  );
  const setData = (lateComplicationData: EndOfStudyResponse) => {
    setEndOfStudy(lateComplicationData.endOfStudy);
    setEndOfStudyType(lateComplicationData.endOfStudyType);
    setEndOfStudyDate(lateComplicationData.endOfStudyDate);
    setDaySinceLastVisit(lateComplicationData.daySinceLastVisit);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (currentEntryId) {
        const endOfStudyData: EndOfStudyResponse =
          await visitDataService.getEndOfStadyFromEntryId(currentEntryId);
        setData(endOfStudyData);
      }
      else if (currentEntryId === null) {
        setEndOfStudy(false);
        setEndOfStudyType(0);
        setEndOfStudyDate("");
        setDaySinceLastVisit(0);
      }
    };
    fetchData();
  }, [openNoPermissionDialog]);
  const { mutateAsync: submitDataAsync } = useMutation({
    mutationFn: async () => {
      const response = await visitDataService.saveEndOfStadyData({
        patientId: store.getState().patientUser.patientUser?.id,
        visitId: store.getState().patientUser.selectedVisitId,
        visitDataStatus: 2,
        endOfStudy: endOfStudy,
        endOfStudyType: endOfStudyType,
        endOfStudyDate: endOfStudyType === 1 ? endOfStudyDate : null,
        daySinceLastVisit: daySinceLastVisit,
      });
      return response as EndOfStudyResponse;
    },
    onSuccess(data) {
      dispatch(
        updateEntryIds({
          endOfStudyStatus: data.entryId,
        })
      );
      enqueueSnackbar("ส่งข้อมูลสำเร็จ", {
        variant: "success",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    },
    onError(error) {
      console.log(error);
    },
  });
  const handleSubmitData = async () => {
    if(patientDataPermissionEditAble === false){
      setOpenNoPermissionDialog(true);
      dispatch(updateTabsIsDirty(false));
      enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
      return;
    }
    if (
      (endOfStudy && endOfStudyType === 0) ||
      (endOfStudy && endOfStudyType === 1 && endOfStudyDate === "")
    ) {
      setOpenIncompleteDataDialog(true);
    } else {
      await submitDataAsync();
      dispatch(updateTabsIsDirty(false));
    }
  };
  const handleCloseDialog = () => {
    setOpenIncompleteDataDialog(false);
    setOpenNoPermissionDialog(false);
    setOpenHistoryDialog(false);
  };
  useEffect(() => {
    if (!endOfStudy) {
      setEndOfStudyType(0);
      setEndOfStudyDate("");
    }
  }, [endOfStudy]);
  return (
    <LayoutTab
      handleCancelClick={handleCancelClick}
      handleSubmitData={handleSubmitData}
    >
      <Box sx={{ p: "20px", height: "inherit" }}>
        <HistoryButtonGroup
          handleHistoryClick={() => setOpenHistoryDialog(true)}
          handleLogClick={() => console.log("history")}
          visitDate={store.getState().patientUser.selectedVisitDate as string}
        />
        <Box sx={{ px: 2 }}>
          <Stack spacing={2}>
            <Typography style={title10Tab}>
              Amount of time since the latest visit
            </Typography>

            <Typography
              color={"#646D78"}
            >{`${daySinceLastVisit} วัน`}</Typography>
          </Stack>
          <Divider sx={{ width: "100%", my: 3 }} />
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography style={title10Tab}>End of study</Typography>
              <Typography color={"#646D78"}>
                End of study <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <RadioGroup
                  value={endOfStudy}
                  setValue={setEndOfStudy}
                  label="End of study"
                />
              </Box>
            </Stack>
            {endOfStudy && (
              <Stack spacing={1}>
                <Typography color={"#646D78"}>
                  กรุณาระบุประเภท <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box>
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        color="default"
                        style={{ color: "#098484" }}
                        onChange={(e) =>
                          setEndOfStudyType(Number(e.target.value))
                        }
                      />
                    }
                    label="Loss to f/u"
                    checked={endOfStudyType === 1}
                  />
                  <FormControlLabel
                    value={2}
                    control={
                      <Radio
                        color="default"
                        style={{ color: "#098484" }}
                        onChange={(e) =>
                          setEndOfStudyType(Number(e.target.value))
                        }
                      />
                    }
                    label="Dead"
                    checked={endOfStudyType === 2}
                  />
                </Box>
              </Stack>
            )}
            {endOfStudy && endOfStudyType === 1 && (
              <Stack spacing={1}>
                <Typography color={"#646D78"}>
                  Date <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box>
                  <DatePicker
                    value={dayjs(endOfStudyDate)}
                    onChange={(newValue) =>
                      setEndOfStudyDate(
                        newValue
                          ? newValue.format("YYYY-MM-DD")
                          : endOfStudyDate
                      )
                    }
                    maxDate={dayjs()}
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
      <EndOfStudyHistoryDialog
        open={openHistoryDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openIncompleteDataDialog}
        title="ข้อมูลไม่ครบถ้วน"
        content="กรุณาระบุข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
      <WarningDialog
        openDialog={openNoPermissionDialog}
        title="ไม่สามารถแก้ไขข้อมูลได้"
        content="กรุณาตรวจสอบสิทธิ์การใช้งาน"
        handleCancelClick={handleCloseDialog}
        handleCloseDialog={handleCloseDialog}
        handleConfirmClick={handleCloseDialog}
      />
    </LayoutTab>
  );
};
