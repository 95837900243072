import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ThemeProvider, styled } from "@mui/material/styles";
import { ChangeEvent } from "react";
import {
  FormContainer,
  RadioButtonGroup,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { CustomMenuItem } from "../../../components/CustomMenuItem";
import { AddCircleIcon, RemoveCircleIcon } from "../../../icon";
import {
  addPregnancyStatus,
  removePregnancyStatus,
  updatePatientUserProfile,
  updatePregnancyStatus,
} from "../../../redux/features/patientSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { store } from "../../../redux/store";
import { chronicDiseaseList, protectionMethodList } from "../../../sharedValue";
import { profileTitle, spacingForm } from "../../../style";
import { theme2 } from "../../../theme";
import { PatientUserProfile } from "../../../type";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

type Props = {
  openDialog: boolean;
  handleCloseDialog: () => void;
  handleSubmit: () => void;
};
export default function HealthInformationDialog({
  openDialog,
  handleCloseDialog,
  handleSubmit,
}: Props) {
  const dispatch = useAppDispatch();
  const patientUserProfile = useAppSelector(
    (state) => state.patientUser.selectedPatientUserProfile
  ) as PatientUserProfile;

  const handleBariatricSurgeryHistoryChange = (
    value:
      | string
      | {
          id: string;
          label: string;
        }
      | undefined
  ) => {
    dispatch(
      updatePatientUserProfile({
        bariatricSurgeryHistory: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleDrinkingHistoryChange = (
    value:
      | string
      | {
          id: string;
          label: string;
        }
      | undefined
  ) => {
    dispatch(
      updatePatientUserProfile({
        drinkingHistory: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleSmokingHistoryChange = (
    value:
      | string
      | {
          id: string;
          label: string;
        }
      | undefined
  ) => {
    dispatch(
      updatePatientUserProfile({
        smokingHistory: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleHighestWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updatePatientUserProfile({ highestWeight: Number(event.target.value) })
    );
  };
  const handleWeightLossMethodChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    dispatch(
      updatePatientUserProfile({
        weightLossMethod: typeof value === "string" ? value.split(",") : value,
      })
    );
    if (!value.includes("อื่นๆ")) {
      dispatch(
        updatePatientUserProfile({
          weightLossMethodOther: "",
        })
      );
    }
  };
  const handleWeightLossMethodOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updatePatientUserProfile({ weightLossMethodOther: event.target.value })
    );
  };
  const handleChronicDiseaseChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    dispatch(
      updatePatientUserProfile({
        chronicDisease: typeof value === "string" ? value.split(",") : value,
      })
    );
    if (!value.includes("โรคอื่นๆ")) {
      dispatch(
        updatePatientUserProfile({
          chronicDiseaseOther: "",
        })
      );
    }
    if (!value.includes("โรคทางจิตเวช")) {
      dispatch(
        updatePatientUserProfile({
          psychoticDisorderOther: "",
        })
      );
    }
    if (!value.includes("โรคมะเร็ง")) {
      dispatch(
        updatePatientUserProfile({
          cancerOther: "",
        })
      );
    }
  };
  const handleChronicDiseaseOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updatePatientUserProfile({ chronicDiseaseOther: event.target.value })
    );
  };
  const handleChronicDiseasePsychOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updatePatientUserProfile({ psychoticDisorderOther: event.target.value })
    );
  };
  const handleChronicDiseaseCancerOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(updatePatientUserProfile({ cancerOther: event.target.value }));
  };
  const handleIsSexuallyActiveChange = (
    value:
      | string
      | {
          id: string;
          label: string;
        }
      | undefined
  ) => {
    if (value === "ไม่") {
      dispatch(
        updatePatientUserProfile({
          sexualActivityFrequency: "",
          protectionMethod: [],
        })
      );
    }
    dispatch(
      updatePatientUserProfile({
        isSexuallyActive: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleSexualActivityFrequencyChange = (value: string) => {
    dispatch(updatePatientUserProfile({ sexualActivityFrequency: value }));
  };
  const handleProtectionMethodChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    dispatch(
      updatePatientUserProfile({
        protectionMethod: typeof value === "string" ? value.split(",") : value,
      })
    );
    if (!value.includes("อื่นๆ")) {
      dispatch(
        updatePatientUserProfile({
          protectionMethodOther: "",
        })
      );
    }
  };
  const handleProtectionMethodOtherChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      updatePatientUserProfile({ protectionMethodOther: event.target.value })
    );
  };
  const handleHasChildChange = (
    value:
      | string
      | {
          id: string;
          label: JSX.Element;
        }
      | undefined
  ) => {
    if (value === "ไม่") {
      dispatch(
        updatePatientUserProfile({
          numberOfChild: 0,
        })
      );
    } else if (value === "ใช่") {
      dispatch(
        updatePatientUserProfile({
          numberOfChild: 1,
        })
      );
    }
    dispatch(
      updatePatientUserProfile({
        hasChild: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleNumberOfChildChange = (isAdd: boolean) => {
    const count =
      store.getState().patientUser.selectedPatientUserProfile?.numberOfChild;
    if (count !== null && count !== undefined) {
      if (isAdd) {
        dispatch(
          updatePatientUserProfile({
            numberOfChild: count + 1,
          })
        );
      } else {
        dispatch(
          updatePatientUserProfile({
            numberOfChild: count - 1,
          })
        );
      }
    }
  };
  const handleWantChildChange = (
    value:
      | string
      | {
          id: string;
          label: JSX.Element;
        }
      | undefined
  ) => {
    if (value === "ไม่") {
      dispatch(
        updatePatientUserProfile({
          yearTillChildIsExpected: "",
          expectedNumberOfChild: 0,
        })
      );
    } else if (value === "ใช่") {
      dispatch(
        updatePatientUserProfile({
          yearTillChildIsExpected: "",
          expectedNumberOfChild: 1,
        })
      );
    }
    dispatch(
      updatePatientUserProfile({
        wantChild: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleYearTillChildIsExpectedChange = (value: string) => {
    dispatch(updatePatientUserProfile({ yearTillChildIsExpected: value }));
  };
  const handleExpectedNumberOfChildChange = (isAdd: boolean) => {
    const count =
      store.getState().patientUser.selectedPatientUserProfile
        ?.expectedNumberOfChild;
    if (count !== null && count !== undefined) {
      if (isAdd) {
        dispatch(
          updatePatientUserProfile({
            expectedNumberOfChild: count + 1,
          })
        );
      } else {
        dispatch(
          updatePatientUserProfile({
            expectedNumberOfChild: count - 1,
          })
        );
      }
    }
  };
  const handleStillHavingPeriodChange = (
    value:
      | string
      | {
          id: string;
          label: JSX.Element;
        }
      | undefined
  ) => {
    if (value === "ไม่") {
      dispatch(
        updatePatientUserProfile({
          periodGapDuration: "",
          periodDuration: "",
        })
      );
    }
    dispatch(
      updatePatientUserProfile({
        stillHavingPeriod: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handlePeriodGapDurationChange = (value: string) => {
    dispatch(updatePatientUserProfile({ periodGapDuration: value }));
  };
  const handlePeriodDurationChange = (value: string) => {
    dispatch(updatePatientUserProfile({ periodDuration: value }));
  };
  const handleIsPregnantChange = (
    value:
      | string
      | {
          id: string;
          label: JSX.Element;
        }
      | undefined
  ) => {
    if (value === "ไม่") {
      dispatch(
        updatePatientUserProfile({
          pregnancyStatusList: [],
          numberOfPregnancy: 0,
          numberOfMiscarriage: 0,
        })
      );
    } else if (value === "ใช่") {
      dispatch(
        updatePatientUserProfile({
          pregnancyStatusList: [
            {
              deliverMethod: "",
              complication: "",
            },
          ],
          numberOfPregnancy: 1,
          numberOfMiscarriage: 0,
        })
      );
    }
    dispatch(
      updatePatientUserProfile({
        isPregnant: value?.toString() === "ใช่" ? true : false,
      })
    );
  };
  const handleDeliverMethodChange = (deliverMethod: string, index: number) => {
    dispatch(
      updatePregnancyStatus({
        pregnancyStatusIndex: index,
        deliverMethod: deliverMethod,
      })
    );
  };
  const handleComplicationChange = (complication: string, index: number) => {
    dispatch(
      updatePregnancyStatus({
        pregnancyStatusIndex: index,
        complication: complication,
      })
    );
  };
  const handleNumberOfPregnancyChange = (isAdd: boolean) => {
    const count =
      store.getState().patientUser.selectedPatientUserProfile
        ?.numberOfPregnancy;
    if (count !== null && count !== undefined) {
      if (isAdd) {
        dispatch(
          updatePatientUserProfile({
            numberOfPregnancy: count + 1,
          })
        );
        dispatch(addPregnancyStatus());
      } else {
        dispatch(
          updatePatientUserProfile({
            numberOfPregnancy: count - 1,
          })
        );
        dispatch(removePregnancyStatus());
      }
    }
  };
  const handleNumberOfMiscarriageChange = (isAdd: boolean) => {
    const count =
      store.getState().patientUser.selectedPatientUserProfile
        ?.numberOfMiscarriage;
    if (count !== null && count !== undefined) {
      if (isAdd) {
        dispatch(
          updatePatientUserProfile({
            numberOfMiscarriage: count + 1,
          })
        );
      } else {
        dispatch(
          updatePatientUserProfile({
            numberOfMiscarriage: count - 1,
          })
        );
      }
    }
  };

  return (
    <ThemeProvider theme={theme2}>
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="profile-dialog-title"
        open={openDialog}
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, py: 1, px: 2, bgcolor: "#F7F8FC" }}
          id="profile-dialog-title"
        >
          <Typography fontSize={18} fontWeight={500}>
            ข้อมูลสุขภาพ
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ scrollbarWidth: "thin" }}>
          <FormContainer
            defaultValues={
              patientUserProfile && {
                bariatricSurgeryHistory:
                  patientUserProfile.bariatricSurgeryHistory ? "ใช่" : "ไม่",
                drinkingHistory: patientUserProfile.drinkingHistory
                  ? "ใช่"
                  : "ไม่",
                smokingHistory: patientUserProfile.smokingHistory
                  ? "ใช่"
                  : "ไม่",
                sexuallyActive: patientUserProfile.isSexuallyActive
                  ? "ใช่"
                  : "ไม่",
                sexualActivityFrequency:
                  patientUserProfile.sexualActivityFrequency,
                protectionMethod: patientUserProfile.protectionMethod,
                protectionMethodOther: patientUserProfile.protectionMethodOther,
                wantChild: patientUserProfile.wantChild ? "ใช่" : "ไม่",
                yearTillChildIsExpected:
                  patientUserProfile.yearTillChildIsExpected,
                stillHavingPeriod: patientUserProfile.stillHavingPeriod
                  ? "ใช่"
                  : "ไม่",
                periodGapDuration: patientUserProfile.periodGapDuration,
                isPregnant: patientUserProfile.isPregnant ? "ใช่" : "ไม่",
                numberOfPregnancy: patientUserProfile.numberOfPregnancy,
                numberOfMiscarriage: patientUserProfile.numberOfMiscarriage,
                hasChild: patientUserProfile.hasChild ? "ใช่" : "ไม่",
                periodDuration: patientUserProfile.periodDuration,
                pregnancyStatusList: patientUserProfile.pregnancyStatusList,
                weightLossMethodOther: patientUserProfile.weightLossMethodOther,
                chronicDiseasePsychOther:
                  patientUserProfile.psychoticDisorderOther,
                chronicDiseaseCancerOther: patientUserProfile.cancerOther,
                chronicDiseaseOther: patientUserProfile.chronicDisease,
              }
            }
            onSuccess={handleSubmit}
          >
            <Stack spacing={3} sx={{ pb: 6 }}>
              <Typography component="label" color={"#646D78"}>
                กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
                <span style={{ color: "red" }}>*</span>
              </Typography>

              <Stack>
                <Typography component="label" style={profileTitle}>
                  เคยผ่าตัดกระเพาะอาหาร <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioButtonGroup
                  row
                  required
                  name="bariatricSurgeryHistory"
                  onChange={(e) =>
                    handleBariatricSurgeryHistoryChange(e?.toString())
                  }
                  options={[
                    {
                      id: "ใช่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ใช่
                        </span>
                      ),
                    },
                    {
                      id: "ไม่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ไม่
                        </span>
                      ),
                    },
                  ]}
                />
              </Stack>
              <Stack>
                <Typography component="label" style={profileTitle}>
                  การดื่มเหล้า <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioButtonGroup
                  row
                  required
                  name="drinkingHistory"
                  onChange={(e) => handleDrinkingHistoryChange(e?.toString())}
                  options={[
                    {
                      id: "ใช่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ใช่
                        </span>
                      ),
                    },
                    {
                      id: "ไม่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ไม่
                        </span>
                      ),
                    },
                  ]}
                />
              </Stack>
              <Stack>
                <Typography component="label" style={profileTitle}>
                  การสูบบุหรี่ <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioButtonGroup
                  row
                  required
                  name="smokingHistory"
                  onChange={(e) => handleSmokingHistoryChange(e?.toString())}
                  options={[
                    {
                      id: "ใช่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ใช่
                        </span>
                      ),
                    },
                    {
                      id: "ไม่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ไม่
                        </span>
                      ),
                    },
                  ]}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  น้ำหนักมากที่สุดของคุณ (กก.){" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  id="highestWeight"
                  variant="outlined"
                  size="small"
                  placeholder="โปรดระบุ"
                  type="number"
                  sx={{ width: 1 }}
                  inputProps={{
                    min: 1,
                  }}
                  InputProps={{
                    value: patientUserProfile.highestWeight.toLocaleString(),
                    sx: {
                      fontSize: 14,
                      color: "#212121",
                    },
                  }}
                  onChange={handleHighestWeightChange}
                />
              </Stack>
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  วิธีลดความอ้วนที่เคยใช้
                </Typography>
                <Select
                  id="weightLossMethod"
                  multiple={patientUserProfile.weightLossMethod?.length > 0}
                  value={patientUserProfile.weightLossMethod ?? ""}
                  size="small"
                  sx={{ width: 1, fontSize: 14 }}
                  onChange={handleWeightLossMethodChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <>โปรดระบุ</>;
                    } else {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selected?.map((value) => (
                            <Chip
                              key={value}
                              label={`${
                                value
                                // value === "อื่นๆ"
                                //   ? patientUserProfile.weightLossMethodOther
                                //   : value
                              }`}
                            />
                          ))}
                        </Box>
                      );
                    }
                  }}
                >
                  <MenuItem disabled value={""}>
                    โปรดระบุ
                  </MenuItem>
                  <CustomMenuItem
                    value="ออกกำลังกาย"
                    label="ออกกำลังกาย"
                    isSelected={patientUserProfile.weightLossMethod?.includes(
                      "ออกกำลังกาย"
                    )}
                    onClick={() => {}}
                  />
                  <CustomMenuItem
                    value="ยาลดน้ำหนัก"
                    label="ยาลดน้ำหนัก"
                    isSelected={patientUserProfile.weightLossMethod?.includes(
                      "ยาลดน้ำหนัก"
                    )}
                    onClick={() => {}}
                  />
                  <CustomMenuItem
                    value="คุมอาหาร"
                    label="คุมอาหาร"
                    isSelected={patientUserProfile.weightLossMethod?.includes(
                      "คุมอาหาร"
                    )}
                    onClick={() => {}}
                  />
                  <CustomMenuItem
                    value="อื่นๆ"
                    label="อื่นๆ"
                    isSelected={patientUserProfile.weightLossMethod?.includes(
                      "อื่นๆ"
                    )}
                    onClick={() => {}}
                  />
                </Select>
              </Stack>
              {patientUserProfile.weightLossMethod?.includes("อื่นๆ") && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    วิธีลดความอ้วนที่เคยใช้ (อื่นๆ){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={patientUserProfile.weightLossMethod.includes(
                      "อื่นๆ"
                    )}
                    name={"weightLossMethodOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: patientUserProfile.weightLossMethodOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={handleWeightLossMethodOtherChange}
                  />
                </Stack>
              )}
              <Stack spacing={spacingForm}>
                <Typography component="label" style={profileTitle}>
                  โรคประจำตัว
                </Typography>
                <Select
                  id="chronicDisease"
                  multiple={patientUserProfile.chronicDisease?.length > 0}
                  value={patientUserProfile.chronicDisease ?? ""}
                  size="small"
                  sx={{ width: 1, fontSize: 14 }}
                  onChange={handleChronicDiseaseChange}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <>โปรดระบุ</>;
                    } else {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {selected?.map((value) => (
                            <Chip
                              key={value}
                              label={`${
                                value
                                // value === "อื่นๆ"
                                //   ? patientUserProfile.chronicDiseaseOther
                                //   : value
                              }`}
                            />
                          ))}
                        </Box>
                      );
                    }
                  }}
                >
                  <MenuItem disabled value={""}>
                    โปรดระบุ
                  </MenuItem>
                  {chronicDiseaseList.map((item, idx) => {
                    return (
                      <CustomMenuItem
                        key={idx}
                        value={item}
                        label={item}
                        isSelected={patientUserProfile.chronicDisease?.includes(
                          item
                        )}
                        onClick={() => {}}
                      />
                    );
                  })}
                </Select>
              </Stack>
              {patientUserProfile.chronicDisease?.includes("โรคทางจิตเวช") && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    โรคทางจิตเวช (ระบุเพิ่มเติม){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={patientUserProfile.chronicDisease.includes(
                      "โรคทางจิตเวช"
                    )}
                    name={"chronicDiseasePsychOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: patientUserProfile.psychoticDisorderOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={handleChronicDiseasePsychOtherChange}
                  />
                </Stack>
              )}
              {patientUserProfile.chronicDisease?.includes("โรคมะเร็ง") && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    โรคมะเร็ง (ระบุเพิ่มเติม){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={patientUserProfile.chronicDisease.includes(
                      "โรคมะเร็ง"
                    )}
                    name={"chronicDiseaseCancerOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: patientUserProfile.cancerOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={handleChronicDiseaseCancerOtherChange}
                  />
                </Stack>
              )}
              {patientUserProfile.chronicDisease?.includes("โรคอื่นๆ") && (
                <Stack spacing={spacingForm}>
                  <Typography component="label" style={profileTitle}>
                    โรคอื่นๆ (ระบุเพิ่มเติม){" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextFieldElement
                    required={patientUserProfile.chronicDisease.includes(
                      "โรคอื่นๆ"
                    )}
                    name={"chronicDiseaseOther"}
                    size="small"
                    sx={{ width: 1 }}
                    placeholder="โปรดระบุ"
                    InputProps={{
                      value: patientUserProfile.chronicDiseaseOther,
                      sx: {
                        fontSize: 14,
                        color: "#212121",
                      },
                    }}
                    onChange={handleChronicDiseaseOtherChange}
                  />
                </Stack>
              )}
              <Stack>
                <Typography component="label" style={profileTitle}>
                  เคยมีเพศสัมพันธ์หรือไม่{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioButtonGroup
                  row
                  required
                  name="sexuallyActive"
                  onChange={(e) => handleIsSexuallyActiveChange(e?.toString())}
                  options={[
                    {
                      id: "ใช่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ใช่
                        </span>
                      ),
                    },
                    {
                      id: "ไม่",
                      label: (
                        <span style={{ fontSize: 14, color: "#212121" }}>
                          ไม่
                        </span>
                      ),
                    },
                  ]}
                />
              </Stack>
              {patientUserProfile.isSexuallyActive && (
                <>
                  <Stack spacing={spacingForm}>
                    <Typography component="label" style={profileTitle}>
                      ความถี่ในการมีเพศสัมพันธ์ในช่วง 3 เดือนที่ผ่านมา{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <SelectElement
                      required
                      name="sexualActivityFrequency"
                      size="small"
                      sx={{ width: 1 }}
                      onChange={(e) => handleSexualActivityFrequencyChange(e)}
                      SelectProps={{
                        displayEmpty: true,
                        value: patientUserProfile.sexualActivityFrequency ?? "",
                        sx: { fontSize: 14 },
                      }}
                      options={[
                        {
                          id: "",
                          label: "โปรดระบุ",
                          disabled: true,
                        },
                        {
                          id: "ไม่มี",
                          label: "ไม่มี",
                        },
                        {
                          id: "<  1 ครั้งต่อเดือน",
                          label: "<  1 ครั้งต่อเดือน",
                        },
                        {
                          id: "1 ครั้งต่อเดือน",
                          label: "1 ครั้งต่อเดือน",
                        },
                        {
                          id: "1 ครั้งต่อสัปดาห์",
                          label: "1 ครั้งต่อสัปดาห์",
                        },
                        {
                          id: ">= 2 ครั้งต่อสัปดาห์",
                          label: ">= 2 ครั้งต่อสัปดาห์",
                        },
                      ]}
                    />
                  </Stack>
                  <Stack spacing={spacingForm}>
                    <Typography component="label" style={profileTitle}>
                      วิธีคุมกำเนิดที่เคยใช้{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Select
                      id="protectionMethod"
                      multiple={patientUserProfile.protectionMethod?.length > 0}
                      size="small"
                      value={patientUserProfile.protectionMethod}
                      onChange={handleProtectionMethodChange}
                      input={<OutlinedInput />}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected?.length === 0) {
                          return <>โปรดระบุ</>;
                        } else {
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected?.map((value) => (
                                <Chip
                                  key={value}
                                  label={`${
                                    value
                                    // value === "อื่นๆ"
                                    //   ? patientUserProfile.protectionMethodOther
                                    //   : value
                                  }`}
                                />
                              ))}
                            </Box>
                          );
                        }
                      }}
                    >
                      <MenuItem disabled value={""}>
                        โปรดระบุ
                      </MenuItem>
                      {protectionMethodList.map((item, idx) => {
                        return (
                          <CustomMenuItem
                            key={idx}
                            value={item}
                            label={item}
                            isSelected={patientUserProfile.protectionMethod.includes(
                              item
                            )}
                            onClick={() => {}}
                          />
                        );
                      })}
                    </Select>
                  </Stack>
                  {patientUserProfile.protectionMethod?.includes("อื่นๆ") && (
                    <Stack spacing={spacingForm}>
                      <Typography component="label" style={profileTitle}>
                        วิธีคุมกำเนิดที่เคยใช้ (อื่นๆ){" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextFieldElement
                        required={patientUserProfile.protectionMethod.includes(
                          "อื่นๆ"
                        )}
                        name={"protectionMethodOther"}
                        size="small"
                        sx={{ width: 1 }}
                        placeholder="โปรดระบุ"
                        InputProps={{
                          value: patientUserProfile.protectionMethodOther,
                          sx: {
                            fontSize: 14,
                            color: "#212121",
                          },
                        }}
                        onChange={handleProtectionMethodOtherChange}
                      />
                    </Stack>
                  )}
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      ปัจจุบันมีบุตรหรือไม่{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <RadioButtonGroup
                      row
                      required
                      name="hasChild"
                      onChange={(e) => handleHasChildChange(e)}
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  {patientUserProfile.hasChild && (
                    <Stack>
                      <Typography component="label" style={profileTitle}>
                        ปัจจุบันมีลูกกี่คน{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            patientUserProfile.numberOfChild > 1 &&
                              handleNumberOfChildChange(false);
                          }}
                          sx={{ pl: 0 }}
                        >
                          <RemoveCircleIcon color="primary" fontSize="large" />
                        </IconButton>
                        <TextField
                          id="numberOfChild"
                          value={patientUserProfile.numberOfChild}
                          variant="outlined"
                          size="small"
                          type="text"
                          sx={{
                            width: "100px",
                          }}
                          inputProps={{
                            style: { textAlign: "center", fontSize: 14 },
                          }}
                          disabled
                        />
                        <IconButton
                          onClick={() => {
                            handleNumberOfChildChange(true);
                          }}
                        >
                          <AddCircleIcon color="primary" fontSize="large" />
                        </IconButton>
                      </Box>
                    </Stack>
                  )}
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      ความต้องการมีบุตรในระยะยาว{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <RadioButtonGroup
                      row
                      required
                      name="wantChild"
                      onChange={(e) => handleWantChildChange(e)}
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  {patientUserProfile.wantChild && (
                    <>
                      <Stack spacing={spacingForm}>
                        <Typography component="label" style={profileTitle}>
                          วางแผนมีบุตรในระยะกี่ปี{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectElement
                          required
                          name="yearTillChildIsExpected"
                          size="small"
                          sx={{ width: 1 }}
                          onChange={(e) =>
                            handleYearTillChildIsExpectedChange(e)
                          }
                          SelectProps={{
                            displayEmpty: true,
                            value:
                              patientUserProfile.yearTillChildIsExpected ?? "",
                            sx: { fontSize: 14 },
                          }}
                          options={[
                            {
                              id: "",
                              label: "โปรดระบุ",
                              disabled: true,
                            },
                            {
                              id: "< 2 ปี",
                              label: "< 2 ปี",
                            },
                            {
                              id: "> 2-5 ปี",
                              label: "> 2-5 ปี",
                            },
                            {
                              id: "> 5 ปี",
                              label: "> 5 ปี",
                            },
                            {
                              id: "ไม่แน่ใจ",
                              label: "ไม่แน่ใจ",
                            },
                          ]}
                        />
                      </Stack>
                      <Stack>
                        <Typography component="label" style={profileTitle}>
                          ต้องการมีบุตรทั้งหมดกี่คน{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              patientUserProfile.expectedNumberOfChild > 1 &&
                                handleExpectedNumberOfChildChange(false);
                            }}
                            sx={{ pl: 0 }}
                          >
                            <RemoveCircleIcon
                              color="primary"
                              fontSize="large"
                            />
                          </IconButton>
                          <TextField
                            id="expectedNumberOfChild"
                            value={patientUserProfile.expectedNumberOfChild}
                            variant="outlined"
                            size="small"
                            type="text"
                            sx={{
                              width: "100px",
                            }}
                            inputProps={{
                              style: { textAlign: "center", fontSize: 14 },
                            }}
                            disabled
                          />
                          <IconButton
                            onClick={() => {
                              handleExpectedNumberOfChildChange(true);
                            }}
                          >
                            <AddCircleIcon color="primary" fontSize="large" />
                          </IconButton>
                        </Box>
                      </Stack>
                    </>
                  )}
                </>
              )}
              {patientUserProfile.sex === "female" && (
                <>
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      ในช่วง 6 เดือนที่ผ่านมาคุณมีประจำเดือนมาหรือไม่{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <RadioButtonGroup
                      row
                      required
                      name="stillHavingPeriod"
                      onChange={(e) => handleStillHavingPeriodChange(e)}
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  {patientUserProfile.stillHavingPeriod && (
                    <>
                      <Stack>
                        <Typography component="label" style={profileTitle}>
                          รอบประจำเดือนมาห่างกันกี่วัน{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectElement
                          required
                          name="periodGapDuration"
                          size="small"
                          sx={{ width: 1 }}
                          onChange={(e) => handlePeriodGapDurationChange(e)}
                          SelectProps={{
                            displayEmpty: true,
                            value: patientUserProfile.periodGapDuration ?? "",
                            sx: { fontSize: 14 },
                          }}
                          options={[
                            {
                              id: "",
                              label: "โปรดระบุ",
                              disabled: true,
                            },
                            {
                              id: "< 21 วัน",
                              label: "< 21 วัน",
                            },
                            {
                              id: "21-35 วัน",
                              label: "21-35 วัน",
                            },
                            {
                              id: "> 35 วัน",
                              label: "> 35 วัน",
                            },
                            {
                              id: "ไม่สม่ำเสมอเลย",
                              label: "ไม่สม่ำเสมอเลย",
                            },
                          ]}
                        />
                      </Stack>
                      <Stack>
                        <Typography component="label" style={profileTitle}>
                          ประจำเดือนมาครั้งละกี่วัน{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <SelectElement
                          required
                          name="periodDuration"
                          size="small"
                          sx={{ width: 1 }}
                          onChange={(e) => handlePeriodDurationChange(e)}
                          SelectProps={{
                            displayEmpty: true,
                            value: patientUserProfile.periodDuration ?? "",
                            sx: { fontSize: 14 },
                          }}
                          options={[
                            {
                              id: "",
                              label: "โปรดระบุ",
                              disabled: true,
                            },
                            {
                              id: "< 4 วัน",
                              label: "< 4 วัน",
                            },
                            {
                              id: "4-7 วัน",
                              label: "4-7 วัน",
                            },
                            {
                              id: "> 7 วัน",
                              label: "> 7 วัน",
                            },
                          ]}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack>
                    <Typography component="label" style={profileTitle}>
                      คุณกำลังตั้งครรภ์หรือไม่{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <RadioButtonGroup
                      row
                      required
                      name="isPregnant"
                      onChange={(e) => handleIsPregnantChange(e)}
                      options={[
                        {
                          id: "ใช่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ใช่
                            </span>
                          ),
                        },
                        {
                          id: "ไม่",
                          label: (
                            <span style={{ fontSize: 14, color: "#212121" }}>
                              ไม่
                            </span>
                          ),
                        },
                      ]}
                    />
                  </Stack>
                  {patientUserProfile.isPregnant && (
                    <>
                      <Stack>
                        <Typography component="label" style={profileTitle}>
                          จำนวนครั้งที่ตั้งครรภ์{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              patientUserProfile.numberOfPregnancy > 1 &&
                                handleNumberOfPregnancyChange(false);
                            }}
                            sx={{ pl: 0 }}
                          >
                            <RemoveCircleIcon
                              color="primary"
                              fontSize="large"
                            />
                          </IconButton>
                          <TextField
                            id="numberOfPregnancy"
                            value={patientUserProfile.numberOfPregnancy}
                            variant="outlined"
                            size="small"
                            placeholder="โปรดระบุ"
                            type="text"
                            sx={{
                              width: "100px",
                            }}
                            inputProps={{
                              style: { textAlign: "center", fontSize: 14 },
                            }}
                            disabled
                          />
                          <IconButton
                            onClick={() => {
                              handleNumberOfPregnancyChange(true);
                            }}
                          >
                            <AddCircleIcon color="primary" fontSize="large" />
                          </IconButton>
                        </Box>
                      </Stack>
                      <Stack>
                        <Typography component="label" style={profileTitle}>
                          ข้อมูลการตั้งครรภ์{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Box
                          sx={{
                            p: 1,
                            border: "1px solid #E5EBEB",
                            borderRadius: 1,
                          }}
                        >
                          <Stack spacing={1}>
                            <Stack
                              spacing={1}
                              direction="row"
                              useFlexGap
                              display={"grid"}
                              gridTemplateColumns={"1fr 2fr 2fr"}
                            >
                              <Typography
                                fontSize={14}
                                fontWeight={600}
                                sx={{
                                  textAlign: "center",
                                }}
                              >
                                ครั้งที่
                              </Typography>
                              <Typography fontSize={14} fontWeight={600}>
                                วิธีการคลอด
                              </Typography>
                              <Typography fontSize={14} fontWeight={600}>
                                ภาวะการแทรกซ้อน
                              </Typography>
                            </Stack>
                            {patientUserProfile.pregnancyStatusList?.map(
                              (status, idx) => {
                                return (
                                  <Stack
                                    key={idx}
                                    spacing={1}
                                    direction="row"
                                    useFlexGap
                                    display={"grid"}
                                    gridTemplateColumns={"1fr 2fr 2fr"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      fontSize={14}
                                      fontWeight={400}
                                      sx={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {idx + 1}
                                    </Typography>
                                    <Select
                                      required
                                      name="deliverMethod"
                                      size="small"
                                      sx={{
                                        width: 1,
                                        fontSize: 14,
                                      }}
                                      value={status.deliverMethod}
                                      onChange={(e) =>
                                        handleDeliverMethodChange(
                                          e.target.value,
                                          idx
                                        )
                                      }
                                      displayEmpty
                                    >
                                      <MenuItem value="" disabled>
                                        โปรดระบุ
                                      </MenuItem>
                                      <MenuItem value={"ผ่าคลอด"}>
                                        ผ่าคลอด
                                      </MenuItem>
                                      <MenuItem value={"คลอดธรรมชาติ"}>
                                        คลอดธรรมชาติ
                                      </MenuItem>
                                    </Select>
                                    <Select
                                      required
                                      name="complication"
                                      size="small"
                                      sx={{
                                        width: 1,
                                        fontSize: 14,
                                      }}
                                      value={status.complication}
                                      onChange={(e) =>
                                        handleComplicationChange(
                                          e.target.value,
                                          idx
                                        )
                                      }
                                      displayEmpty
                                    >
                                      <MenuItem value="" disabled>
                                        โปรดระบุ
                                      </MenuItem>
                                      <MenuItem value={"ไม่มี"}>ไม่มี</MenuItem>
                                      <MenuItem value={"มี"}>มี</MenuItem>
                                    </Select>
                                  </Stack>
                                );
                              }
                            )}
                          </Stack>
                          {!patientUserProfile.pregnancyStatusList.every(
                            (status) =>
                              status.deliverMethod !== "" &&
                              status.complication !== ""
                          ) && (
                            <FormHelperText sx={{ color: "#d32f2f", mt: 0 }}>
                              All fields are required
                            </FormHelperText>
                          )}
                        </Box>
                      </Stack>

                      <Stack>
                        <Typography component="label" style={profileTitle}>
                          จำนวนครั้งที่แท้ง{" "}
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              patientUserProfile.numberOfMiscarriage > 1 &&
                                handleNumberOfMiscarriageChange(false);
                            }}
                            sx={{ pl: 0 }}
                          >
                            <RemoveCircleIcon
                              color="primary"
                              fontSize="large"
                            />
                          </IconButton>
                          <TextField
                            id="numberOfMiscarriage"
                            value={patientUserProfile.numberOfMiscarriage}
                            variant="outlined"
                            size="small"
                            placeholder="โปรดระบุ"
                            type="text"
                            sx={{
                              width: "100px",
                            }}
                            inputProps={{
                              style: { textAlign: "center", fontSize: 14 },
                            }}
                            disabled
                          />
                          <IconButton
                            onClick={() => {
                              handleNumberOfMiscarriageChange(true);
                            }}
                          >
                            <AddCircleIcon color="primary" fontSize="large" />
                          </IconButton>
                        </Box>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </Stack>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                bgcolor: "white",
                height: "45px",
                display: "flex",
                justifyContent: "flex-end",
                width: "95%",
                pt: 1,
                borderTop: "1px solid #E3E7F0",
              }}
            >
              <Stack direction={"row"} spacing={1} height={35} sx={{ pr: 2 }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleCloseDialog}
                  sx={{
                    width: 100,
                    bgcolor: "#7A8688",
                    "&:hover": {
                      bgcolor: "#7A8688",
                    },
                  }}
                >
                  ยกเลิก
                </Button>
                <Button
                  type="submit"
                  autoFocus
                  variant="contained"
                  size="small"
                  sx={{
                    width: 100,
                    bgcolor: "#098484",
                    "&:hover": {
                      bgcolor: "#098484",
                    },
                  }}
                >
                  บันทึก
                </Button>
              </Stack>
            </Box>
          </FormContainer>
        </DialogContent>
      </BootstrapDialog>
    </ThemeProvider>
  );
}
