import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { useEffect, useMemo, useState } from "react";
import { store } from "../../../redux/store";

interface Chemistry {
  FBS: string;
  HbA1C: string;
  cholesterol: string;
  HDL: string;
  triglyceride: string;
  LDLC: string;
  creatinine: string;
  albumin: string;
  SGOT: string;
  SGPT: string;
  ALP: string;
}

interface ChemistryDialogProps extends Chemistry {
  open: boolean;
  onClose: (data: Chemistry) => void;
  onSave: (data: Chemistry) => void;
}

export const ChemistryDialog: React.FC<ChemistryDialogProps> = ({
  open,
  onClose,
  onSave,
  FBS,
  HbA1C,
  cholesterol,
  HDL,
  triglyceride,
  LDLC,
  creatinine,
  albumin,
  SGOT,
  SGPT,
  ALP,
}) => {
  const initialValues = useMemo(
    () => ({
      FBS,
      HbA1C,
      cholesterol,
      HDL,
      triglyceride,
      LDLC,
      creatinine,
      albumin,
      SGOT,
      SGPT,
      ALP,
    }),
    [
      FBS,
      HbA1C,
      cholesterol,
      HDL,
      triglyceride,
      LDLC,
      creatinine,
      albumin,
      SGOT,
      SGPT,
      ALP,
    ]
  );
  const [chemistryValues, setChemistryValues] =
    useState<Chemistry>(initialValues);
  const [chemistryValuesTemp, setChemistryValuesTemp] =
    useState<Chemistry>(initialValues);
  useEffect(() => {
    setChemistryValues(initialValues);
    setChemistryValuesTemp(initialValues);
  }, [initialValues]);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
  userId === "1" ? true : userPermission?.patientDataPermission.editAble;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChemistryValues({
      ...chemistryValues,
      [event.target.name]: event.target.value,
    });
  };
  const handleClickSave = () => {
    if(patientDataPermissionEditAble === true){
    onSave(chemistryValues);
    } else {
      setChemistryValues(chemistryValuesTemp);
      onClose(chemistryValuesTemp);
    }
  };
  const handleCloseDialog = () => {
    setChemistryValues(chemistryValuesTemp);
    onClose(chemistryValuesTemp);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>Chemistry</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom style={{ color: "#646D78" }}>
          กรุณาระบุข้อมูลที่มีเครื่องหมาย{" "}
          <span style={{ color: "red" }}>*</span>
        </Typography>
        <div>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>FBS</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="FBS"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.FBS}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              HbA1C <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="HbA1C"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "470px" }}
                value={chemistryValues.HbA1C}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>%</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Cholesterol <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="cholesterol"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.cholesterol}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              HDL <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="HDL"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.HDL}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Triglyceride <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="triglyceride"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.triglyceride}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              LDL-C <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="LDLC"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.LDLC}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Creatinine <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="creatinine"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.creatinine}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>mg/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              Albumin <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="albumin"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.albumin}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>g/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              SGOT <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="SGOT"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.SGOT}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>g/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              SGPT <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="SGPT"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.SGPT}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>g/dL</span>
            </div>
          </Grid>
          <Grid style={{ padding: "5px" }}>
            <p style={{ color: "#646D78" }}>
              ALP <img src={infoIcon} />
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                name="ALP"
                label="-- โปรดระบุข้อมูล --"
                variant="outlined"
                size="small"
                style={{ width: "500px" }}
                value={chemistryValues.ALP}
                onChange={handleChange}
              />
              <span style={{ marginLeft: "10px" }}>g/dL</span>
            </div>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          style={{ backgroundColor: "#7A8688", color: "white" }}
        >
          ยกเลิก
        </Button>
        <Button
          onClick={handleClickSave}
          style={{ backgroundColor: "#098484", color: "white" }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
};
