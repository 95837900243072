import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BodyLabRow } from "../../../components/BodyLabRow";
import { title10Tab } from "../../../style";
import { store } from "../../../redux/store";
import { useEffectOnce } from "react-use";
import { BodyLabResponse } from "./BodyAndCompositionAndLab";
import { visitDataService } from "../../../service/visitDataService";
import { masterDataService } from "../../../service/masterDataService";
import { LabItem } from "../../../Pages/MaterData/LabMaster";
import { CustomFieldItem } from "../../../Pages/MaterData/CustomFieldMaster";
import { set } from "date-fns";
import { OthersDialog } from "./OthersDialog";

export type CustomField = {
  id: number;
  name: string;
  type: string;
  measurementUnit: string;
};

export type CustomLabs = {
  customLabId: number;
  value: string;
};

export type OtherField = {
  id: number;
  name: string;
  value: string;
  measurementUnit: string;
};

type Props = {
  handleSaveOthersValues: (value: CustomLabs[]) => void;
  latestEntryId?: number | null;
  currentEntryId?: number | null;
};

export const Other = ({
  handleSaveOthersValues,
  latestEntryId,
  currentEntryId,
}: Props) => {
  const [otherValues, setOtherValues] = useState<CustomLabs[]>([]);
  const [openOtherDialog, setOpenOtherDialog] = useState(false);
  const [otherIsDirty, setOtherIsDirty] = useState(false);

  const userId = localStorage.getItem("userId") ?? "";
  const userPermission = store.getState().loginUser.userPermission;
  const patientDataPermissionEditAble =
    userId === "1" ? true : userPermission?.patientDataPermission.editAble;
  const [customFieldMappingList, setCustomFieldMappingList] = useState<
    CustomField[]
  >([]);
  const [customFieldList, setCustomFieldList] = useState<CustomFieldItem[]>([]);
  const [activeCustomFieldList, setActiveCustomFieldList] = useState<
    CustomFieldItem[]
  >([]);

  const handleClickOther = () => {
    setOpenOtherDialog(true);
  };

  const handleOtherDialogClose = (newValues: CustomLabs[]) => {
    setOtherValues(newValues);
    setOpenOtherDialog(false);
  };

  const handleOtherDialogSave = (newValues: CustomLabs[]) => {
    if (patientDataPermissionEditAble === false) {
      return;
    }
    setOtherValues(newValues);
    handleSaveOthersValues(newValues);
    setOpenOtherDialog(false);
    setOtherIsDirty(true);
  };

  useEffectOnce(() => {
    const fetchData = async () => {
      const masterData =
        await masterDataService.getCustomFieldMasterDataForMapping();
      setCustomFieldMappingList(masterData);

      const masterDataDashBoard =
        await masterDataService.getCustomFieldMasterData();
      setCustomFieldList(masterDataDashBoard);

      const activeCustomField = masterDataDashBoard.filter(
        (item: CustomFieldItem) => item.isActivated === true
      );
      setActiveCustomFieldList(activeCustomField);
      if (currentEntryId) {
        // Fetch other values from entryId
        const bodyLabData: BodyLabResponse =
          await visitDataService.getBodyLabFromEntryId(currentEntryId);
        setOtherValues(bodyLabData.customLabs);
      } else {
        // Initialize otherValues with active custom fields
        const initialOtherValues = activeCustomField.map((field : any) => ({
          customLabId: field.id,
          value: "",
        }));
        setOtherValues(initialOtherValues);
      }
    };

    fetchData();
  });

  const getTitleAndUnit = (id: number) => {
    const data = customFieldList.find((item) => item.id === id);
    if (data) {
      return {
        title: data.name,
        unit: data.measurementUnit,
      };
    }
    return { title: "Unknown", unit: "" };
  };

  return (
    <Box width={"100%"} height={"100%"}>
      <Box width={"90%"} height={"100%"} sx={{ border: "2px solid #D8D8D8" }}>
        <Box
          sx={{
            px: 2,
            py: 1,
            borderBottom: "2px solid #D8D8D8",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography style={title10Tab}>Others</Typography>
          <Typography
            sx={{
              color: "#646D78",
              cursor: "pointer",
            }}
            onClick={handleClickOther}
          >
            แก้ไข
          </Typography>
        </Box>

        <Divider sx={{ width: "100%" }} />

        <Box sx={{ px: 2, py: 1 }}>
          {otherValues.map((otherValue, index) => {
            const { title, unit } = getTitleAndUnit(otherValue.customLabId);
            return (
              <BodyLabRow
                key={index}
                title={title}
                value={otherValue.value || "-"}
                detail={unit}
              />
            );
          })}
        </Box>
      </Box>
      <OthersDialog
        open={openOtherDialog}
        onClose={handleOtherDialogClose}
        onSave={handleOtherDialogSave}
        otherValues={otherValues}
        customFieldList={customFieldList}
      />
    </Box>
  );
};
