import React, { useEffect, useState } from "react";
import HeaderMenuBar from "../../HeaderMenuBar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Footer from "../../Footer";
import "./Register.css";
import Stack from "@mui/material/Stack";
import { ArrowForward } from "@mui/icons-material";
import TOS from "../../asset/TOS.png";
import { useLocation, useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { userManagementService } from "../../service/userManagementService";
import { ConsentData } from "../../type";

const TermOfService = () => {
  // State for storing the values of the three filter fields
  const [step, setStep] = useState(1);
  const [acceptTOS, setAcceptTOS] = useState(false);
  const [consentData, setConsentData] = useState<ConsentData>();
  const location = useLocation();
  const { status, email, password, tokenId, userId } = location.state || {};

  const navigate = useNavigate();
  useEffectOnce(() => {
    userManagementService.getLatestConsent().then((response) => {
      setConsentData(response);
    });
  });

  return (
    <div className="register-container">
      <HeaderMenuBar />

      <div className="register-card">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={TOS}
            style={{ width: "60px", height: "60px", marginRight: "10px" }}
          />
          <Typography
            variant="h5"
            align="center"
            style={{
              fontWeight: "bold",
              paddingTop: "30px",
              paddingBottom: "20px",
            }}
          >
            Term of Service
          </Typography>
        </div>
        <Typography align="center">Updated 10 มกราคม 2567</Typography>

        <Stack spacing={1} style={{ paddingTop: "40px", padding: "50px" }}>
          <Typography>{consentData?.termAndCondition.content}</Typography>
          <Typography>
            Curabitur luctus ornare enim ut vestibulum. Nunc ullamcorper
            imperdiet erat, id vehicula ex posuere sed. Nam eu mauris euismod
            orci sollicitudin ornare at et nulla. Praesent fringilla bibendum
            ipsum ut cursus. Sed semper in urna at ullamcorper. Nulla facilisi.
            Donec quis sollicitudin justo, non mattis lacus. In molestie commodo
            purus, sit amet pretium lorem semper nec. Duis eu pulvinar tortor.
            Suspendisse elit nibh, tincidunt vel ornare sit amet, ultrices et
            metus. Cras vitae nunc in enim bibendum sollicitudin a in eros.
            Aenean scelerisque in tellus quis tincidunt. Cras placerat ipsum
            eget arcu tempus vehicula.
          </Typography>
          <Typography>
            Curabitur luctus ornare enim ut vestibulum. Nunc ullamcorper
            imperdiet erat, id vehicula ex posuere sed. Nam eu mauris euismod
            orci sollicitudin ornare at et nulla. Praesent fringilla bibendum
            ipsum ut cursus. Sed semper in urna at ullamcorper. Nulla facilisi.
            Donec quis sollicitudin justo, non mattis lacus. In molestie commodo
            purus, sit amet pretium lorem semper nec. Duis eu pulvinar tortor.
            Suspendisse elit nibh, tincidunt vel ornare sit amet, ultrices et
            metus. Cras vitae nunc in enim bibendum sollicitudin a in eros.
            Aenean scelerisque in tellus quis tincidunt. Cras placerat ipsum
            eget arcu tempus vehicula.
          </Typography>
        </Stack>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "50px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  style={{ color: "#DF5C8E", paddingLeft: "50px" }}
                  value={acceptTOS}
                  onChange={() => setAcceptTOS(!acceptTOS)}
                  checked={acceptTOS}
                />
              }
              label="ยอมรับ ข้อกำหนดในการให้บริการ (Terms of Service)"
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {acceptTOS ? (
              <Typography
                variant="body1"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: "50px",
                  color: "#DF5C8E",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  if (status === "consent_required") {
                    navigate("/privacyPolicy", {
                      state: {
                        status: "consent_required",
                        email: email,
                        password: password,
                        userId: userId,
                      },
                    }); 
                  } else if(status === "consent_required_SSO"){
                    navigate("/privacyPolicy", { state: {status: "consent_required_SSO", tokenId: tokenId, userId: userId}});
                  } else {
                    navigate("/privacyPolicy");
                  }
                }}
              >
                ถัดไป
                <ArrowForward />
              </Typography>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermOfService;
