import axios from "axios";

const BASE_URL = "https://cub-bff-lf3ljqzysq-as.a.run.app/v1/visit";

export const visitDataService = {
  getAllVisit: async function (targetUserId?: number) {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/list`,
        {
          targetUserId: targetUserId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveNewVisit: async (targetUserId: number, localDate: any) => {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/new`,
        {
          targetUserId: targetUserId,
          visitDate: localDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  updateVisitDate: async (visitId: number, visitDate: any) => {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.put(
        `${BASE_URL}/update`,
        {
          visitId: visitId,
          visitDate: visitDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getDemoGraphicFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/demographic/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getDemoGraphicListHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(
        `${BASE_URL}/demographic/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // include the token in the Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveDemoGraphicData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token"); // get the token from async storage
      const response = await axios.post(`${BASE_URL}/demographic/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`, // include the token in the Authorization header
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getComorbiditiesFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/comorbidities/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveComorbiditiesData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/comorbidities/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getComorbiditiesHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/comorbidities/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getBodyLabFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/laboratory/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveBodyLabData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/laboratory/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getBodyLabHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/laboratory/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getBariatricSurgeryHistory: async (patientId: number | null) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/surgery/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  deleteBariatricSurgeryDataFromEntryId: async (entryId: number | undefined) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.delete(
        `${BASE_URL}/surgery/delete`,
        {
          data: {
            entryId: entryId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getGynecologicFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/gynecologic/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getGynecologicHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/gynecologic/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getBariatricSurgeryFromEntryId: async (entryId: number | null) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/surgery/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveBariatricSurgeryData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/surgery/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveGynecologicData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/gynecologic/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getInvestigationFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/investigation/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveInvestigationData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/investigation/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getInvestigationHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/investigation/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getPathologyFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/pathology/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  savePathologyData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/pathology/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getPathologyHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/pathology/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getMedicationFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/medication/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveMedicationData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/medication/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getMedicationHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/medication/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getLateComplicationFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/complication/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveLateComplicationData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/complication/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getLateComplicationHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/complication/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  getEndOfStadyFromEntryId: async (entryId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/end/search`,
        {
          entryId: entryId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  saveEndOfStadyData: async (data: Record<string, any>) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(`${BASE_URL}/end/new`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  getEndOfStudyHistory: async (patientId: number) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await axios.post(
        `${BASE_URL}/end/list/history`,
        {
          patientId: patientId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

// Add more functions for other endpoints as needed
