import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import infoIcon from "../../../asset/icn.png";
import { RadioGreyBox, TextGreyBox } from "../../../components/TextBox";
import { ContraceptionState } from "./Gynecologic";
import { OtherAdd, OtherArrow, OtherRemove } from "../../../asset/iconSvg";

type Props = {
  ContraceptionValues: ContraceptionState;
  otherDetails: string[];
  handleContraceptionChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleOtherAdd: () => void;
  handleOtherRemove: (index: number) => void;
  handleOtherChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  validationError: boolean;
};
export const GynecologicContraception = ({
  ContraceptionValues,
  otherDetails,
  handleContraceptionChange,
  handleOtherAdd,
  handleOtherRemove,
  handleOtherChange,
  validationError,
}: Props) => {
  return (
    <Box sx={{ pt: 3 }}>
      <Divider sx={{ width: "100%", mb: 3 }} />
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography color={"#646D78"}>
            frequency of sexual intercourse{" "}
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value={"1"}
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "1"
                  }
                />
              }
              label="<  1 ครั้งต่อเดือน"
            />
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value="2"
                  color="default"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "2"
                  }
                />
              }
              label="1 ครั้งต่อเดือน"
            />
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value="3"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "3"
                  }
                />
              }
              label="1 ครั้งต่อสัปดาห์"
            />
            <FormControlLabel
              control={
                <Radio
                  name="frequencyOfSexualIntercourse"
                  value="4"
                  style={{ color: "#098484" }}
                  onChange={(e) => handleContraceptionChange(e)}
                  checked={
                    ContraceptionValues.frequencyOfSexualIntercourse === "4"
                  }
                />
              }
              label=">= 2 ครั้งต่อสัปดาห์"
            />
          </Box>
        </Stack>
        <Stack spacing={1} width={"90%"}>
          <Typography color={"#646D78"}>
            Contraception <span style={{ color: "red" }}>*</span>{" "}
            <img src={infoIcon} />
          </Typography>
          <Grid container rowGap={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>Male condom (ถุงยางอนามัยชาย)</TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="maleCondom"
                      checked={ContraceptionValues.maleCondom}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>
                    Combined oral contraceptive pills
                    (ยาเม็ดคุมกำเนิดฮอร์โมนรวม)
                  </TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="combinedPills"
                      checked={ContraceptionValues.combinedPills}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>
                    Combined oral contraceptive pills
                    (ยาเม็ดคุมกำเนิดฮอร์โมนเดี่ยวหรือยาเม็ด
                    คุมกำเนิดสำหรับให้นมบุตร)
                  </TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="singlePills"
                      checked={ContraceptionValues.singlePills}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>DMPA (ยาฉีดคุมกำเนิด)</TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="dmpa"
                      checked={ContraceptionValues.dmpa}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>
                    Contraceptive implant (ยาฝังคุมกำเนิด)
                  </TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="implant"
                      checked={ContraceptionValues.implant}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Stack spacing={1}>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>Copper IUD (ห่วงอนามัยทองแดง)</TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="copper"
                      checked={ContraceptionValues.copper}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>
                    Levonorgestrel IUD (ห่วงอนามัยฮอร์โมน)
                  </TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="levo"
                      checked={ContraceptionValues.levo}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>Withdrawal (หลั่งนอก)</TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="withdrawal"
                      checked={ContraceptionValues.withdrawal}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>Fertility awareness (นับวันปลอดภัย)</TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="awareness"
                      checked={ContraceptionValues.awareness}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <TextGreyBox>Other (อื่นๆ) โปรดระบุ</TextGreyBox>
                  <RadioGreyBox>
                    <Checkbox
                      name="other"
                      checked={ContraceptionValues.other}
                      onChange={handleContraceptionChange}
                      value="yes"
                      style={{ color: "#098484" }}
                    />
                  </RadioGreyBox>
                </Stack>
                {ContraceptionValues.other && (
                  <Stack spacing={1}>
                    {otherDetails?.map((detail, index) => {
                      return (
                        <Stack
                          key={index}
                          spacing={1}
                          direction={"row"}
                          // alignItems={"center"}
                        >
                          <Box sx={{ pt: 1 }}>
                            <OtherArrow />
                          </Box>

                          <TextField
                            required
                            sx={{ width: "61%" }}
                            value={detail}
                            onChange={(e) => handleOtherChange(index, e)}
                            color="secondary"
                            error={validationError && !detail.trim()}
                            helperText={
                              validationError && !detail.trim()
                                ? "This field is required"
                                : ""
                            }
                          />
                          <Box
                            sx={{ pt: 0.5 }}
                            onClick={() => handleOtherRemove(index)}
                          >
                            <OtherRemove />
                          </Box>
                          <Box sx={{ pt: 0.5 }} onClick={handleOtherAdd}>
                            <OtherAdd />
                          </Box>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
};
