import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { Layout } from "../../Layout";
import { title16500 } from "../../style";
import { ChevronRightIcon, MedicalServicesIcon } from "../../icon";
import { useNavigate } from "react-router";

type CustomeBoxProps = {
  label: string;
  path?: string;
};
const CustomBox = ({ label, path }: CustomeBoxProps) => {
  const navigate = useNavigate();
  return (
    <Box
      // component={Paper}
      sx={{
        bgcolor: "#F7F6FA",
        py: 2,
        px: 1.5,
        borderRadius: 4,
        cursor: "pointer",
      }}
      onClick={() => path && navigate(path)}
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={1}>
          <MedicalServicesIcon />
          <Typography color={"#2A282F"}>{label}</Typography>
        </Stack>
        <ChevronRightIcon />
      </Stack>
    </Box>
  );
};
export const MasterData = () => {
  return (
    <Layout activeTab="setting">
      <Box
        sx={{
          padding: "20px",
          width: "100%",
          // height: "100%",
          // height: "calc(100vh + 80px)",
          height: "inherit",
          boxSizing: "border-box",
        }}
      >
        <Box sx={{ pb: 2 }}>
          <Typography component={"span"} style={title16500}>
            ตั้งค่าข้อมูลหลัก
          </Typography>
        </Box>
        <Box
          sx={{
            bgcolor: "white",
            height: "calc(100% - 40px)",
            width: "100%",
            p: "20px",
            overflow: "auto",
            scrollbarWidth: "thin",
            flexGrow: 1,
            boxSizing: "border-box",
          }}
        >
          <Typography fontSize={18} color={"#212121"}>
            เลือกรายการ “ข้อมูลหลัก” ที่ต้องการจัดการข้อมูล
          </Typography>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox label="Custom Field" path="customField" />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox
                label="Frequency of sexual intercourse"
                // path="frequencyOfSexualIntercourse"
              />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox
                label="Contraception"
                // path="contraception"
              />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox
                label="Simultaneous operation"
                // path="simultaneousOperation"
              />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox
                label="โรคร่วมและโรคแทรกซ้อน"
                // path="comorbidities"
              />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox label="โรงพยาบาล" path="hospital" />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox label="อาหาร" path="food" />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox label="ผลแลป" path="lab" />
            </Grid>
            <Grid item sm={6} md={4} lg={3}>
              <CustomBox
                label="คำถามที่พบบ่อย"
                // path="faq"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};
